import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifyService } from 'src/app/notify.service';
import { HrService } from 'src/app/pages/services/hr.service';
import { Validatorutil } from 'src/app/validatorutil';
import { HradminService } from '../services/hradmin.service';

@Component({
  selector: 'app-create-company',
  templateUrl: './create-company.component.html',
  styleUrls: ['./create-company.component.css']
})
export class CreateCompanyComponent implements OnInit {
  public formgroup: any;
  public submitted = false;
  errorMessage = "Invallid Credentials Please Try again";
  public category: any = [];
  public storedata: any = [];
  public companyDetails: any = {
    updated: null,
    added: null,
    isRemoved: false,
    updatedBy: null,
    id: null,
    name: "",
    gst: "",
    grade: "",
    address: {
      updated: null,
      added: null,
      isRemoved: false,
      updatedBy: null,
      id: 1,
      houseNo: "",
      location: "",
      localArea: "",
      city: "",
      pincode: ""
    },
    emailId: "",
    contactNo: ""
  }
  stateId: any;
  countryId: any;
  cities: any;
  states: any;
  countries: any;
  isButtonDisabled: boolean = false;
  companyId: any;
  editFlag: boolean = false;

  constructor(private router: Router, private fb: FormBuilder,
    private validtor: Validatorutil, private hrAdminService: HradminService, private notify: NotifyService,
    private hrService: HrService, private route: ActivatedRoute) {
    this.formgroup = fb.group({
      companyId: [null],
      mothergroup: [true],
      companyName: ['', [Validators.required, Validators.pattern(validtor.NAME_REGEXP)]],
      gst: ['', [Validators.required, Validators.pattern(validtor.GST_REGEXP)]],
      grade: [''],
      address: this.fb.group({
        id: [null],
        hno: ['', [Validators.required]],
        location: ['', [Validators.required, Validators.pattern(validtor.NAME_REGEXP)]],
        pincode: ['', [Validators.required, Validators.pattern(validtor.ZIP_REGEXP)]],
        country: ['India', [Validators.required, Validators.pattern(validtor.NAME_REGEXP)]],
        countryId: [101],
        city: ['', [Validators.required, Validators.pattern(validtor.NAME_REGEXP)]],
        cityId: [''],
        stateOfCountry: ['', [Validators.required, Validators.pattern(validtor.NAME_REGEXP)]],
        stateId: ['']
      }),
      emailId: ['', [Validators.required, Validators.pattern(validtor.EMAIL_REGEXP)]],
      contactNumber: ['', [Validators.required, Validators.pattern(validtor.PHONE_REGEXP), Validators.min(1000000000)]],
      firstName: ['', [Validators.required, Validators.pattern(validtor.NAME_REGEXP)]],
      lastName: ['', [Validators.required, Validators.pattern(validtor.NAME_REGEXP)]],
      aadhaarName: ['', [Validators.required, Validators.pattern(validtor.NAME_REGEXP)]],
      representativeNo: ['', [Validators.required, Validators.pattern(validtor.PHONE_REGEXP), Validators.min(1000000000)]]
    })
    this.syncFormControls();
  }

  ngOnInit(): void {
    this.countryId = 101;
    // this.stateId = 4012;
    // this.cityList();
    this.countryList();
    this.stateList();
    this.companyId = this.route.snapshot.paramMap.get('id');
    if (this.companyId && this.companyId != "") {
      this.loadCompanyDetails();
      this.editFlag = true;
    }
  }

  loadCompanyDetails(){
    this.hrAdminService.getcompanyDeatislById(this.companyId).subscribe(
      res => {
        if (res.status === "Success") {
          const data = res.respObject;
          console.log(data)
          setTimeout(() => {
            if (this.states) {
              this.formgroup.value.address.stateOfCountry = data.address.stateOfCountry;
              this.selectState()
            }
          }, 500)
          this.formgroup.patchValue({
              companyId: this.companyId,
              mothergroup: true,
              companyName: data.companyName,
              gst: data.gst,
              grade: data.grade,
              emailId: data.emailId,
              contactNumber: data.contactNumber,
              firstName: data.firstName,
              lastName: data.lastName,
              aadhaarName: data.aadhaarName,
              representativeNo: data?.representativeNo,
              address: {
                id: data.address.id,
                hno: data.address.hno,
                location: data.address.location,
                pincode: data.address.pincode,
                country: data.address.country ? data.address.country : 'India',
                countryId: data.address.countryId ? data.address.countryId : 101,
                city: data.address.city,
                cityId: data.address.cityId,
                stateOfCountry: data.address.stateOfCountry,
                stateId: data.address.stateId
              }
            })
            console.log(this.formgroup.value)
        }
      },
      (error) => {
        console.log(error?.error ? error.error : error);
        if (error?.error?.status === 'Error') {
          this.notify.showError(error?.error?.message, 'Failed')
        }
      }
    )
  }

  cancel(){
    this.router.navigate(['/hradmin/companylist'])
    // this.formgroup.reset();
    // this.formgroup.get('address').get('countryId').setValue(101);
    // this.formgroup.get('address').get('country').setValue('India');
  }

  updateCompany() {
    this.isButtonDisabled = true;
    if (this.formgroup.invalid) {
      this.submitted = true;
      this.isButtonDisabled = false;
      return;
    } else {
      this.formgroup.value.address.cityId = this.cities.find((city: { name: any; }) => city.name === this.formgroup.value.address.city)?.id;
      console.log(this.formgroup.value);
      this.hrAdminService.updateCompany(this.formgroup.value).subscribe(
        res => {
          if (res.status == "Success") {
            this.notify.showSuccess("Company details updated succssfully.", "Sucess");
            console.log(this.formgroup.value);
            this.router.navigate([`/hradmin/companylist`]);
          }
        },
        (error) => {
          this.isButtonDisabled = false;
          if (error?.error?.status === 'Error') {
            this.notify.showError(error?.error?.message, 'Failed')
          } else {
            this.notify.showError('Failed to update company details.', 'Failed');
          }
        }
      )
    }
  }

  addCompany() {
    this.isButtonDisabled = true;
    if (this.formgroup.invalid) {
      this.submitted = true;
      this.isButtonDisabled = false;
      return;
    } else {
      // this.formgroup.value.address.stateId = this.states.find((state: { name: any; }) => state.name === this.formgroup.value.address.stateOfCountry).id;
      this.formgroup.value.address.cityId = this.cities.find((city: { name: any; }) => city.name === this.formgroup.value.address.city).id;
      let dat = JSON.stringify(this.formgroup.value);
      this.category.push(this.formgroup.value);
      this.hrAdminService.addCompany(this.formgroup.value).subscribe(
        res => {
          if (res.status == "Success") {
            this.notify.showSuccess("Company details added successfully.", "Sucess");
            console.log(this.formgroup.value);
            this.router.navigate([`/hradmin/companylist`]);
          }
        },
        (error) => {
          this.isButtonDisabled = false;
          if (error?.error?.status === 'Error') {
            this.notify.showError(error?.error?.message, 'Failed')
          } else {
            this.notify.showError('Failed to save company details.', 'Failed');
          }
        }
      )
    }

  }

  selectState(){
    this.formgroup.value.address.stateId = this.states.find((state: { name: any; }) => state.name === this.formgroup.value.address.stateOfCountry)?.id;
    this.cityList();
  }

  cityList() {
    if (this.formgroup.value.address.stateId && this.formgroup.value.address.stateId != "") {
      this.hrService.cityList(this.countryId, this.formgroup.value.address.stateId).subscribe(res => {
        this.cities = res.respObject;
      })
    }
  }

  stateList() {
    this.hrService.stateList(this.countryId).subscribe(res => {
      this.states = res.respObject;
    })
  }

  countryList() {
    this.hrService.countryList(this.countryId).subscribe(res => {
      this.countries = res.respObject;
    })
  }

  get f() {
    return this.formgroup.controls;
  }

  syncFormControls() {
    const contactNumber = this.formgroup.get('contactNumber') as FormControl;
    const representativeNo = this.formgroup.get('representativeNo') as FormControl;

    contactNumber.valueChanges.subscribe((value: string) => {
      // Update the value of the second control
      const updatedValue = value + ''; // Modify the value as desired
      representativeNo.setValue(updatedValue, { emitEvent: false });
    });
  }

}
