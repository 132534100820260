<div class="wrap_body">
  <h4 class="title_m1">Create Company</h4>
  <div class="row">
<div class="col-sm-8">
  <form class="row" [formGroup]="formgroup" (ngSubmit)="addCompany()">

    <div class="col-sm-12">
      <h5 class="mt-0 mb-2">Company Details</h5>
    </div>

    <div class="col-sm-4">
      <div class="form-group row">
        <label for="" class="col-sm-12 text-left col-form-label">Company Name <span class="c_3">&ast;</span></label>
        <div class="col-sm-12">
          <input type="text" class="form-control" id="" formControlName="companyName" required
            [ngClass]="{ 'is-invalid': submitted && f.companyName.errors }">
          <div *ngIf="submitted && f.companyName.errors" class="invalid-feedback">
            <div *ngIf="f.companyName.errors.required">Field is required</div>
            <div *ngIf="f.companyName.errors.pattern">Invallid Input</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-4">
      <div class="form-group row">
        <label for="" class="col-sm-12 text-left col-form-label">GST <span class="c_3">&ast;</span></label>
        <div class="col-sm-12">
          <input type="text" class="form-control" id="" formControlName="gst" required
            [ngClass]="{ 'is-invalid': submitted && f.gst.errors }">
          <div *ngIf="submitted && f.gst.errors" class="invalid-feedback">
            <div *ngIf="f.gst.errors.required">Field is required</div>
            <div *ngIf="f.gst.errors.pattern">Invallid Input</div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="col-sm-4">
      <div class="form-group row">
        <label for="" class="col-sm-12 text-left col-form-label">Grade</label>
        <div class="col-sm-12">
          <select name="" id="" class="form-control" formControlName="grade" required
            [ngClass]="{ 'is-invalid': submitted && f.grade.errors }">
            <option value="">Select</option>
            <option value="grade">Grade</option>
          </select>
          <div *ngIf="submitted && f.grade.errors" class="invalid-feedback">
            <div *ngIf="f.grade.errors.required">Field is required</div>
            <div *ngIf="f.grade.errors.pattern">Invallid Input</div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="col-sm-4">
      <div class="form-group row">
        <label for="" class="col-sm-12 text-left col-form-label">Email Id <span class="c_3">&ast;</span></label>
        <div class="col-sm-12">
          <input type="text" class="form-control" id="" formControlName="emailId" required
            [ngClass]="{ 'is-invalid': submitted && f.emailId.errors }">
          <div *ngIf="submitted && f.emailId.errors" class="invalid-feedback">
            <div *ngIf="f.emailId.errors.required">Field is required</div>
            <div *ngIf="f.emailId.errors.pattern">Invallid Email Entered</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="form-group row">
        <label for="" class="col-sm-12 text-left col-form-label">Contact Number <span class="c_3">&ast;</span></label>
        <div class="col-sm-12">
          <input type="text" class="form-control" id="" formControlName="contactNumber" required
            [ngClass]="{ 'is-invalid': submitted && f.contactNumber.errors }">
          <div *ngIf="submitted && f.contactNumber.errors" class="invalid-feedback">
            <div *ngIf="f.contactNumber.errors.required">Field is required</div>
            <div *ngIf="f.contactNumber.errors.pattern || f.contactNumber.errors.min">Invallid Input</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12">
      <h5 class="mt-3 mb-0  ">Address</h5>
    </div>

    <div class="col-sm-4" formGroupName="address">
      <div class="form-group row">
        <label for="" class="col-sm-12 text-left col-form-label">Location <span class="c_3">&ast;</span></label>
        <div class="col-sm-12">
          <input type="text" class="form-control" id="" formControlName="location"
            [ngClass]="{ 'is-invalid': submitted && f['address']['controls'].location.errors }">
          <div *ngIf="submitted && f['address']['controls'].location.errors" class="invalid-feedback">
            <div *ngIf="f['address']['controls'].location.errors.required">Field is required</div>
            <div *ngIf="f['address']['controls'].location.errors.pattern">Invallid Input</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-4" formGroupName="address">
      <div class="form-group row">
        <label for="" class="col-sm-12 text-left col-form-label">H.No. <span class="c_3">&ast;</span></label>
        <div class="col-sm-12">
          <input type="text" class="form-control" id="" formControlName="hno"
            [ngClass]="{ 'is-invalid': submitted && f['address']['controls'].hno.errors }">
          <div *ngIf="submitted && f['address']['controls'].hno.errors" class="invalid-feedback">
            <div *ngIf="f['address']['controls'].hno.errors.required">Field is required</div>
            <div *ngIf="f['address']['controls'].hno.errors.pattern">Invallid Input</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-4" formGroupName="address">
      <div class="form-group row">
        <label for="" class="col-sm-12 text-left col-form-label">Pin Code <span class="c_3">&ast;</span></label>
        <div class="col-sm-12">
          <input type="text" class="form-control" id="" formControlName="pincode"
            [ngClass]="{ 'is-invalid': submitted && f['address']['controls'].pincode.errors }">
          <div *ngIf="submitted && f['address']['controls'].pincode.errors" class="invalid-feedback">
            <div *ngIf="f['address']['controls'].pincode.errors.required">Field is required</div>
            <div *ngIf="f['address']['controls'].pincode.errors.pattern">Invallid Pincode</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-4" formGroupName="address">
      <div class="form-group row">
        <label for="" class="col-sm-12 text-left col-form-label">Country <span class="c_3">&ast;</span></label>
        <div class="col-sm-12">
          <input type="text" class="form-control" id="" formControlName="country"
          [ngClass]="{ 'is-invalid': submitted && f['address']['controls'].country.errors }">
          <div *ngIf="submitted && f['address']['controls'].country.errors" class="invalid-feedback">
            <div *ngIf="f['address']['controls'].country.errors.required">Field is required</div>
            <div *ngIf="f['address']['controls'].country.errors.pattern">Invallid Input</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-4" formGroupName="address">
      <div class="form-group row">
        <label for="" class="col-sm-12 text-left col-form-label">State <span class="c_3">&ast;</span></label>
        <div class="col-sm-12">
          <select name="" id="" class="form-control" (change)="selectState()" formControlName="stateOfCountry" required
            [ngClass]="{ 'is-invalid': submitted && f['address']['controls'].stateOfCountry.errors }">
            <option value="">Select</option>
            <option value="{{data.name}}" *ngFor="let data of states">{{data.name}}</option>
          </select>
          <div *ngIf="submitted && f['address']['controls'].stateOfCountry.errors" class="invalid-feedback">
            <div *ngIf="f['address']['controls'].stateOfCountry.errors.required">Field is required</div>
            <div *ngIf="f['address']['controls'].stateOfCountry.errors.pattern">Invallid Input</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-4" formGroupName="address">
      <div class="form-group row">
        <label for="" class="col-sm-12 text-left col-form-label">City <span class="c_3">&ast;</span></label>
        <div class="col-sm-12">
            <select name="" id="" class="form-control" formControlName="city" required
            [ngClass]="{ 'is-invalid': submitted && f['address']['controls'].city.errors }">
            <option value="">Select</option>
            <option value="{{data.name}}" *ngFor="let data of cities">{{data.name}}</option>
          </select>
          <div *ngIf="submitted && f['address']['controls'].city.errors" class="invalid-feedback">
            <div *ngIf="f['address']['controls'].city.errors.required">Field is required</div>
            <div *ngIf="f['address']['controls'].city.errors.pattern">Invallid Input</div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="col-sm-12">
      <h5 class="mt-3 mb-0  ">Company Representative</h5>
    </div>

    <div class="col-sm-4">
      <div class="form-group row">
        <label for="" class="col-sm-12 text-left col-form-label">First Name <span class="c_3">&ast;</span></label>
        <div class="col-sm-12">
          <input type="text" class="form-control" id="" formControlName="firstName"
            [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }">
          <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
            <div *ngIf="f.firstName.errors.required">Field is required</div>
            <div *ngIf="f.firstName.errors.pattern">Invallid Input</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="form-group row">
        <label for="" class="col-sm-12 text-left col-form-label">Last Name <span class="c_3">&ast;</span></label>
        <div class="col-sm-12">
          <input type="text" class="form-control" id="" formControlName="lastName"
            [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }">
          <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
            <div *ngIf="f.lastName.errors.required">Field is required</div>
            <div *ngIf="f.lastName.errors.pattern">Invallid Input</div>
          </div>
        </div>
      </div>
    </div>
  
    <div class="col-sm-4">
      <div class="form-group row">
        <label for="" class="col-sm-12 text-left col-form-label">Name As Per Aadhaar <span class="c_3">&ast;</span></label>
        <div class="col-sm-12">
          <input type="text" class="form-control" id="" formControlName="aadhaarName" pattern="[a-zA-Z ]*"
            [ngClass]="{ 'is-invalid': submitted && f.aadhaarName.errors }">
          <div *ngIf="submitted && f.aadhaarName.errors" class="invalid-feedback">
            <div *ngIf="f.aadhaarName.errors.required">Field is required</div>
            <div *ngIf="f.aadhaarName.errors.pattern">Invallid Input</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-4">
      <div class="form-group row">
        <label for="" class="col-sm-12 text-left col-form-label">Contact Number <span class="c_3">&ast;</span></label>
        <div class="col-sm-12">
          <input type="text" class="form-control" id="" formControlName="representativeNo" required
            [ngClass]="{ 'is-invalid': submitted && f.representativeNo.errors }">
          <div *ngIf="submitted && f.representativeNo.errors" class="invalid-feedback">
            <div *ngIf="f.representativeNo.errors.required">Field is required</div>
            <div *ngIf="f.representativeNo.errors.pattern || f.representativeNo.errors.min">Invallid Input</div>
          </div>
        </div>
      </div>
    </div>
 
    <div class="col-sm-12 text-right mt-3 mb-3">
      <button class="btn btn_m3 mr-2" type="button" (click)="cancel()">Cancel</button> 
      <button class="btn btn_m4" [disabled]="isButtonDisabled" *ngIf="!editFlag" type="submit">Save</button>
      <button class="btn btn_m4" [disabled]="isButtonDisabled" *ngIf="editFlag" (click)="updateCompany()" type="button">Update</button>
    </div>
  
  </form>
</div>
</div>
</div>