export class Validatorutil {
    public ZIP_REGEXP = /^[0-9]{6}(?:-[0-9]{6})?$/;
    public PHONE_REGEXP = /^((\\+91-?))?[0-9]{10}$/; // old logic -> /^((\\+91-?)|0)?[0-9]{10}$/
    public URL_REGEXP = /^(http?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
    public EMAIL_REGEXP=/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    public NAME_REGEXP=/[a-zA-Z][a-zA-Z ]+/;
    public HNO_REGEXP=/[A-Za-z0-9'\.\-\s\,]/;
    public GST_REGEXP=/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    public SIGRATE_REGEXP=/$|^([0-9]|[1-9][0-9]|[1][0][0])?/;
}
