import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifyService } from 'src/app/notify.service';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.css']
})
export class UpdatePasswordComponent implements OnInit {

  public formgroup:any;
  public submitted=false;
  errorMsgFlag = false;
  errorMessage="New Password and Confirm Password should be same!";
  constructor(private router:Router,private fb:FormBuilder, private loginService: LoginService,
    private route: ActivatedRoute, private notify: NotifyService) {
    this.formgroup =fb.group({
      password:['',[Validators.required,Validators.pattern('')]],
      newPassword:['',[Validators.required,Validators.pattern('')]],
      confirmPassword:['',[Validators.required,Validators.pattern('')]],
      loginId:[this.route.snapshot.paramMap.get('username')]
    })
   }

  ngOnInit(): void {
  }

  updatePwd(){
    this.errorMsgFlag = false;
    if (this.formgroup.invalid) {
      this.submitted= true;
      return;
    } else {
      if (this.formgroup.value.newPassword != this.formgroup.value.confirmPassword) {
        this.errorMsgFlag = true;
        return;
      }
     
      this.loginService.updatePwd(this.formgroup.value).subscribe(
        res => {
          if (res.status == "Success") {
            console.log('Password updated successfully')
            this.notify.showSuccess('Password updated successfully.', 'Success');
            this.router.navigate(['/dashboard/dashboard']);
          } else if(res.status === 'Error'){
            this.notify.showWarning(res?.message, 'Failed')
          }
        },
        (error) =>{
          if (error?.error?.status === 'Error') {
            this.notify.showError(error?.error?.message, 'Failed')
          } else if (error.status == "Failed") {
            console.log('Internal Server Error')
            this.notify.showError('Password updated failed.', 'Failed')
          } else {
            this.notify.showError(error?.message, 'Failed')
          }
        }
      )
      
  }
  
  }
  get f(){
    return this.formgroup.controls;
  }

}
