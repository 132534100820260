import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { EncryptDecrypt } from 'src/app/encrypt-decrypt';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HrService {

  headers = new HttpHeaders()
    .set('content-type', 'application/json')
    .set('Access-Control-Allow-Origin', '*');

  constructor(private http: HttpClient, private encDec: EncryptDecrypt) { }

  encrypt(value: any): any {
    return this.encDec.encrypt(value);
  }

  private messageSource = new Subject<string>();
  message$ = this.messageSource.asObservable();

  sendXml(message: string) {
    this.messageSource.next(message);
  }


  public bulkappointmentList(data: any) {
    return this.http.post<any>(environment.url +`/esign/candidate/list`, data);
  }
  public candidatesList() {
    return this.http.get<any>(`https://2d9776e0-8f75-40aa-9a87-6ac7196737a2.mock.pstmn.io/v1/candidate?companyId=2`);
  }

  templetList(companyId: any, status: any) {
    return this.http.get<any>(environment.url + `/esign/template/companyId/${this.encrypt(companyId)}/${this.encrypt(status)}`);
  }

  getMasterTempletList() {
    return this.http.get<any>(environment.url + `/esign/masterTemplate/all`);
  }

  getTempletList(categoryId: any) {
    return this.http.get<any>(environment.url + `/esign/template/company/category/${this.encrypt(categoryId)}`);
  }

  getExcel(masterTemplateId: any) {
    // return this.http.get<any>(environment.url + `/esign/template/downloadExcel/${masterTemplateId}`);
    // const url = 'YOUR_API_ENDPOINT_TO_DOWNLOAD_EXCEL';
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<Blob>(environment.url + `/esign/template/downloadExcel/${this.encrypt(masterTemplateId)}`, { headers, responseType: 'blob' as 'json' });
  }

  countryList(countryId: any) {
    return this.http.get<any>(environment.url + `/esign/master/countries/${this.encrypt(countryId)}`);
  }

  stateList(countryId: any) {
    return this.http.get<any>(environment.url + `/esign/master/states/${this.encrypt(countryId)}`);
  }

  candidateList(conpanyId: any, userId: any) {
    return this.http.get<any>(environment.url + `/esign/candidate/company/${this.encrypt(conpanyId)}/${this.encrypt(userId)}`);
  }

  cityList(countryId: any, stateId: any) {
    return this.http.get<any>(environment.url + `/esign/master/cities/${this.encrypt(countryId)}/${this.encrypt(stateId)}`);
  }

  rigisterCandidate(candidate: any) {
    return this.http.post<any>(environment.url + `/esign/candidate`, candidate);
  }

  updateCandidate(candidate: any) {
    return this.http.put<any>(environment.url + `/esign/candidate`, candidate);
  }

  candidateLatterReview(candidate: any) {
    return this.http.post<any>(environment.url + `/esign/candidate/candidateLetterReview`, candidate);
  }

  sendAppointment(candidateId: any) {
    return this.http.get<any>(environment.url + `/esign/candidate/appointmentLink/${this.encrypt(candidateId)}`);
  }

  verifySign(candidateId: any) {
    return this.http.put<any>(environment.url + `/esign/candidate/candidateSignVerified/${candidateId}/verified`, {});
  }

  saveTemplate(data: any) {
    const headers = new HttpHeaders({
      'Custom-Header': 'multipart/data'
    });
    return this.http.post<Object>(environment.url + `/esign/template/save`, data,{headers:headers});
  }

  templateVariables() {
    return this.http.get<any>(environment.url + `/esign/master/mapVariables`);
  }
  getCategoryByCompanyId(cId:any, status: any){
    return this.http.get<any>(environment.url+`/esign/companycataegory/company/${this.encrypt(cId)}/${this.encrypt(status)}`);
  }
  getcompanyDeatislById(cId:any){
    return this.http.get<any>(environment.url+`/esign/company/${this.encrypt(cId)}`);
  }

}
