<div class="wrap_body">
  <h4 class="title_m1">Recipient List</h4>
  <div class="row">
    
    <div class="col-sm-3">
      <select name="" id="" class="form-control" (change)="selectStatus($event)">
        <option value="">Select</option>
        <option value="REVIEWER" selected>REVIEWER</option>
        <option value="APPROVER">APPROVER</option>
        <option value="ISSUER">ISSUER</option>
      </select>
    </div>

    <div class="col-sm-3 text-right offset-sm-2">
      <div class="search_m1 mb-3">
        <input type="text" class="form-control" [(ngModel)]="term" placeholder="Search"
          (keyup)="pageConfig.currentPage = 1">
        <img src="../../../assets/images/Search.png" alt="">
      </div>
    </div>

    <div class="col-sm-2 text-right" *ngIf="checkAccess('recipient')">
      <a routerLink="/hr/add-Candidate" routerLinkActive="active" class="btn_m1 text-white">
        <span>Add Recipient</span>
      </a>
    </div>

    <div class="col-sm-2 text-right" *ngIf="checkAccessBolk('recipient')">
      <a routerLink="/hr/bulk-appointments" routerLinkActive="active" class="btn_m1 text-white">
        <span>Bulk Upload</span>
      </a>
    </div>

    <!-- <div class="col-sm-12 my-2 text-right">
      <button class="btn btn_un1 btn_m7 text-uppercase" *ngIf="statusData == 'ISSUER'" (click)="sendAppointment()">Send
        appointment</button>
    </div> -->

    <div class="col-sm-12">
      <div class="table-responsive text-nowrap">
        <table class="table table-striped table_m1">
          <thead>
            <tr>
              <th>S. No</th>
              <!-- <th scope="col" *ngIf="statusData == 'ISSUER'"><input type="checkbox" (change)="selectAll($event)"
                  [(ngModel)]="checkBoxFlag"></th> -->
              <th>Name</th>
              <th scope="col">Email Id</th>
              <th scope="col" *ngIf="statusData == 'APPROVER'">Approver</th>
              <th scope="col" *ngIf="statusData == 'ISSUER'">Issuer</th>
              <th scope="col" *ngIf="statusData == 'REVIEWER'">Reviewer</th>
              <th scope="col">Company</th>
              <th>Template</th>
              <th>Download</th>
              <th>Status</th>
              <th>Signed By</th>
              <th>Expiry date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <ng-container>
              <tr *ngFor="let data of candidates | filter:term | paginate: { itemsPerPage: recordCount, currentPage: pageConfig.currentPage } let i = index">
                <td>{{(pageConfig.currentPage - 1) * recordCount + ((candidates | filter:term )?.length > 0 ? 1 : 0) + i}}
                </td>
                <!-- <td *ngIf="statusData == 'ISSUER'">
                  <input type="checkbox" name="{{data.templateCandidateId}}" (change)="checkEvent()"
                    [(ngModel)]="data.selected">
                </td> -->
                <td>{{getName(data.candidateDto.firstName, data.candidateDto.lastName)}}</td>
                <td>{{data.candidateDto.emailId}}</td>
                <td>{{data.approver}}</td>
                <td>{{sessionData.companyName}}</td>
                <td><a href="#!" class="link-primary"></a> <button class="btn btn-link" type="button"
                    (click)="offer_letter_link(template1, data.fileUrl, data.templateDto.templateName, data.templateCandidateId, data.signVerified, data.reviewStatus)">{{data.templateDto.templateName}}</button>
                  <a (click)="downloadFile(data?.templateCandidateId)" class="menu_icon" type="button">
                    <img src="../../../assets/images/download.png" alt="">
                  </a>
                </td>
                <td>
                  <!-- <a href="#!" class="link-primary"></a>  -->
                  <ng-container *ngIf="data.reviewStatus=='ACCEPTED' && data.signVerified">
                    <a (click)="downloadZipFile(data?.templateCandidateId)" class="menu_icon" type="button">
                      <img src="../../../assets/images/download.png" alt="">
                    </a>
                  </ng-container>
                  <ng-container *ngIf="!data.signVerified">
                    <div class="text-center">-</div>
                  </ng-container>
                  <!-- <button class="btn btn-link" type="button"
                  (click)="downloadZip(data?.templateCandidateId)">{{data.templateDto.templateName}}</button> -->
                </td>
  
                <div *ngIf="statusData == 'APPROVER'">
                  <button class="btn btn_un1 btn_m6 text-uppercase mr-3"
                    *ngIf="!['APPROVED', 'ACCEPTED', 'REJECTED'].includes(data.reviewStatus)"
                    (click)="statusEvent(data, 'REJECTED')" (click)="openCandidateRemModal(template2)">reject</button>
                  <button class="btn btn_un1 btn_m7 text-uppercase" *ngIf="data.reviewStatus=='PENDING'"
                    (click)="statusEvent(data, 'APPROVED')" (click)="openCandidateRemModal(template2)">approve</button>
                  <button class="btn btn_un1 btn_m7 text-uppercase" *ngIf="data.reviewStatus=='REVIEWED'"
                    (click)="statusEvent(data, 'APPROVED')" (click)="openCandidateRemModal(template2)">approve</button>
                  <td *ngIf="data.reviewStatus=='APPROVED'" class="approved text-uppercase text-center"><i
                      class="fas fa-check "></i>
                    {{data.reviewStatus}}</td>
                  <td *ngIf="data.reviewStatus=='ACCEPTED' && !data.signVerified" class="c_2 text-uppercase text-center"><i class="fas fa-clock"></i> {{data.reviewStatus}}</td>
                  <td *ngIf="data.reviewStatus=='ACCEPTED' && data.signVerified" class="c_2 text-uppercase text-center"><i
                      class="fas fa-check"></i> {{data.reviewStatus}}</td>
                  <td *ngIf="data.reviewStatus=='REJECTED'" class="c_3 text-center"><i
                      class="fas fa-exclamation-triangle"></i>
                    {{data.reviewStatus}}</td>
                </div>
  
                <div *ngIf="statusData == 'REVIEWER'">
                  <button class="btn btn_un1 btn_m6 text-uppercase mr-3" (click)="statusEvent(data, 'REJECTED')"
                    *ngIf="data.reviewStatus=='PENDING'" (click)="openCandidateRemModal(template2)">reject</button>
                  <button class="btn btn_un1 btn_m7 text-uppercase" *ngIf="data.reviewStatus=='PENDING'"
                    (click)="statusEvent(data, 'REVIEWED')" (click)="openCandidateRemModal(template2)">REVIEW</button>
                  <td *ngIf="data.reviewStatus=='REVIEWED'" class="c_2 text-uppercase text-center"><i
                      class="fas fa-check "></i> {{data.reviewStatus}}</td>
                  <td *ngIf="data.reviewStatus=='APPROVED'" class="approved text-uppercase text-center">
                    <i class="fas fa-check"></i>
                    {{data.reviewStatus}}</td>
                  
                  <td *ngIf="data.reviewStatus=='ACCEPTED' && !data.signVerified" class="c_2 text-uppercase text-center"><i class="fas fa-clock"></i> {{data.reviewStatus}}</td>

                  <td *ngIf="data.reviewStatus=='ACCEPTED' && data.signVerified" class="c_2 text-uppercase text-center"><i
                    class="fas fa-check"></i> {{data.reviewStatus}}</td>

                  <td *ngIf="data.reviewStatus=='REJECTED'" class="c_3 text-center"><i
                      class="fas fa-exclamation-triangle"></i>
                    {{data.reviewStatus}}</td>
                </div>
  
                <div *ngIf="statusData == 'ISSUER'">
                  <td *ngIf="data.reviewStatus=='PENDING'" class="pending text-uppercase text-center"><i
                      class="fas fa-clock"></i> {{data.reviewStatus}}</td>

                  <button class="btn btn_un1 btn_m6 text-uppercase mr-3" (click)="statusEvent(data, 'REJECTED')"
                    *ngIf="data.reviewStatus === 'APPROVED'" (click)="openCandidateRemModal(template2)">reject</button>

                  <button class="btn btn_un1 btn_m7 text-uppercase" *ngIf="!data.issuerReceived && data.reviewStatus === 'APPROVED'"
                    name="{{data.templateCandidateId}}" (change)="checkEvent()" (click)="sendAppointment(data)"> Send </button>
                  
                  <button class="btn btn_un1 btn_m7 text-uppercase" *ngIf="data.issuerReceived && data.reviewStatus === 'APPROVED'"
                  name="{{data.templateCandidateId}}" (change)="checkEvent()" (click)="sendAppointment(data)"> Sent</button>
                  
                  <td *ngIf="data.reviewStatus=='REVIEWED'" class="c_2 text-uppercase text-center"><i
                      class="fas fa-check "></i> {{data.reviewStatus}}
                  </td>
                  
                  <td *ngIf="data.reviewStatus=='ACCEPTED' && !data.signVerified" class="c_2 text-uppercase text-center"><i class="fas fa-clock"></i> {{data.reviewStatus}}</td>
                  <td *ngIf="data.reviewStatus=='ACCEPTED' && data.signVerified" class="c_2 text-uppercase text-center"><i
                    class="fas fa-check"></i> {{data.reviewStatus}}</td>

                  <td *ngIf="data.reviewStatus=='REJECTED'" class="c_3 text-center"><i
                      class="fas fa-exclamation-triangle"></i>
                    {{data.reviewStatus}}</td>
                </div>
                <td>
                  <span [ngClass]="{'text-success': data.signedByApprover, 'text-danger': !data.signedByApprover}">{{data.approverAdhaarName}}</span>
                </td>
                <td>{{data.expiryDate}}</td>
                <td class="text-center"><a class="link-primary"
                    routerLink="/hr/add-Candidate/{{data?.templateCandidateId}}"><i class="fas fa-edit"></i></a></td>
              </tr>
              <tr *ngIf="candidates.length === 0">
                <td colspan="11" class="text-center">No data found</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
        <div *ngIf="candidates?.length > 0">
          <div class="row">
            <div class="col-sm-6 text-start">
              <p>Showing {{(pageConfig.currentPage - 1) * recordCount + ((candidates | filter:term )?.length > 0 ? 1 :
                0)}} -
                {{(((pageConfig.currentPage - 1) * recordCount + recordCount) > (candidates | filter:term )?.length)
                ? ((candidates | filter:term )?.length) : (pageConfig.currentPage - 1) * recordCount + recordCount}}
                of {{(candidates | filter:term )?.length}} records
              </p>
            </div>
            <div class="col-sm-6 text-end">
              <pagination-controls (pageChange)="pageConfig.currentPage = $event"></pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Candidate remarks model start -->

<ng-template #template2>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Candidate Remarks</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="candidateRemModel.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group row">
          <label for="" class="col-sm-2 text-left col-form-label">Remarks</label>
          <div class="col-sm-10">
            <textarea name="" id="" cols="30" rows="2" [(ngModel)]="candidateRemarks" class="form-control"></textarea>
          </div>
          <div *ngIf="errorMsgFlag" class="text-center my-2 text-danger">{{errorMessage}}</div>
        </div>
      </div>
    </div>

    <div class="text-right">
      <button class="btn btn_un1 btn_m6 text-uppercase" (click)="candidateRemModel.hide()">Cancel</button>
      <button class="btn btn_un1 btn_m7 text-uppercase ml-3" (click)="saveEvent()">Submit</button>
    </div>
  </div>
</ng-template>

<!-- Candidate remarks model end -->

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Approve Candidate 1</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group row">
          <label for="" class="col-sm-2 text-left col-form-label">Adress</label>
          <div class="col-sm-10">
            <textarea name="" id="" cols="30" rows="2" class="form-control"></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="text-right">
      <button class="btn btn_un1 btn_m6 text-uppercase">Cancel</button>
      <button class="btn btn_un1 btn_m7 text-uppercase ml-3">Submit</button>
    </div>
  </div>
</ng-template>

<ng-template #template1>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{popupFileName}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="offer_letter.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12">
        <pdf-viewer [src]="pdfSrc" [render-text]="true" [original-size]="false" style="width: 100%; height: 600px"></pdf-viewer>
      </div>
    </div>
    <div class="my-3 col-sm-12 row" *ngIf="verifyFlag">
      <div class="text-right col-sm-11">
      </div>
      
      <div class="col-sm-12 text-right">
        <button class="btn btn_un1 btn_m7 text-uppercase"  (click)="offer_letter.hide()"
          (click)="verify('verified')">Verify</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #template4>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Appointment </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="offer_letter.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <h6>Are you sure want to Send Appointment to Candidate ?</h6>
    </div>
    <div class="text-right mt-4 mb-2">
      <button class="btn btn_un1 btn_m6 text-uppercase" (click)="appointMentNotSented()">NO</button>
      <button class="btn btn_un1 btn_m7 text-uppercase ml-3" (click)="appointMentSented()">YES</button>
    </div>
  </div>
</ng-template>