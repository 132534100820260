<div class="">
    <div class="can_header">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-6"><img src="../../../assets/images/logo.png" alt=""></div>
                <div class="col-sm-6"></div>
            </div>
        </div>
    </div>
    <div class="wrap_body">
        <router-outlet></router-outlet>
    </div>
</div>