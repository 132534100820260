<div class="wrap_body">
  <h4 class="title_m1">Add Recipient Details</h4>
  <div class="row">
    <div class="col-sm-12 mt-3 mb-3">
      <h6>Personal Details</h6>
    </div>
  </div>
  <form class="row " [formGroup]="formgroup">
    <div class="col-sm-6" formGroupName="candidate">
      <div class="form-group row">
        <label for="" class="col-sm-3 text-right col-form-label">First Name <span class="c_3">&ast;</span></label>
        <div class="col-sm-8">
          <input type="text" class="form-control" id="" formControlName="firstName" pattern="[a-zA-Z ]*"
            [ngClass]="{ 'is-invalid': submitted && f['candidate']['controls'].firstName.errors }">
          <div *ngIf="submitted && f.candidate.controls.firstName.errors" class="invalid-feedback">
            <div *ngIf="f.candidate.controls.firstName.errors.required">Field is required</div>
            <div *ngIf="f.candidate.controls['firstName'].errors.pattern">Invallid First Name</div>
         </div>
        </div>
      </div>
    </div>

    <div class="col-sm-6" formGroupName="candidate">
      <div class="form-group row">
        <label for="" class="col-sm-3 text-right col-form-label">Last Name <span class="c_3">&ast;</span></label>
        <div class="col-sm-8">
          <input type="text" class="form-control" id="" formControlName="lastName" pattern="[a-zA-Z ]*"
            [ngClass]="{ 'is-invalid': submitted && f['candidate']['controls'].lastName.errors }">
          <div *ngIf="submitted && f.candidate.controls.lastName.invalid" class="invalid-feedback">
            <div *ngIf="f.candidate.controls['lastName'].errors.required">Field is required</div>
            <div *ngIf="f.candidate.controls['lastName'].errors.pattern">Invallid Last Name</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-6" formGroupName="candidate">
      <div class="form-group row">
        <label for="" class="col-sm-3 text-right col-form-label">Email Id <span class="c_3">&ast;</span></label>
        <div class="col-sm-8">
          <input type="email" class="form-control" id="" formControlName="emailId"
            [ngClass]="{ 'is-invalid': submitted && f['candidate']['controls'].emailId.errors }">
          <div *ngIf="submitted && f.candidate.controls.emailId.invalid" class="invalid-feedback">
            <div *ngIf="f.candidate.controls['emailId'].errors.required">Field is required</div>
            <div *ngIf="f.candidate.controls['emailId'].errors.pattern">Invallid EmailId</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-6" formGroupName="candidate">
      <div class="form-group row">
        <label for="" class="col-sm-3 text-right col-form-label">Contact Number <span class="c_3">&ast;</span></label>
        <div class="col-sm-8">
          <input type="text" class="form-control" id="" formControlName="contactNumber"
            [ngClass]="{ 'is-invalid': submitted && f['candidate']['controls'].contactNumber.errors }" maxlength="10">
          <div *ngIf="submitted && f.candidate.controls.contactNumber.invalid" class="invalid-feedback">
            <div *ngIf="f.candidate.controls['contactNumber'].errors.required">Field is required</div>
            <div *ngIf="f.candidate.controls['contactNumber'].errors.pattern">Enter Valid ContactNo</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-6" formGroupName="candidate">
      <div class="form-group row">
        <label for="" class="col-sm-3 text-right col-form-label">Name As Per Aadhaar <span class="c_3">&ast;</span></label>
        <div class="col-sm-8">
          <input type="text" class="form-control" id="" formControlName="aadhaarName" pattern="[a-zA-Z ]*"
            [ngClass]="{ 'is-invalid': submitted && f['candidate']['controls'].aadhaarName.errors }">
          <div *ngIf="submitted && f.candidate.controls.aadhaarName.invalid" class="invalid-feedback">
            <div *ngIf="f.candidate.controls['aadhaarName'].errors.required">Field is required</div>
            <div *ngIf="f.candidate.controls['aadhaarName'].errors.pattern">Invallid Aadhaar name</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 mt-3 mb-3">
      <h6>Address</h6>
    </div>
    <div class="col-sm-6" formGroupName="address">
      <div class="form-group row">
        <label for="" class="col-sm-3 text-right col-form-label">H.No.</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" id="" formControlName="hno"
            [ngClass]="{ 'is-invalid': submitted && f['address']['controls'].hno.errors }">
          <div *ngIf="submitted && f['address']['controls'].hno.errors" class="invalid-feedback">
            <div *ngIf="f['address']['controls'].hno.errors.required">Field is required</div>
            <!-- <div *ngIf="f['address']['controls'].hno.errors.pattern">Invallid Characters Entered</div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6" formGroupName="address">
      <div class="form-group row">
        <label for="" class="col-sm-3 text-right col-form-label">Country</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" id="" formControlName="country" readonly
            [ngClass]="{ 'is-invalid': submitted && f['address']['controls'].country.errors }">
          <div *ngIf="submitted && f['address']['controls'].country.errors" class="invalid-feedback">
            <div *ngIf="f['address']['controls'].country.errors.required">Field is required</div>
            <div *ngIf="f['address']['controls'].country.errors.pattern">Invallid </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="col-sm-6" formGroupName="address">
      <div class="form-group row">
        <label for="" class="col-sm-3 text-right col-form-label">Street/Building Name</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" id="" formControlName="streat"
            [ngClass]="{ 'is-invalid': submitted && f['address']['controls'].streat.errors }">
          <div *ngIf="submitted && f['address']['controls'].streat.errors" class="invalid-feedback">
            <div *ngIf="f['address']['controls'].streat.errors.required">Field is required</div>
            <div *ngIf="f['address']['controls'].streat.errors.pattern">Invallid UserName</div>
          </div>
        </div>
      </div>
    </div>
    <!-- state -->
    <div class="col-sm-6" formGroupName="address">
      <div class="form-group row">
        <label for="" class="col-sm-3 text-right col-form-label">State</label>
        <div class="col-sm-8">
          <select name="" id="" class="form-control" (change)="selectState($event)" formControlName="state"
            [ngClass]="{ 'is-invalid': submitted && f['address']['controls'].state.errors }">
            <option value="">Select</option>
            <option value="{{data.name}}" *ngFor="let data of states">{{data.name}}</option>
          </select>
          <div *ngIf="submitted && f['address']['controls'].state.errors" class="invalid-feedback">
            <div *ngIf="f['address']['controls'].state.errors.required">Field is required</div>
            <div *ngIf="f['address']['controls'].state.errors.pattern">Invallid UserName</div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="col-sm-6" formGroupName="address">
      <div class="form-group row">
        <label for="" class="col-sm-3 text-right col-form-label">Landmark</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" id="" formControlName="landmark"
            [ngClass]="{ 'is-invalid': submitted && f['address']['controls'].landmark.errors }">
          <div *ngIf="submitted && f['address']['controls'].landmark.errors" class="invalid-feedback">
            <div *ngIf="f['address']['controls'].landmark.errors.required">Field is required</div>
            <div *ngIf="f['address']['controls'].landmark.errors.pattern">Invallid UserName</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-6" formGroupName="address">
      <div class="form-group row">
        <label for="" class="col-sm-3 text-right col-form-label">City</label>
        <div class="col-sm-8">
          <select name="" id="" class="form-control" (change)="selectCity($event)" formControlName="city"
            [ngClass]="{ 'is-invalid': submitted && f['address']['controls'].city.errors }">
            <option value="">Select</option>
            <option value="{{data.name}}" *ngFor="let data of cities">{{data.name}}</option>
          </select>
          <div *ngIf="submitted && f['address']['controls'].city.errors" class="invalid-feedback">
            <div *ngIf="f['address']['controls'].city.errors.required">Field is required</div>
            <div *ngIf="f['address']['controls'].city.errors.pattern">Invallid UserName</div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="col-sm-6" formGroupName="address">
      <div class="form-group row">
        <label for="" class="col-sm-3 text-right col-form-label">PinCode</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" id="" formControlName="pincode"
            [ngClass]="{ 'is-invalid': submitted && f['address']['controls'].pincode.errors }" maxlength="6">
          <div *ngIf="submitted && f['address']['controls'].pincode.errors" class="invalid-feedback">
            <div *ngIf="f['address']['controls'].pincode.errors.required">Field is required</div>
            <div *ngIf="f['address']['controls'].pincode.errors.pattern">Invallid Pincode</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 mt-3 mb-3">
      <h6>Send to approver</h6>
    </div>
    <!-- <div class="col-sm-6">
      <div class="form-group row" formGroupName="template">
        <label for="" class="col-sm-3 text-right col-form-label">Approved By</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" id="" readonly formControlName="approvedBy"
            [ngClass]="{ 'is-invalid': submitted && tempForm.approvedBy.errors }">
          <div *ngIf="submitted && tempForm.approvedBy.errors" class="invalid-feedback">
            <div *ngIf="tempForm.approvedBy.errors.required">Field is required</div>
            <div *ngIf="tempForm.approvedBy.errors.pattern">Invallid UserName</div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="col-sm-6" formGroupName="template">
      <div class="form-group row">
        <label for="" class="col-sm-3 text-right col-form-label">Company <span class="c_3">&ast;</span></label>
        <div class="col-sm-8">
            <select name="" id="" class="form-control" (change)="selectCompany()" formControlName="companyId" required
            [ngClass]="{ 'is-invalid': submitted && submitted && tempForm.companyId.errors }">
              <option value="">Select</option>
              <option value="{{data.companyId}}" *ngFor="let data of companyList">{{data.companyName}}</option>
            </select>
          <div *ngIf="submitted && tempForm.companyId.errors" class="invalid-feedback">
            <div *ngIf="tempForm.companyId.errors.required">Field is required</div>
            <div *ngIf="tempForm.companyId.errors.pattern">Invallid UserName</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-6" formGroupName="template">
      <div class="form-group row">
        <label for="" class="col-sm-3 text-right col-form-label">Template <span class="c_3">&ast;</span></label>
        <div class="col-sm-8">
          <select name="" id="" class="form-control" (change)="selecTemplet($event)" formControlName="templateName" required
            [ngClass]="{ 'is-invalid': submitted && tempForm.templateName.errors }">
            <option value="">Select</option>
            <option *ngFor="let data of templets">{{data.templateName}}</option>
          </select>
          <div *ngIf="submitted && tempForm.templateName.errors" class="invalid-feedback">
            <div *ngIf="tempForm.templateName.errors.required">Field is required</div>
            <div *ngIf="tempForm.templateName.errors.pattern">Invallid UserName</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-6" formGroupName="template">
      <div class="form-group row">
        <label for="" class="col-sm-3 text-right col-form-label">Trigger Date <span class="c_3">&ast;</span></label>
        <div class="col-sm-8">
          <input type="date" class="form-control" id="" formControlName="triggerDate"
            [ngClass]="{ 'is-invalid': submitted && tempForm.triggerDate.errors }">
          <div *ngIf="submitted && tempForm.triggerDate.errors" class="invalid-feedback">
            <div *ngIf="tempForm.triggerDate.errors.required">Field is required</div>
            <div *ngIf="tempForm.triggerDate.errors.pattern">Invallid UserName</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-6" formGroupName="template">
      <div class="form-group row d-flex">
        <label for="" class="col-sm-3 text-right col-form-label">Expiry Date <span class="c_3">&ast;</span></label>
        <div class="col-sm-8">
          <input type="date" class="form-control" id="" formControlName="expiryDate"
            [ngClass]="{ 'is-invalid': submitted && tempForm.expiryDate.errors }">
          <div *ngIf="submitted && tempForm.expiryDate.errors" class="invalid-feedback">
            <div *ngIf="tempForm.expiryDate.errors.required">Field is required</div>
            <div *ngIf="tempForm.expiryDate.errors.pattern">Invallid UserName</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 mt-3 mb-3">
      <h6>Variables Details</h6>
    </div>

    <div class="col-sm-12" >

      <div class="table-responsive">
        <table class="table  table-striped table_m1">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Name</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <ng-container formArrayName="variableDetails">
              <tr *ngFor="let service of formgroup.controls.variableDetails.controls; let i = index;" [formGroupName]="i">
                <td>{{i+1}}</td>
                <td><input type="text" class="form-control" formControlName="key" readonly></td>
                <td><input type="text" class="form-control" formControlName="value"></td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
    
    <div class="col-6 mt-3"><button class="btn btn_m3"  (click)="cancel()">Cancel</button></div>

    <div class="col-6 text-right mt-3" *ngIf="saveFlag && !backButtonFlag"><button class="btn btn_m4" type="button"
        (click)="openModal(template,formgroup)">Submit</button></div>
    <div class="col-6 text-right mt-3" *ngIf="!saveFlag && !backButtonFlag"><button class="btn btn_m4" type="button"
      (click)="updateCandidate()">Update</button></div>
    <div class="col-6 text-right mt-3" *ngIf="backButtonFlag"><button class="btn btn_m4" type="button"
      routerLink="/hr/appointment-list">Back</button></div>
  </form>
</div>

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Added Candidate </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p *ngIf="sucessMessage"><i class="fas fa-check c_2"></i> {{sucessMessage}}</p>
    <p *ngIf="errorMessage"><i class="fas fa-exclamation-triangle c_3"></i> {{errorMessage}}</p>
    <div class="text-right">
      <button class="btn btn_m5" (click)="submit()">Ok</button>
    </div>
  </div>
</ng-template>