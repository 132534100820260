import { Component, OnInit } from '@angular/core';
import {  TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-bulk-appointments',
  templateUrl: './bulk-appointments.component.html',
  styleUrls: ['./bulk-appointments.component.css']
})
export class BulkAppointmentsComponent implements OnInit {
  modalRef!: BsModalRef;
  constructor(private modalService: BsModalService) { }
  afuConfig = {
    uploadAPI: {
      url:"https://example-file-upload-api"
    },
    autoUpload: true,
    hideResetBtn: true,
    theme: "dragNDrop",
    hideProgressBar: false,
    replaceTexts: {
      selectFileBtn: 'Choose file',
      resetBtn: 'Reset',
      uploadBtn: 'Choose file',
      dragNDropBox: 'Drag and Drop',
      attachPinBtn: 'Attach Files...',
      afterUploadMsg_success: 'Successfully Uploaded !',
      afterUploadMsg_error: 'Upload Failed !',
      sizeLimit: 'Size Limit'
    }
};

openModal(template: TemplateRef<any>) {
  const initialState = {
    
};
 // this.modalRef = this.modalService.show(template);
  this.modalRef = this.modalService.show(template,
    {
      class: 'modal-dialog-centered model_1', initialState ,
      
    },
    
);
};
  ngOnInit(): void {
  }

}
