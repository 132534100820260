import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EncryptDecrypt } from 'src/app/encrypt-decrypt';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SuperuserService {

  constructor(private http: HttpClient, private encDec: EncryptDecrypt) { }

  encrypt(value: any): any {
    return this.encDec.encrypt(value);
  }

  public addUser(data: any){
    return this.http.post<any>(environment.url+`/esign/usermanagement/user`,data);
  }

  public editUser(data: any){
    return this.http.put<any>(environment.url+`/esign/usermanagement/user`,data);
  }

  public getuser(userId: any){
    return this.http.get<any>(environment.url+`/esign/usermanagement/user/${this.encrypt(userId)}`);
  }

  public userlist(companyId: any, status: any){
    return this.http.get<any>(environment.url+`/esign/usermanagement/userRole/${this.encrypt(companyId)}/${this.encrypt(status)}`);
  }

  public userActveInactive(userId: any, status: any){
    return this.http.put<any>(environment.url+`/esign/usermanagement/userId/${userId}/status/${status}`,{});
  }

  public addRole(data:any){
    return this.http.post<any>(environment.url+`/esign/usermanagement/role`,data);
  }
  public editRole(data:any){
    return this.http.put<any>(environment.url+`/esign/usermanagement/role`,data);
  }
  public assignedRolesToTheCompany(id:any, status: any){
    return this.http.get<any>(environment.url+`/esign/usermanagement/role/${this.encrypt(id)}/${this.encrypt(status)}`);
  }
  public assignedroleandFeaturesList(id:any){
    return this.http.get<any>(environment.url+`/esign/usermanagement/roleFeature/${this.encrypt(id)}`);
  }
  assignedrolemodificationData(data:any){
    return this.http.put<any>(environment.url+`/esign/usermanagement/roleFeature`,data);
  }
  public addRoletoUser(data:any){
    return this.http.post<any>(environment.url+`/esign/usermanagement/userRole`,data);
  }
  public assignedUserList(id:any, status: any){
    return this.http.get<any>(environment.url+`/esign/usermanagement/userRole/${this.encrypt(id)}/${this.encrypt(status)}`);
  }
  getAllcompanyDeatls() {
    return this.http.get<any>(environment.url + `/esign/company`);
  }

  deleteRole(data: any) {
    return this.http.post<any>(environment.url + `/esign/usermanagement/updateRoleStatus`, data);
  }
 
}
