import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './shared/login/login.component';
import { SharedModule } from './shared/shared.module';
 import { PagesModule } from './pages/pages.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CandidateModule } from './candidate/candidate.module';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HradminModule } from './hradmin/hradmin.module';
import { Validatorutil } from './validatorutil';
import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { AuthInterceptor } from './authinterceptor';
import {DataTablesModule} from 'angular-datatables';
import { ReportsModule } from './reports/reports.module';
import { ChartModule } from 'angular2-highcharts';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxUiLoaderHttpModule, NgxUiLoaderModule } from "ngx-ui-loader";
import { environment } from 'src/environments/environment';
import { EncryptDecrypt } from './encrypt-decrypt';
import {CookieService} from 'ngx-cookie-service';
 
 
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    PagesModule,
    HradminModule,
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    CandidateModule,
    TabsModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      timeOut:5000,
      progressBar:true,
      closeButton: true
    }),
    DataTablesModule.forRoot(),
   ReportsModule,
   ChartModule.forRoot(require('highcharts')),
   Ng2SearchPipeModule,
   HttpClientModule,
   NgxUiLoaderModule,
   NgxUiLoaderHttpModule.forRoot({
    showForeground: true,
    exclude: [environment.url + "/esign/masterTemplate/all", "https://2d9776e0-8f75-40aa-9a87-6ac7196737a2.mock.pstmn.io//v1/bulkupload/filetojson"],
   })
  ],
  providers: [Validatorutil, EncryptDecrypt, DatePipe, CookieService,{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent],
  schemas:[
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],

})
export class AppModule { }
