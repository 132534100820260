import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NotifyService } from 'src/app/notify.service';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  public formgroup: any;
  public submitted = false;
  errorMsgFlag = false;
  errorMessage = "Please provide valid Username!";
  constructor(private router: Router, private fb: FormBuilder, private loginService: LoginService,
    private route: ActivatedRoute, private notify: NotifyService) {
    this.formgroup = fb.group({
      loginId: ['', [Validators.required, Validators.pattern('')]]
    })
  }
  ngOnInit(): void {
  }

  forgotPwd() {
    this.errorMsgFlag = false;
    if (this.formgroup.invalid) {
      this.submitted = true;
      return;
    } else {

      this.loginService.forgotPwd(this.formgroup.value).subscribe(
        res => {
          if (res.status == "Success") {
            this.notify.showSuccess(res.message, 'Success')
            this.router.navigate(['/login']);
          }
        },
        (error) => {
          if(error?.error?.status === 'Error'){
            this.notify.showError(error?.error?.message, 'Failed')
          } else {
            this.notify.showError('Password updated failed.', 'Failed')
          }
        }
      )
    }
  }

  get f() {
    return this.formgroup.controls;
  }

}
