import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HradminComponent } from './hradmin.component';
import { AppointmentListComponent } from './appointment-list/appointment-list.component';
import { VariableMappingComponent } from './variable-mapping/variable-mapping.component';
import { CreateTemplateComponent } from './create-template/create-template.component';
import { TemplateComponent } from './template/template.component';
import { CreateCompanyComponent } from './create-company/create-company.component';
import { CompanyListComponent } from './company-list/company-list.component';
import { AddCandidateDetailsComponent } from './add-candidate-details/add-candidate-details.component';
import { BulkAppointmentsComponent } from './bulk-appointments/bulk-appointments.component';
import { WorkflowComponent } from './workflow/workflow.component';
import { HeaderComponent } from '../pages/header/header.component';
import { CreateCategoryComponent } from './create-category/create-category.component';
import { WorkflowlistComponent } from './workflowlist/workflowlist.component';
import { CategoryListComponent } from './category-list/category-list.component';
const routes: Routes = [{ path: '', component: HradminComponent,
children: [
  
  { path: 'appointment-list', component: AppointmentListComponent },
  { path: 'variable-mapping/:id', component: VariableMappingComponent },
  { path: 'create-template', component: CreateTemplateComponent },
  { path: 'template', component: TemplateComponent },
  { path: 'create-company', component: CreateCompanyComponent },
  { path: 'create-category', component: CreateCategoryComponent },
  { path: 'create-category/:id', component: CreateCategoryComponent },
  { path: 'companylist', component: CompanyListComponent },
  { path: 'AddCandidateDetails', component: AddCandidateDetailsComponent },
  { path: 'BulkAppointments', component: BulkAppointmentsComponent },
  { path: 'workflow', component: WorkflowComponent },
  { path: 'workflow/:id', component: WorkflowComponent },
  { path: 'workflowList', component: WorkflowlistComponent },
  { path: 'create-company/:id', component: CreateCompanyComponent },
  { path: 'categoryList', component: CategoryListComponent },
]
},
// { path: 'appointment-list', component: AppointmentListComponent }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HradminRoutingModule { }
