import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PdfServiceService {

  private authToken = sessionStorage.getItem('token'); // Replace with your actual authentication token
  baseUrl: any;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.url;
  }

  getPdfWithToken(fileUrl: string): Observable<any> {
    const url = this.baseUrl + fileUrl;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.authToken}`,
    });
    const options = { headers: headers, responseType: 'blob' as 'json' };
    return this.http.get(fileUrl, options);
  }

}
