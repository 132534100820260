<div class="wrap_body">
  <h4 class="title_m1">Create Category</h4>

  <form class="row " [formGroup]="formgroup" (ngSubmit)="addCategory()">
    <div class="col-sm-6">
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group row">
            <label for="" class="col-sm-3 text-right col-form-label">Company Name <span class="c_3">&ast;</span></label>
            <div class="col-sm-8">
               <select name="" id="" class="form-control" formControlName="companyId" (change)="selectCompany()"
               required [ngClass]="{ 'is-invalid': submitted && f.companyId.errors }">
                  <option value="">Select</option>
                  <option value="{{data.companyId}}" *ngFor="let data of companyList">{{data.companyName}}</option>
                </select>
                <div *ngIf="submitted && f.companyId.errors" class="invalid-feedback">
                  <div *ngIf="f.companyId.errors.required">Field is required</div>
                  <div *ngIf="f.companyId.errors.pattern">Invallid UserName</div>
                </div>
            </div>
         </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group row">
            <label for="" class="col-sm-3 text-right col-form-label">Category <span class="c_3">&ast;</span></label>
            <div class="col-sm-8">
              <input type="text" class="form-control" formControlName="name" required pattern="[a-zA-Z ]*"
                [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
              <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                <div *ngIf="f.name.errors.required">Field is required</div>
                <div *ngIf="f.name.errors.pattern">Invallid Name</div>
              </div>
              <div class="text-right">
                <div class="">
                  <button class="btn btn_m3 mr-2 my-4" type="button" (click)="cancel()">Cancel</button>
                  <button class="btn btn_m4" type="submit">save</button></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </form>
  
</div>