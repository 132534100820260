import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DetailsComponent } from './details/details.component';
import { UploadEducationalDocumentsComponent } from './upload-educational-documents/upload-educational-documents.component';
import { CandidateLayoutComponent } from './candidate-layout/candidate-layout.component';
const routes: Routes = [
  {

    path: 'candidate', component: CandidateLayoutComponent, 

    children: [
      { path: `details/candidateAppointment/:Id`,  component: DetailsComponent ,pathMatch:'full'},
      // { path: 'details/esign/candidate/candidateAppointment?candidateTemplateId',  component: DetailsComponent },
      { path: 'upload-educational-documents',  component: UploadEducationalDocumentsComponent },
      
    ]
    }]
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CandidateRoutingModule { }
