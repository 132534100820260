import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HrService } from '../services/hr.service';
import { environment } from 'src/environments/environment';
import { NotifyService } from 'src/app/notify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PdfServiceService } from 'src/app/pdf-service.service';
import { EncryptDecrypt } from 'src/app/encrypt-decrypt';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-appointment-list',
  templateUrl: './appointment-list.component.html',
  styleUrls: ['./appointment-list.component.css']
})
export class AppointmentListComponent implements OnInit {

  modalRef!: BsModalRef;
  offer_letter!: BsModalRef;
  candidateRemModel!: BsModalRef;
  public templateName = "";
  public appointmentTag = true;
  public appointmentSentTag = false;
  public candidates: any = [];
  sessionData: any;
  public candidateRemarks: any;
  public checkBoxFlag = false;
  public candidateTempletId: any;
  public statusRequest!: CandidateStatusRequest;
  public errorMessage: string = 'Please add remarks!';
  public errorMsgFlag: boolean = false;
  public baseUrl: any;
  public fileUrl: any;
  image: any;
  statusData = 'REVIEWER';
  appResponse: any;
  xml: any;
  menuList: any;
  term: string = '';
  recordCount: number = 10;
  popupFileName: any
  candidateTemplateId: any;
  verifyFlag: boolean = false;
  token: any;
  pdfSrc: any;
  downloadZipUrl: string = '/esign/uploadDetails/download/templateLetterCandidateId/';
  constructor(private modalService: BsModalService, private notify: NotifyService, private encDec: EncryptDecrypt,
    private http: HttpClient, private hrService: HrService, private route: ActivatedRoute,
    private router: Router, private pdfService: PdfServiceService, private ngxService: NgxUiLoaderService) {
    var data: any= sessionStorage.getItem('loginDetails');
    this.sessionData = JSON.parse(data);
    this.baseUrl = environment.url;
    var menu: any = sessionStorage.getItem('menuList');
    this.menuList = JSON.parse(menu);
  }

  ngOnInit(): void {
    this.token = sessionStorage.getItem('token');
    this.ngxService.start();
    setTimeout(() => {
      this.ngxService.stop();
    }, 3000);
    this.appointMentList();
  }
  pageConfig = {
    id: 'pagination',
    itemsPerPage:this.recordCount,
    currentPage: 1,
  };
  appointMentList() {
    this.hrService.candidateList(this.sessionData.companyId, this.sessionData.userId).subscribe(
      res => {
        this.appResponse = res.respObject;
        this.selectStatus(null);
      }
    )
  }

  encrypt(value: any): any {
    return this.encDec.encrypt(value);
  }

  loadPdf(fileUrl: any){
    this.pdfService.getPdfWithToken(fileUrl).subscribe((pdfBlob) => {
      this.pdfSrc = window.URL.createObjectURL(pdfBlob);
    });
  }

  selectStatus(event:any){
    this.statusData = event ? event.currentTarget.value : this.statusData;
    this.candidates = [];
    if (this.statusData == 'REVIEWER' && this.appResponse?.REVIEWER) {
      this.candidates = this.appResponse?.REVIEWER.map((item: any) => {
        return {
          ...item,
          selected: false,
          approver : this.sessionData.emailId
        };
      });
    } else if(this.statusData == 'ISSUER' && this.appResponse?.ISSUER){
      this.candidates = this.appResponse?.ISSUER.map((item: any) => {
        return {
          ...item,
          selected: false,
          approver : this.sessionData.emailId
        };
      });
    } else if(this.statusData == 'APPROVER' && this.appResponse?.APPROVER){
      this.candidates = this.appResponse?.APPROVER.map((item: any) => {
        return {
          ...item,
          selected: false,
          approver : this.sessionData.emailId
        };
      });
    }
  }

  candidateReview(){
    this.hrService.candidateLatterReview(this.statusRequest).subscribe(res => {
      if (res) {
        if (res.status == "Success") {
          this.notify.showSuccess('Saved Successfully', res.status);
          if (res.respObject != '' && res.respObject.includes('<')) {
            this.xml = res.respObject;
            this.submitForm(this.xml);
            // this.hrService.sendXml(this.xml);
          }
          this.appointMentList();
        }
      }
    },
    (error) =>{
      if (error?.error?.status == 'Error') {
        this.notify.showError(error?.error?.message, 'Failed');
      }
    })  
  }

  statusEvent(event: any, status: any) {
    const approver = this.candidates.filter((candidate: { templateCandidateId: any; }) => candidate.templateCandidateId).approver ? 
    this.candidates.filter((candidate: { templateCandidateId: any; }) => candidate.templateCandidateId).approver : "raju@gmail.com";
    this.statusRequest = {
      approverId: this.sessionData.userId,
      remarks: "",
      status: status,
      templateCandidateId: event.templateCandidateId
    }
    this.candidateRemarks = '';
  }

  saveEvent(){
    this.statusRequest.remarks = this.candidateRemarks;
    console.log(this.statusRequest);
    this.candidateRemarks = '';
    if (!this.statusRequest.remarks) {
      // this.errorMsgFlag = true;
      this.notify.showInfo('Please add Remarks!', 'Info')
      return;
    }
    this.candidateReview();
    this.candidateRemModel.hide()
  }

  selectAll(event:any){
    if (event.currentTarget.checked) {
      this.candidates = this.candidates.map((item: any) => {
        return {
          ...item,
          selected: true
        };
      });
      this.checkBoxFlag = true;
    } else {
      this.candidates = this.candidates.map((item: any) => {
        return {
          ...item,
          selected: false
        };
      });
    }
  }

  checkEvent(){
    // !event.currentTarget.checked ? this.checkBoxFlag = this.candidates.filter((candidate: any) => !candidate.selected).length > 0 ? false : true :
    this.checkBoxFlag = this.candidates.filter((candidate: any) => !candidate.selected).length > 0 ? false : true;
  }

  downloadFile(id: any) {
    const url = this.baseUrl + '/esign/candidate/originalfile/download/' + this.encrypt(id);
    const timestamp = new Date().getTime();
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/octet-stream',
        'Authorization': `Bearer ${this.token}`
      }
    })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `template-${timestamp}.pdf`; // Specify the desired file name
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  verify(name?: any){
    if (!this.candidateTemplateId && this.candidateTemplateId === '') {
      return;
    }
    this.hrService.verifySign(this.candidateTemplateId).subscribe(
      res => {
        this.notify.showSuccess('Verified successfully', res.status);
        this.appointMentList();
        this.verifyFlag = false;
      },
      (error) => {
        if (error?.error?.status == 'Error') {
          console.log('error: ->' + error?.error)
          this.notify.showError(error?.error?.message, 'Failed');
        }
      }
    )
  }

  sendAppointment(candidate?: any) {
    let selectedOptions;
    if (candidate) {
      candidate.selected = true;
      this.checkEvent();
      selectedOptions = this.candidates.filter((candidate: { selected: any; }) => candidate.selected);
    } else {
      selectedOptions = this.candidates.filter((candidate: { selected: any; }) => candidate.selected);
    }
    let candidateTemplateId = selectedOptions.length > 0 ? selectedOptions[0].templateCandidateId : candidate.templateCandidateId;
    this.hrService.sendAppointment(candidateTemplateId).subscribe(
      res => {
        this.notify.showSuccess('Send Appointment Successfully', res.status);
        this.appointMentList();
      },
      (error) =>{
        if (error.error.status == 'Error') {
          console.log('error: ->' + error.error)
          this.notify.showError('Appointment Not Send Successfully', 'Failed');
        }
      })
  }

  getName(firstName:any, lastName:any){
    if (firstName && lastName) {
      return firstName + ' ' + lastName;
    } else if(firstName) {
      return firstName;
    } else {
      return lastName;
    }
  }

  openModal(template: TemplateRef<any>) {
    const initialState = {
    };
    // this.modalRef = this.modalService.show(template);
    this.modalRef = this.modalService.show(template,
      {
        class: 'modal-dialog-centered model_1', initialState,
      },
    );
  }
  openCandidateRemModal(template: TemplateRef<any>) {
    const initialState = {
    };
    this.candidateRemModel = this.modalService.show(template,
      {
        class: 'modal-dialog-centered model_1', initialState,
      },
    );
  }

  offer_letter_link(template: any, url: any, fileName: any, candidateTemplateId: any, signVerified: any, reviewStatus: any) {
    
    if ((signVerified === 'false' || !signVerified) && reviewStatus === 'ACCEPTED') {
      this.candidateTemplateId = candidateTemplateId;
      this.verifyFlag = true;
    } else {
      this.verifyFlag = false;
    }
    this.popupFileName = fileName;
    const initialState = {
    };
    this.fileUrl = this.baseUrl+ '/esign' + url;
    this.loadPdf(this.fileUrl);
    this.offer_letter = this.modalService.show(template,
      {
        class: 'modal-dialog-centered model_1 modal-lg', initialState,
      },
    );
  }

  sendappointMent(template4: any) {
    const initialState = {
    };
    this.offer_letter = this.modalService.show(template4,
      {
        class: 'modal-dialog-centered model_1', initialState,
      },
    );
  }

  downloadZip(id: any) {
    const url = this.baseUrl + this.downloadZipUrl + this.encrypt(id);
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token}`,
    });
    const options = { headers: headers, responseType: 'blob' as 'json' };
    this.http.get(url, options).subscribe((data) => {
      const link = document.createElement('a');
      link.download = 'your-file.zip';
      link.click();
    });
  }

  downloadZipFile(id: any) {
    const url = this.baseUrl + this.downloadZipUrl + this.encrypt(id);
    const authToken = this.token;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${authToken}`,
    });
    const options = {
      headers: headers,
      responseType: 'blob' as 'json', // Set the response type to blob to handle binary data (e.g., a ZIP file).
    };
    this.http.get(url, options).subscribe((data: any) => {
      const blob = new Blob([data], { type: 'application/zip' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'your-file.zip'; 
      a.click();
      window.URL.revokeObjectURL(url);
    });
  }

  appointMentSented() {
    this.offer_letter.hide();
    this.appointmentSentTag = true;
    this.appointmentTag = false;
    this.appointMentList();
  }
  appointMentNotSented() {
    // this.offer_letter.hide();
    this.candidateRemModel.hide();
  }

  approveOrRejectLater(status: any){
    console.log(status)
  }

  submitForm(xml: any) {
    console.log(xml);
    // this.formUrl = 'https://pregw.esign.egov-nsdl.com/nsdl-esp/authenticate/esign-doc/';
    const formData = new FormData();
    formData.append('msg', xml);
    const url = 'https://pregw.esign.egov-nsdl.com/nsdl-esp/authenticate/esign-doc/';
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = url;
  
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = 'msg';
    input.value = xml
    form.appendChild(input);
  
    const newWindow = window.open('', '_blank');
    if (newWindow) {
      newWindow.document.body.appendChild(form);
      form.submit();
    }
  }
  checkAccessBolk(menu: any){
    return this.menuList.some((item: {
      edit: boolean; featureName: any; 
    }) => item.featureName.toLowerCase() === menu.toLowerCase() && item.edit);
  }

  checkAccess(menu: any){
    return this.menuList.some((item: {
      edit: boolean; featureName: any; 
    }) => item.featureName.toLowerCase() === menu.toLowerCase() && item.edit);
  }

}

interface CandidateStatusRequest {
  "approverId": any,
  "remarks": string,
  "status": string,
  "templateCandidateId": any
}


