import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { LayoutComponent } from './layout/layout.component';
 import { BulkAppointmentsComponent } from './bulk-appointments/bulk-appointments.component';
 import { AddCandidateDetailsComponent } from './add-candidate-details/add-candidate-details.component';
 import { AppointmentListComponent } from './appointment-list/appointment-list.component';
 import { CreateTemplateComponent } from './create-template/create-template.component';
import { CreateCompanyComponent } from '../hradmin/create-company/create-company.component';
import { AuthGuard } from '../shared/auth/auth.guard';

const routes: Routes = [
  {

    path: 'hr', component: LayoutComponent, 

    children: [
      { path: 'header',  component: HeaderComponent, canActivate: [AuthGuard]  },
      { path: 'bulk-appointments',  component: BulkAppointmentsComponent, canActivate: [AuthGuard] },
      { path: 'add-Candidate',  component: AddCandidateDetailsComponent, canActivate: [AuthGuard]  },
      { path: 'appointment-list',  component: AppointmentListComponent, canActivate: [AuthGuard]  },
      { path: 'create-template',  component: CreateTemplateComponent, canActivate: [AuthGuard]  },
      { path: 'create-company',  component: CreateCompanyComponent, canActivate: [AuthGuard]  },
      { path: 'add-Candidate/:id',  component: AddCandidateDetailsComponent, canActivate: [AuthGuard]  }
    ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
