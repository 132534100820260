import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { TemplateRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgxExtendedPdfViewerService } from 'ngx-extended-pdf-viewer/public_api';
import { environment } from 'src/environments/environment';
import { CandidateService } from '../services/candidate.service';
import { NotifyService } from 'src/app/notify.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EncryptDecrypt } from 'src/app/encrypt-decrypt';
import { PdfServiceService } from 'src/app/pdf-service.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit, AfterViewInit {
  public response: any;
  public candidateDeatils: any = {};
  modalRef!: BsModalRef;
  modalRef1!: BsModalRef;
  public baseurl = environment.url;
  public trustedUrl: any;
  acceptedFlag: boolean = true;
  continueFlag: boolean = false;
  popupFileName: any;
  public baseUrl: any;
  candidateTemplateId: any;
  otp: any;
  otpVerified: boolean = false;
  myTemplate!: TemplateRef<any>;
  token: any;
  @ViewChild('verifyButton') verifyButton!: ElementRef;
  pdfSrc: any;
  downloadZipUrl: string = '/esign/uploadDetails/download/templateLetterCandidateId/';
  constructor(private modalService: BsModalService, private encDec: EncryptDecrypt,
    private candidateService: CandidateService, private notify: NotifyService,
    private router: Router, public sanitizer: DomSanitizer, private http: HttpClient,
    private activatedRoute: ActivatedRoute, private renderer: Renderer2, 
    private pdfService: PdfServiceService) { }
  
  ngAfterViewInit(): void {
    if (this.verifyButton) {
        this.renderer.selectRootElement(this.verifyButton.nativeElement).click();
    }
  }

  encrypt(value: any): any {
    return this.encDec.encrypt(value);
  }

  ngOnInit(): void {
    sessionStorage.clear();
    this.baseUrl = environment.url;
    this.candidateTemplateId = this.activatedRoute.snapshot.params['Id'];
    var encrpt = this.encDec.encrypt(this.candidateTemplateId);
        console.log('Encrypt: '+ encrpt);
    console.log('Dncrypt: '+ this.encDec.decrypt(encrpt));
    // this.encDec.decryptData();

    // var enc = this.encDec.encryptP('Hi');
    // console.log(enc)
    // console.log(this.encDec.decryptP(enc))
    // const buttonElement = document.getElementById('popup');
    // if (buttonElement) {
    //   buttonElement.click(); 
    // }
  }

  loadPdf(fileUrl: any){
    this.pdfService.getPdfWithToken(fileUrl).subscribe((pdfBlob) => {
      this.pdfSrc = window.URL.createObjectURL(pdfBlob);
    });
  }

  candidateDetails() { 
    this.candidateService.candidatedeatils(this.candidateTemplateId).subscribe(
      res => {
        this.candidateDeatils = res.respObject[0];
        sessionStorage.setItem('templateCandidateId', this.candidateDeatils?.templateCandidateId);
        sessionStorage.setItem('candidateName', this.candidateDeatils.candidateDto.firstName + ' ' +  this.candidateDeatils.candidateDto.lastName);
        sessionStorage.setItem('aadhaarSign', this.candidateDeatils?.templateDto?.aadhaarSign);
        sessionStorage.setItem('upload', this.candidateDeatils?.templateDto?.upload);
        sessionStorage.setItem('reviewStatus', this.candidateDeatils?.reviewStatus)
        this.trustedUrl = this.baseurl + `/esign` + `${this.candidateDeatils.fileUrl}`;
        this.acceptedFlag = this.candidateDeatils?.reviewStatus === 'ACCEPTED' ? false : true;
        this.continueFlag = this.candidateDeatils?.reviewStatus === 'REJECTED' ? true : false;
      }
    )
  }

  openModal(template: TemplateRef<any>, fileName: any) {
    this.popupFileName = fileName;
    const initialState = {
    };
    this.loadPdf(this.trustedUrl);
    this.modalRef = this.modalService.show(template,
      {
        class: 'modal-dialog-centered model_1 modal-lg', initialState,
      },
    );
  }
  popupModal(template1: TemplateRef<any>) {
    this.generateOtpToVerifyCandidate()
    const initialState = {

    };
    this.modalRef1 = this.modalService.show(template1,
      {
        class: 'modal-dialog-centered model_1', initialState,
      },
    );

  }

  downloadFile(id: any){
    const url = this.baseUrl + '/esign/candidate/originalfile/download/' + this.encrypt(id);
    const timestamp = new Date().getTime();
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/octet-stream', // Adjust the content type as needed
        'Authorization': `Bearer ${this.token}`
      }
    })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `template-${timestamp}.pdf`; // Specify the desired file name
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  downloadZip(id: any) {
    // const link = document.createElement('a');
    // link.href = url;
    // link.download = 'your-file.zip';
    // link.click();
    const url = this.baseUrl + this.downloadZipUrl + this.encrypt(id);
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token}`,
    });
    const options = { headers: headers, responseType: 'blob' as 'json' };
    this.http.get(url, options).subscribe((data) => {
      const link = document.createElement('a');
      link.download = 'your-file.zip';
      link.click();
    });
  }

  downloadZipFile(id: any) {
    const url = this.baseUrl + this.downloadZipUrl + this.encrypt(id);
    const authToken = this.token;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${authToken}`,
    });
    const options = {
      headers: headers,
      responseType: 'blob' as 'json', // Set the response type to blob to handle binary data (e.g., a ZIP file).
    };
    this.http.get(url, options).subscribe((data: any) => {
      const blob = new Blob([data], { type: 'application/zip' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'your-file.zip'; 
      a.click();
      window.URL.revokeObjectURL(url);
    });
  }

  approveLetter(template1: TemplateRef<any>, value: any) {
    this.candidateService.candidateleterApprove(this.candidateDeatils.templateCandidateId, value, null).subscribe(
      res => {
        console.log(res);
        this.popupModal(template1);
        this.response = res.message;
        this.candidateDetails();
      },
      (error) => {
        if (error?.error?.status === 'Error') {
          this.notify.showError(error?.error?.message, 'Failed')
        } else {
          this.notify.showError('Can not be process.', 'Failed')
        }
      }
    )
  }
  afterApproval() {
    this.router.navigate(['/candidate/upload-educational-documents']);
  }

  getAddress(address: any) {
    var addrs = !!address ? 
    (!!address.hno ? address.hno : '') + '' +
    (!!address.landmark ? ' ' + address.landmark : '') + '' +
    (!!address.city ? ' ' + address.city : '') + '' +
    (!!address.stateOfCountry ? ' ' + address.stateOfCountry : '') + '' +
    (!!address.country ? ' ' + address.country : '') + '' + 
    (!!address.pincode ? ' ' + address.pincode : '') 
    : ''; 
    return addrs;
  }

  generateOtpToVerifyCandidate() {
    let request: any = {
      objectId: this.candidateTemplateId,
      txnObject: "candidate",
      reason: "login"
    }
    this.candidateService.generateOtpService(request).subscribe(
      res => {
        if (res.status == "Success") {
          if (res.respObject) {
            sessionStorage.setItem('trnxKey', res.respObject);
            this.notify.showSuccess('OTP has send to your registered email.', res.status);
          }
        }
      },
        (error) => {
          console.log('error: ', error)
          if (error?.error?.status === 'Error') {
            this.notify.showError(error?.error?.message, 'Failed')
          } else {
            this.notify.showError('Failed to send OTP to your registered email.', 'Failed');
          }
        }
    )
  }

  verifyOtpAndGetToken(){
    if (!this.otp || this.otp === '') {
      this.notify.showInfo('Please enter OTP.', 'Info');
      return;
    }
    let request: any = {
      otp: this.otp,
      transcationUniqueId: sessionStorage.getItem('trnxKey'),
      txnObject: "candidate",
      reason: "login"
    }
    this.candidateService.verifyOtpGetToken(request).subscribe(
      res => {
        if (res.status == "Success") {
          if (res.respObject) {
            this.token = res.respObject?.token;
            sessionStorage.setItem('token', this.token);
            this.candidateDetails();
            this.otpVerified = true;
            this.modalRef1.hide();
            console.log(res.respObject)
            this.notify.showSuccess('OTP verified.', res.status);
          }
        }
      },
      (error) => {
        // this.otpVerified = true;
        console.log('error: ', error)
        if (error?.error?.status === 'Error') {
          this.notify.showError(error?.error?.message, 'Failed');
        } else {
          this.notify.showError('Otp varification failed!', 'Failed');
        }
      }
    )
  }

}
