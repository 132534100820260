<div class="wrap_body">
  <h4 class="title_m1">Create Template</h4>
  <form class="row " [formGroup]="formgroup" (submit)="addTemplate()">
    <div class="col-sm-6">
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group row">
            <label for="" class="col-sm-3 text-right col-form-label">Company Name</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" readonly value="sarvHR">
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group row">
            <label for="" class="col-sm-3 text-right col-form-label">Category</label>
            <div class="col-sm-8">
              <select name="" id="" class="form-control" formControlName="category"
                [ngClass]="{ 'is-invalid': submitted && f.category.errors }">
                <option value="">SELECT</option>
                <option value="Appointment Letter">Appointment Letter</option>
                <option value="Offer Letter">Offer Letter</option>
              </select>
              <div *ngIf="submitted && f.category.errors" class="invalid-feedback">
                <div *ngIf="f.category.errors.required">Field is required</div>
                <div *ngIf="f.category.errors.pattern">Invallid UserName</div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-12">
          <div class="form-group row">
            <label for="" class="col-sm-3 text-right col-form-label">Template Name</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" id="" formControlName="templateName"
                [ngClass]="{ 'is-invalid': submitted && f.templateName.errors }">
              <div *ngIf="submitted && f.templateName.errors" class="invalid-feedback">
                <div *ngIf="f.templateName.errors.required">Field is required</div>
                <div *ngIf="f.templateName.errors.pattern">Invallid UserName</div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-12">
          <div class="form-group row">
            <label for="" class="col-sm-3 text-right col-form-label">Document</label>
            <div class="col-sm-8">
              <div class="file_m1">
                <angular-file-uploader [config]="afuConfig" (fileSelected)="fileSelected($event)"
                  (ApiResponse)="docUpload($event)"> >
                </angular-file-uploader>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="table_m3" *ngIf="uploadResponseFlag">
        <table>
          <thead>
            <tr>
              <th>Variable</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let user of variblesData ">
              <td>{{user}}</td>
              <td>
                <select name="" id="" class="sel_m2" (change)="changeValues($event)">
                  <option value="">SELECT</option>
                  <option *ngFor="let data of templateNames">{{data}}</option>

                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-6 mt-3 text-right">
      <button class="btn btn_m3">Cancel</button>
      <button class="btn btn_m4" type="submit">save</button>
    </div>
   
  </form>

  <div class="wrap_body">
    <div class="row">
      <!-- <div class="col-sm-9 text-right">
   <button class="btn btn_un1 btn_m6 text-uppercase mr-3">reject</button>
   <button class="btn btn_un1 btn_m7 text-uppercase"  type="button"  (click)="openModal(template)">approve</button>
</div> -->

      <div class="col-sm-12">
        <div class="table-responsive">
          <table class="table  table-striped table_m1">
            <thead>
              <tr>
                <th scope="col">Company</th>
                <th scope="col">Category</th>
                <th>Template Name</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let template of templateArray">
                <td>sarvHR</td>
                <td>{{template.CompanyCategoryId}}</td>
                <td>{{template.templateId}}</td>
                <td>PENDING</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>