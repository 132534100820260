import { DatePipe } from '@angular/common';
import { HttpBackend, HttpClient, HttpHandler, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TemplateRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { error } from 'console';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HradminService } from 'src/app/hradmin/services/hradmin.service';
import { NotifyService } from 'src/app/notify.service';
import { Validatorutil } from 'src/app/validatorutil';
import { HrService } from '../services/hr.service';
@Component({
  selector: 'app-add-candidate-details',
  templateUrl: './add-candidate-details.component.html',
  styleUrls: ['./add-candidate-details.component.css']
})
export class AddCandidateDetailsComponent implements OnInit {
  public formgroup: any;
  public submitted = false;
  modalRef!: BsModalRef;
  public sucessMessage = "";
  public errorMessage = "";
  templets: any;
  cities: any;
  states: any;
  countries: any;
  templetId: any;
  cityId: any;
  stateId: any;
  countryId: any;
  request!: CandidateRequest;
  address!: Address;
  templet!: Templet;
  candidate!: Candidate
  imageUrl: any | Blob;
  file: any | Blob;
  sessionData: any;
  companyList: any;
  candidateId: any;
  templateCandidateId: any = null;
  saveFlag: boolean = true;
  backButtonFlag: boolean = false;
  variableDetails: any;
  variableList: any;

  constructor(private modalService: BsModalService, private validor: Validatorutil, private hradminService: HradminService,
    private router: Router, private fb: FormBuilder, private http: HttpClient, private hrService: HrService,
    private datePipe: DatePipe, private notify: NotifyService, private route: ActivatedRoute) {
    var data: any = sessionStorage.getItem('loginDetails');
    this.sessionData = JSON.parse(data);
    this.formgroup = this.fb.group({
      candidate: this.fb.group({
        firstName: ['', [Validators.required, Validators.pattern(validor.NAME_REGEXP)]],
        lastName: ['', [Validators.required, Validators.pattern(validor.NAME_REGEXP)]],
        emailId: ['', [Validators.required, Validators.pattern(validor.EMAIL_REGEXP)]],
        contactNumber: ['', [Validators.required, Validators.pattern(validor.PHONE_REGEXP)]],
        aadhaarName: ['', [Validators.required, Validators.pattern(validor.NAME_REGEXP)]]
      }),
      address: this.fb.group({
        hno: ['', [Validators.pattern(validor.HNO_REGEXP)]],
        landmark: ['', [Validators.pattern(validor.NAME_REGEXP)]],
        streat: ['', [Validators.pattern(validor.NAME_REGEXP)]],
        state: ['', [Validators.pattern(validor.NAME_REGEXP)]],
        city: ['', [Validators.pattern(validor.NAME_REGEXP)]],
        pincode: ['', [Validators.pattern(validor.ZIP_REGEXP)]],
        country: ['India', [Validators.pattern(validor.NAME_REGEXP)]],
        id: [null]
      }),
      template: this.fb.group({
        // approvedBy: [this.sessionData.emailId],
        company: [this.sessionData.roleName != 'Super Admin' ? this.sessionData.companyName : ''],
        templateId: [''],
        // position:[''],
        templateName: [''],
        triggerDate: [this.datePipe.transform(new Date().toISOString().substring(0, 10), 'dd/MM/yyyy'), [Validators.required]],
        expiryDate: [this.datePipe.transform(new Date().toISOString().substring(0, 10), 'dd/MM/yyyy'), [Validators.required]],
        companyId: [this.sessionData.roleName != 'Super Admin' ? this.sessionData.companyId : ''] 
      }),
      variableDetails: this.fb.array([])
    })
  }

  ngOnInit(): void {
    this.candidateId = this.route.snapshot.paramMap.get('id');
    this.countryId = 101;
    this.templtetList(this.formgroup.value.template.companyId);
    this.countryList(this.countryId);
    this.stateList(this.countryId);
    // this.cityList(4012);
    this.getCompanyList()
    if (this.candidateId && this.candidateId != '') {
      this.loadCandidateDetails();
      this.saveFlag = false;
    }
  }

  loadCandidateDetails(){
    this.hradminService.getCandidateDeatls(this.candidateId).subscribe(
      res => {
        if(res.status == "Success"){
          const data = res.respObject[0];
          console.log(data)
          this.templtetList(data.companyId);
          this.templateCandidateId = data.templateCandidateId;
          if (data.reviewStatus === "APPROVED" || data.reviewStatus === "ACCEPTED" || data.reviewStatus === "REJECTED") {
            this.backButtonFlag = true;
          }
          setTimeout(() => {
            if (!this.cities) {
              this.cityList(this.states.filter((value: { name: string; }) => value.name === data.candidateDto.addressDto.stateOfCountry)[0]?.id)
            }
            this.formgroup.patchValue({
              candidate : {
                firstName: data.candidateDto.firstName,
                lastName: data.candidateDto.lastName,
                emailId: data.candidateDto.emailId,
                contactNumber: data.candidateDto.contactNumber,
                aadhaarName: data.candidateDto.aadhaarName
              },
              address: {
                id: data.candidateDto.addressDto.id,
                hno: data.candidateDto.addressDto.hno,
                landmark: data.candidateDto.addressDto.landmark,
                streat: data.candidateDto.addressDto.street,
                state: data.candidateDto.addressDto.stateOfCountry,
                city: data?.candidateDto?.addressDto?.city,
                pincode: data.candidateDto.addressDto.pincode,
                // country: data.candidateDto.addressDto.country,
              },
              template: {
                approvedBy: data.approver,
                company: data.companyName,
                templateId: data.templateDto.templateId,
                templateName: data.templateDto.templateName,
                triggerDate: this.getDate(data.triggerDate),
                expiryDate: this.getDate(data.expiryDate),
                companyId: data.companyId 
              }
            })
            this.getCandidateVariables();
          }, 500);
          
        }
      }
    )
  }

  updateCandidate(){
    if (this.formgroup.invalid) {
      console.log(this.formgroup);
      this.submitted = true;
      return;
    } else {
      this.validate();
      console.log(this.request)
      this.hrService.updateCandidate(this.request).subscribe(
        result => {
          if (result) {
            if (result.status == "Success") {
              this.notify.showSuccess('Updated Successfully.', result.status)
              this.router.navigate(['/hr/appointment-list']);
            }
          }
        },
        (error) => {
          console.log('error: ->' + error)
          if (error?.error?.status === 'Error') {
            this.notify.showError(error?.error?.message, 'Failed')
          } else {
            this.notify.showError('Failed to update.', 'Failed')
          }
        }
      )
    }
  }

  getDate(date: string){
    if (date) {
      let dates: any = date.split('/');
        return dates[2]+"-"+dates[1]+"-"+dates[0];
    }
    return '';
  }

  getCompanyList(){
    this.hradminService.getAllcompanyDeatls().subscribe(
      res => {
        if (res.status == 'Success') {
          this.companyList = res.respObject;
        }
      },
      (error) => {
        console.log('error: ' + error);
        if (error?.error?.status === 'Error') {
          this.notify.showError(error?.error?.message, 'Failed')
        }
      }
    )
  }
  
  getCandidateVariables(){
    if (!this.templateCandidateId || this.templateCandidateId === '') {
      this.templateCandidateId = null;
    }
    if (this.formgroup.value.template.templateId == '') {
      this.formgroup.value.template.templateId = 0;
    }
    this.hradminService.getCandidateVariables(this.formgroup.value.template.templateId, this.templateCandidateId).subscribe(
      res => {
        if (res.status == 'Success') {
          this.variables.clear();
          console.log(res.respObject)
          res.respObject.forEach((feature: {templateVariableId: any, candidateTemplateVariableId: any, candidateTemplateId: any, key: any, value: any, id: any}, index:any ) => {
            const variableValue = this.fb.group({
                templateVariableId: new FormControl(feature.templateVariableId),
                candidateTemplateVariableId: new FormControl(feature.candidateTemplateVariableId),
                candidateTemplateId: new FormControl(feature.candidateTemplateId),
                key: new FormControl(feature.key), // feature.key.split('$')[1].toUpperCase()
                value: new FormControl(feature.value),
                id: new FormControl(feature.id),
              })
            this.variables.push(variableValue)
          });
        }
      },
      (error) => {
        console.log('error: ' + error);
        if (error?.error?.status === 'Error') {
          this.notify.showError(error?.error?.message, 'Failed')
        }
      }
    )
  }


  openModal(template: any, formgroup: any) {
    if (this.formgroup.invalid) {
      console.log(formgroup);
      this.submitted = true;
      return;
    } else {
      this.validate();
      this.hrService.rigisterCandidate(this.request).subscribe(
        result => {
          if (result) {
            if (result.status == "Success") {
              this.notify.showSuccess('Saved Successfully', result.status)
              this.router.navigate(['/hr/appointment-list']);
            }
          }
        },
        (error) => {
          console.log('error: ->' + error)
          if (error?.error?.status === 'Error') {
            this.notify.showError(error?.error?.message, 'Failed')
          } else {
            this.notify.showError('Failed to Saved', 'Failed')
          }
        }
      )
    }
    
  }

  validate() {
    this.address = {
      id: this.formgroup.value.address.id && this.formgroup.value.address.id != '' ? this.formgroup.value.address.id : null,
      hno: this.formgroup.value.address.hno,
      street: this.formgroup.value.address.streat,
      city: this.formgroup.value.address.city,
      cityId: this.cityId ? this.cityId : this.cities?.filter((data: { name: any; }) => data?.name === this.formgroup.value.address?.city)[0]?.id,
      stateOfCountry: this.formgroup.value.address.state,
      stateId: this.formgroup.value.address?.stateId ? this.formgroup.value.address.stateId : this.states?.filter((data: { name: any; candidateDto: { addressDto: { stateOfCountry: any; }; }; }) => data.name === this.formgroup.value.address?.state)[0]?.id,
      country: this.formgroup.value.address.country,
      countryId: this.countryId,
      pincode: this.formgroup.value.address.pincode,
      landmark: this.formgroup.value.address.landmark,
      localArea: "",
      location: ""
    }
    this.candidate = {
      addressDto: this.address,
      contactNumber: this.formgroup.value.candidate.contactNumber,
      emailId: this.formgroup.value.candidate.emailId,
      firstName: this.formgroup.value.candidate.firstName,
      lastName: this.formgroup.value.candidate.lastName,
      aadhaarName: this.formgroup.value.candidate.aadhaarName,
      id: this.candidateId && this.candidateId != '' ? this.candidateId : null
    }
    this.templet = {
      templateId: this.formgroup.value.template.templateId ? this.formgroup.value.template.templateId : this.templetId,
      templateName: this.formgroup.value.template.templateName,
      companyId: Number(this.sessionData.companyId)
    }
    this.request = {
      candidateDto: this.candidate,
      templateDto: this.templet,
      templateCandidateId: this.templateCandidateId,
      companyId: Number(this.sessionData.companyId),
      triggerDate: this.datePipe.transform(this.formgroup.value.template.triggerDate, 'dd/MM/yyyy'),
      expiryDate: this.datePipe.transform(this.formgroup.value.template.expiryDate, 'dd/MM/yyyy'),
      variableKeyDtoList: this.formgroup.value.variableDetails
    }
    this.formgroup.value.template.templateId = this.templetId;
  }

  submit() {
    console.log(this.formgroup.value)
    this.modalRef.hide();
    this.router.navigate(['/hr/appointment-list']);
  }
  closeFirstModal() {
    this.modalRef.hide();
  }

  templtetList(companyId: any) {
    const status = 'active';
    if (companyId && companyId != "") {
      this.hrService.templetList(companyId, status).subscribe(res => {
        if (res.status === 'Success') {
          this.templets = [];
          for(let data of res.respObject){
            if (data.isWorkflowSet) {
              this.templets.push(data);
            }
          }
        }
      })
    }
  }

  async cityList(stateId: any) {
    if (stateId && stateId != "") {
      // this.hrService.cityList(this.countryId, stateId).subscribe(res => {
      //   this.cities = res.respObject;
      // })
      const response = await this.hrService.cityList(this.countryId, stateId).toPromise().catch(
        error =>{
          console.log(error?.error ? error.error : error)
        }
      );
      if (response.status === 'Success') {
        this.cities = response.respObject;
      }
    }
  }

  stateList(countryId: any) {
    this.hrService.stateList(countryId).subscribe(res => {
      this.states = res.respObject;
    })
  }

  countryList(countryId: any) {
    this.hrService.countryList(countryId).subscribe(res => {
      this.countries = res.respObject;
      this.formgroup.value.address.country = res.respObject.name;
      this.countryId = res.respObject.id;
    })
  }

  selecTemplet(templetEvent: any) {
    if (this.formgroup.value.template.companyId == '') {
      this.notify.showInfo('Please select Company!', 'Info')
    }
    this.templets.forEach((value: { templateName: any; templateId: any; }) => {
      if (value.templateName?.trim() == templetEvent.target.value?.trim()) {
        this.templetId = value.templateId;
        this.formgroup.value.template.templateId = value.templateId;
        this.getCandidateVariables();
      }
    });
  }

  selectCity(cityEvent: any) {
    this.cities.forEach((value: { name: any; id: any; }) => {
      if (value.name == cityEvent.target.value) {
        this.cityId = value.id;
        this.formgroup.value.address.cityId = value.id;
      }
    });
  }

  selectState(stateEvent: any) { ///
    // this.states.forEach((value: { name: any; id: any; }) => {
    //   if (value.name == stateEvent.target.value) {
    //     // this.stateId = value.id;
    //     this.formgroup.value.address.stateId = value.id;
    //   }
    // });
    this.formgroup.value.address.stateId = this.states.find((state: { name: any; }) => state.name === stateEvent.target.value).id;
    this.cityList(this.formgroup.value.address.stateId);
  }

  selectCountry(countryEvent: any) {
    this.countries.forEach((value: { name: any; id: any; }) => {
      if (value.name == countryEvent.target.value) {
        this.countryId = value.id;
        this.formgroup.value.address.countryId = value.id;
      }
    });
  }
  
  selectCompany() {
    this.templtetList(this.formgroup.value.template.companyId);
  }

  get f() {
    return this.formgroup.controls;
  }

  get addrsForm() {
    return this.formgroup.controls.address.controls;
  }

  get tempForm() {
    return this.formgroup.controls.template.controls;
  }

  get variables(): FormArray {
    return this.formgroup.get('variableDetails') as FormArray;
  }

  cancel(){
    this.router.navigate(['/hr/appointment-list']);
  }

}

interface CandidateRequest {
  "candidateDto": Candidate,
  "templateDto": Templet,
  "expiryDate": any,
  "triggerDate": any,
  "companyId": number,
  "templateCandidateId": number,
  "variableKeyDtoList": any
}

interface Templet {
  "companyId": number
  "templateId": number,
  "templateName": "string",
}

interface Address {
  "id": any,
  "pincode": number,
  "city": "string",
  "cityId": number,
  "country": "string",
  "countryId": number,
  "hno": "string",
  "landmark": "string",
  "stateId": number,
  "stateOfCountry": "string",
  "street": "string"
  // "latitude": number,
  // "longitude": number,
  "location": "",
  "localArea": "",
}

interface Candidate {
  "contactNumber": "string",
  "emailId": "string",
  "firstName": "string",
  "id": any,
  "lastName": "string",
  "aadhaarName": "string"
  "addressDto": Address
}
