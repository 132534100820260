<div class="wrap_body">
  <h4 class="title_m1">Company list</h4>
  <div class="row">
    <div class="col-sm-3 offset-sm-7">
      <div class="search_m1 mb-3">
        <input type="text" class="form-control" [(ngModel)]="term" placeholder="Search" (keyup)="pageConfig.currentPage = 1">
        <img src="../../../assets/images/Search.png" alt="">
      </div>
    </div>
    <div class="col-sm-2 text-right" *ngIf="checkAccess('company')">
        <a routerLink="/hradmin/create-company" routerLinkActive="active" class="btn_m1 text-white" >
          <span>Create Company</span>
        </a>
    </div>
    <div class="col-sm-12">
      <div class="table-responsive">
        <table class="table  table-striped table_m1">
          <thead>
            <tr>
              <th>S. No</th>
              <th>Company Name</th>
              <th scope="col">Address</th>
              <th scope="col">GST No</th>
              <th scope="col">Email Id</th>
              <th>Contact number</th>
              <th>Company Representative</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let company of companyDetail | filter:term | paginate: { itemsPerPage: recordCount, currentPage: pageConfig.currentPage } let i = index">
              <td>{{(pageConfig.currentPage - 1) * recordCount + ((companyDetail | filter:term )?.length > 0 ? 1 : 0) + i}}
              <td>{{company.companyName}}</td>
              <td>{{getAddress(company?.address)}}</td>
              <td>{{company.gst}}</td>
              <td>{{company.emailId}}</td>
              <td>{{company.contactNumber}}</td>
              <td>{{company.firstName}} {{company.lastName}}</td>
              <td class="text-nowrap">
                <a class="link-primary" routerLink="/hradmin/create-company/{{company?.companyId}}" ><i class="fas fa-edit"></i></a>
                <label class="switch ml-3">
                  <input type="checkbox" [checked]="company?.recordState === 'ACTIVE' ? true : false" (change)="changeStatus($event, company.companyId)" >
                  <span class="slider round"></span>
                </label>
              </td>
            </tr>
            <tr *ngIf="companyDetail?.length === 0">
              <td colspan="7" class="text-center" style="min-width: 600px;">No data found</td> 
            </tr>
          </tbody>
        </table>
        <div *ngIf="companyDetail?.length > 0">
          <div class="row">
            <div class="col-sm-6 text-start">
              <p>Showing {{(pageConfig.currentPage - 1) * recordCount + ((companyDetail | filter:term )?.length > 0 ? 1 :
                0)}} -
                {{(((pageConfig.currentPage - 1) * recordCount + recordCount) > (companyDetail | filter:term )?.length)
                ? ((companyDetail | filter:term )?.length) : (pageConfig.currentPage - 1) * recordCount + recordCount}}
                of {{(companyDetail | filter:term )?.length}} records
              </p>
            </div>
            <div class="col-sm-6 text-end">
              <pagination-controls (pageChange)="pageConfig.currentPage = $event"></pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Approve Candidate 1</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group row">
          <label for="" class="col-sm-2 text-left col-form-label">Adress</label>
          <div class="col-sm-10">
            <textarea name="" id="" cols="30" rows="2" class="form-control"></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="text-right">
      <button class="btn btn_m8 mr-3">Cancel</button>
      <button class="btn btn_m5">Submit</button>
    </div>
  </div>
</ng-template>

<ng-template #template1>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Appointment Letter</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="offer_letter.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12">
        <div class="letter_m1">
          <table>
            <tbody>
              <tr>
                <td>First Name</td>
                <td>John</td>
              </tr>
              <tr>
                <td>Last Name</td>
                <td>Smith</td>
              </tr>
              <tr>
                <td>Email Id</td>
                <td>John.s@gmail.com</td>
              </tr>
              <tr>
                <td>Contact number</td>
                <td>99090902</td>
              </tr>
            </tbody>
          </table>
          <p class="mt-4 mb-4">It is a long established fact that a reader will be distracted by the readable content of
            a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal
            distribution of letters, as opposed to using 'Content here, content here', making it look like readable
            English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model
            text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. </p>
          <p>Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour
            and the like).</p>
        </div>
      </div>
    </div>
    <div class="text-right mt-4 mb-2">
      <button class="btn btn_un1 btn_m6 text-uppercase">reject</button>
      <button class="btn btn_un1 btn_m7 text-uppercase ml-3">approve</button>
    </div>
  </div>
</ng-template>