<div class="login_set">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6 bg_m1">
                <div class="login_left_set"></div>
            </div>
            <div class="col-sm-6">
                <div class="login_right_set">
                    <p class="text-right">Do you have an account? <a routerLink="/signin">Sign In</a></p>
                    <div class="row">
                   <div class="form col-sm-8 offset-sm-2">
                    <h3 class="mt-5 mb-4">Sign Up</h3>
                    <div class="input_m1 mb-5">
                        <input type="text" class="form-control" placeholder="User name">
                    </div>
                    <div class="input_m1 mb-4">
                        <input type="text" class="form-control" placeholder="Password">
                    </div>
                    <div class="mb-2">
                        <a href="#!" class="link_m1">Forgot password?</a>
                    </div>
                    <div>
                        <button class="btn btn-block text-white mt-4 btn_m1">SIGN UP</button>
                    </div>
                    <div class="form-check form-check-inline mt-4 check_m1">
                        <input class="form-check-input" type="checkbox" id="remember_me" value="option1">
                        <label class="form-check-label" for="remember_me">Remember me</label>
                      </div>
                      <p class="text-center mt-4 mb-4 c_1">Or Login in with</p>
                      <button class="btn btn-block btn_m2">Google</button>
                </div>
                   </div>
                </div>
            </div>
        </div>
    </div>
</div>