import { FormStyle } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
const sharedRoutes: Routes = [
   { path: '', component: LoginComponent },
  { path: 'signin', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: '**', component: LoginComponent },
];

 

@NgModule({
  imports: [RouterModule.forChild(sharedRoutes),
    FormsModule, ReactiveFormsModule],
  exports: [RouterModule]
})


export class SharedRoutingModule { }
