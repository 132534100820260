import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { PagesRoutingModule } from './pages-routing.module';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './header/header.component';
import { Routes } from '@angular/router';
import { BulkAppointmentsComponent } from './bulk-appointments/bulk-appointments.component';
import { AddCandidateDetailsComponent } from './add-candidate-details/add-candidate-details.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AppointmentListComponent } from './appointment-list/appointment-list.component';
import { CreateTemplateComponent } from './create-template/create-template.component';
import { AngularFileUploaderModule } from "angular-file-uploader";
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { HradminModule } from '../hradmin/hradmin.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgxPaginationModule } from 'ngx-pagination';


@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    BulkAppointmentsComponent,
    AddCandidateDetailsComponent,
    AppointmentListComponent,
    CreateTemplateComponent
   
  ],
  imports: [
    CommonModule,
    PagesRoutingModule,
    ModalModule.forRoot(),
    AngularFileUploaderModule,
    BsDropdownModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    PdfViewerModule,
    Ng2SearchPipeModule,
    ClickOutsideModule,
    NgxPaginationModule,
  ],
  exports:[HeaderComponent],
  providers: [DatePipe],
})
export class PagesModule {
  static forRoot(routes: Routes): any[] | import("@angular/core").Type<any> | import("@angular/core").ModuleWithProviders<{}> {
    throw new Error('Method not implemented.');
  }
}
