<div class="wrap_body">
   <h4 class="title_m1">Upload Documents</h4>
   <div class="tab_m1">
      <tabset #staticTabs>
      
         <tab heading="">
            <ng-template tabHeading>
               <span class="label_count">1</span>
               <p>Documents</p>
            </ng-template>
            <div *ngIf="upload === 'true'">
               <div class="container">
                  <label for="" class="text-bold font-italic">Note: Only Jpg, Jpeg, PNG and PDF formats are allowed to upload.</label>
                  <div class="row">
                     <div class="col-sm-3">
                        <label for="" class=" col-form-label">Documents Type</label>
                        <select name="" id="" class="form-control"  (change)="documentsType($event)">
                           <option value="">Select</option>
                           <option value="Educational Details">Educational Details</option>
                           <option value="Professional Details">Professional Details</option>
                        </select>
                     </div>
                  </div>
               </div>
               <form class="container" [formGroup]="documents" (submit)="documentDetails(1)">
                  <div class="row">
                     <div class="col-sm-12 mt-3 mb-3">
                        <h6>Details</h6>
                     </div>
                     <div formArrayName="uploadCategotyDetails">
                        <div *ngFor="let formFeature of documents.get('uploadCategotyDetails')['controls']; let i = index;"
                           class="form-group" formGroupName="{{i}}">
                           <div *ngIf="formFeature.value.uploadCategory === docType">
                              <div class="row">
                                 <div class="col-sm-3">
                                    <div class="form-group row">
                                       <label for="" class="col-sm-12  col-form-label">Documents Type</label>
                                       <div class="col-sm-12">
                                          <input type="text" class="form-control" id="" formControlName="uploadCategory">
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-sm-5">
                                    <div class="form-group row">
                                       <label for="" class="col-sm-12  col-form-label">Description</label>
                                       <div class="col-sm-12">
                                          <input type="text" class="form-control" id="" formControlName="description">
                                       </div>
                                    </div>
                                 </div>
            
                                 <div class="col-sm-4">
                                    <div class="form-group">
                                       <div formArrayName="fileNameList" class="d-inline-block align-top">
                                          <div *ngFor="let file of formFeature.get('fileNameList')['controls']; let j = index;"
                                             class="" formGroupName="{{j}}">
                                             <div class="">
                                                <label for="" class="col-sm-12  col-form-label">Upload File</label>
                                                <div class="file_m1 file_drag_m1 drg_sup1">
                                                   <angular-file-uploader [config]="afuConfig"
                                                      (fileSelected)="fileUpload($event, i)" (ApiResponse)="docUpload($event)">
                                                   </angular-file-uploader>
                                                </div>
                                                <label for="" class="ml-3 img-label">{{file.value.file}}</label>
                                             </div>
                                          </div>
                                       </div>
                                       <button class="btn btn_m3 ms-3" style="margin-top: 30px;" type="button"
                                          (click)="removeForm(i)">Remove</button>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="">
                           <button class="btn btn_m4" type="button" (click)="addForm()">ADD</button>
                        </div>
                     </div>
            
                  </div>
            
                  <div class="row mt-4">
                     <div class="col-6 mt-3"><button type="button" class="btn btn_m3" (click)="backPopup(template)">Back</button></div>
                     <div class="col-6 text-right mt-3"><button class="btn btn_m4" type="submit">Save</button></div>
                  </div>
               </form>
            </div>
            <div *ngIf="upload === 'false'">
               <div class="row mt-4">
                  <div class="col-6 mt-3"><button class="btn btn_m3" (click)="backPopup(template)">Back</button></div>
                  <div class="col-6 text-right mt-3"><button class="btn btn_m4" (click)="continueToEsign()">Next</button></div>
               </div>
            </div>
         </tab>
         
         <tab heading="">
            <ng-template tabHeading>
               <span class="label_count">2</span>
               <p>E-Sign</p>
            </ng-template>
            <div *ngIf="!otpModelFlag">
               <h3 class="text-center mt-5 pt-5 mb-4">Please verify with e-sign</h3>
               <div class="text-center mt-5 pt-5 mb-4" *ngIf="aadhaarSign === 'false'">
                  <label for="" class="text-bold font-italic my-2">Note: Only Jpg, Jpeg and PNG formats are allowed to upload.</label>
                  <div class="file_m1 file_drag_m1">
                     <angular-file-uploader  [config]="afuConfig" (fileSelected)="fileSelected($event, 'ESIGN')"
                        (ApiResponse)="docUpload($event)"
                        >
                     </angular-file-uploader>
                  </div>
               </div>
               <div class="row">
                  <div class="col-6 mt-3"><button class="btn btn_m3" (click)="backToUpload()">Back</button></div>
                  <div class="col-6 text-right mt-3"><button class="btn btn_m4" type="button"
                        (click)="esignUpload()">Submit</button></div>
               </div>
            </div>
            <div *ngIf="otpModelFlag">
               <div class="text-center text-success my-5" *ngIf="otpVarMsgFlag">
                  <h2>{{otpVarificationMsg}}</h2>
               </div>
               <div *ngIf="!otpVarMsgFlag">
                  <h4 class="text-center my-3">OTP Verification</h4>
                  <div class="row">
                     <div class="col-sm-12">
                        <div class="form-group row">
                           <!-- <label for="" class="col-sm-12 text-center col-form-label">OTP</label> -->
                           <div class="col-sm-5"></div>
                           <div class="col-sm-2 text-center">
                              <input type="text" name="" id="" placeholder="Enter OTP" [(ngModel)]="otp" class="form-control">
                           </div>
                           <div class="col-sm-5"></div>
                        </div>
                     </div>
                  </div>

                  <div class="text-center">
                     <button class="btn btn_un1 btn_m6 text-uppercase" (click)="otpVarificationCancel()">Cancel</button>
                     <button class="btn btn_un1 btn_m7 text-uppercase ml-3" (click)="verifyOtp()">Verify</button>
                  </div>
               </div>
            </div>
         </tab>
      </tabset>
   </div>
</div>

<ng-template #template>
   <div class="modal-header">
     <h4 class="modal-title pull-right text-center">Are you sure you want to go back! you need to reupload your file?</h4>
     <button type="button" class="close pull-right mb-2" aria-label="Close" (click)="modalRef.hide()">
       <span aria-hidden="true">&times;</span>
     </button>
   </div>
   <div class="modal-body">
     <div class="text-center">
       <button class="btn btn_un1 btn_m6 text-uppercase" (click)="modalRef.hide()">Cancel</button>
       <button class="btn btn_un1 btn_m7 text-uppercase ml-3" (click)="backToOffer()">Back</button>
     </div>
   </div>
 </ng-template>