<div class="wrap_body">
  <h4 class="title_m1">Category</h4>
  <div class="row">
    <div class="col-sm-3">
      <select name="" id="" (change)="selectCompany($event)" [(ngModel)]="companyId" class="form-control">
        <option value="">Select Company</option>
        <option value="{{data.companyId}}" *ngFor="let data of companyList">{{data.companyName}}</option>
      </select>
    </div>
    <div class="col-sm-3 text-right offset-sm-4">
      <div class="search_m1">
        <input type="text" class="form-control" [(ngModel)]="term" placeholder="Search">
        <img src="../../../assets/images/Search.png" alt="">
      </div>
    </div>
    <div class="col-sm-2 text-right" *ngIf="checkAccess('category')">
      <a routerLink="/hradmin/create-category" routerLinkActive="active" class="btn_m1 text-white">
        <span>Create Category</span>
      </a>
    </div>
  </div>
  <div class=" col-sm-6 mt-5">
    <div class="table_m3">
      <table>
        <thead>
          <!-- <tr>
            <th>
              <div class="search_m1">
                <input type="text" class="form-control" [(ngModel)]="term" placeholder="Search" style="height: 33px;">
                <img src="../../../assets/images/Search.png" alt="">
              </div>
            </th>
            <th class="text-right">
                <button class="btn"><i class="fas fa-edit"></i></button>
                <button class="btn"><i class="fas fa-trash"></i></button>
              </th>
          </tr> -->
          <tr>
            <th>S.No.</th>
            <th>Category</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody class="text-center">
          <tr *ngFor="let data of category | filter:term; let indexOfelement=index;">
            <td>{{indexOfelement+1}}</td>
            <td>{{data.name}}</td>
            <td>
              <button class="btn" routerLink="/hradmin/create-category/{{data.id}}"><i class="fas fa-edit"></i></button>
              <!-- <button class="btn" (click)="deleteCategory(data.id)"><i class="fas fa-trash"></i></button> -->
              <label class="switch ml-3">
                <input type="checkbox" [checked]="data?.recordState === 'ACTIVE' ? true : false"
                  (change)="changeStatus($event, data.id)">
                <span class="slider round"></span>
              </label>
            </td>
          </tr>
          <tr *ngIf="category?.length === 0">
            <td colspan="3" class="text-center" style="min-width: 600px;">No data found</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>