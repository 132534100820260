import { Component, OnInit } from '@angular/core';
import {  TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HrService } from 'src/app/pages/services/hr.service';

@Component({
  selector: 'app-appointment-list',
  templateUrl: './appointment-list.component.html',
  styleUrls: ['./appointment-list.component.css']
})
export class AppointmentListComponent implements OnInit {

  
  modalRef!: BsModalRef;
  offer_letter!: BsModalRef;
  reject_Ref!:BsModalRef;
  public candidatesList:any=[];
  public templateDta:any;
  constructor(private modalService: BsModalService,private hrService:HrService) { }

  ngOnInit(): void {
    this.appointMentList();
  }
  appointMentList(){
    this.hrService.candidatesList().subscribe(
      res=>{
        console.log(res.candidateTemplateList);
        this.candidatesList=res.candidateTemplateList;
      }
    )
   }
  
  openModal(template: any,data:any) {
    this.templateDta=data;
    const initialState = {
      
 };
   // this.modalRef = this.modalService.show(template);
    this.modalRef = this.modalService.show(template,
      {
        class: 'modal-dialog-centered model_1', initialState ,
        
      },
      
 );
  };

  offer_letter_link(template1: TemplateRef<any>) {
    const initialState = {
      
 };
   
    this.offer_letter = this.modalService.show(template1,
      {
        class: 'modal-dialog-centered model_1 modal-lg', initialState ,
        
      },
      
 );
  }
  reject_model(template1: TemplateRef<any>) {
    const initialState = {
      
 };
   // this.modalRef = this.modalService.show(template);
    this.reject_Ref = this.modalService.show(template1,
      {
        class: 'modal-dialog-centered model_1 modal-lg', initialState ,
        
      },
 );
  }
  submit(template:any){
    this.modalRef.hide();
    template.status="Approved";
    this.candidatesList[0].push(template);
  }
}



 
 
 

