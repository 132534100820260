import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PagesModule } from './pages/pages.module';
import { PagesRoutingModule } from './pages/pages-routing.module';
import { CandidateModule } from './candidate/candidate.module';
import { UpdatePasswordComponent } from './shared/update-password/update-password.component';
import { AuthGuard } from './shared/auth/auth.guard';
import { EsignComponent } from './esign/esign.component';
import { ForgotPasswordComponent } from './shared/forgot-password/forgot-password.component';
const routes: Routes =
  [
    { path: 'hradmin', loadChildren: () => import('./hradmin/hradmin.module').then(m => m.HradminModule), canActivate: [AuthGuard] },
    { path: 'superuser', loadChildren: () => import('./superuser/superuser.module').then(m => m.SuperuserModule), canActivate: [AuthGuard] },
    { path: 'subscription', loadChildren: () => import('./subscription/subscription.module').then(m => m.SubscriptionModule), canActivate: [AuthGuard] },
    { path: 'updatePwd/:username', component: UpdatePasswordComponent, canActivate: [AuthGuard] },
    {path:'dashboard',loadChildren: () => import('./dashboard/dashboard.module').then(m=>m.DashboardModule),canActivate: [AuthGuard]},
    {path:'esign', component: EsignComponent},
    {path:'reports',loadChildren: () => import('./reports/reports.module').then(m=>m.ReportsModule),canActivate: [AuthGuard]},
    {path:'forgotPassword', component: ForgotPasswordComponent},
    // { path: 'dashboard/:username', component: DashboardComponent, canActivate: [AuthGuard] }

    // { path: 'user',  loadChildren: () => import('./pages/pages.module')},
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes), PagesModule, PagesRoutingModule, CandidateModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
