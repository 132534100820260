import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HradminRoutingModule } from './hradmin-routing.module';
 
import { HradminComponent } from './hradmin.component';
import { AppointmentListComponent } from './appointment-list/appointment-list.component';
import { VariableMappingComponent } from './variable-mapping/variable-mapping.component';
import { CreateTemplateComponent } from './create-template/create-template.component';
import { AngularFileUploaderModule } from "angular-file-uploader";
import { TemplateComponent } from './template/template.component';
import { CreateCompanyComponent } from './create-company/create-company.component';
import { CompanyListComponent } from './company-list/company-list.component';
import { AddCandidateDetailsComponent } from './add-candidate-details/add-candidate-details.component';
import { BulkAppointmentsComponent } from './bulk-appointments/bulk-appointments.component';
import { WorkflowComponent } from './workflow/workflow.component';
import { PagesModule } from '../pages/pages.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateCategoryComponent } from './create-category/create-category.component';
import { WorkflowlistComponent } from './workflowlist/workflowlist.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { CategoryListComponent } from './category-list/category-list.component';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  declarations: [
    HradminComponent,
    AppointmentListComponent,
    VariableMappingComponent,
    CreateTemplateComponent,
    TemplateComponent,
    CreateCompanyComponent,
    CompanyListComponent,
    AddCandidateDetailsComponent,
    BulkAppointmentsComponent,
    WorkflowComponent,
    CreateCategoryComponent,
    WorkflowlistComponent,
    CategoryListComponent,
  ],
  imports: [
    CommonModule,
    HradminRoutingModule,
    AngularFileUploaderModule,
    PagesModule,
    FormsModule,
    ReactiveFormsModule,
    PdfViewerModule,
    NgMultiSelectDropDownModule.forRoot(),
    Ng2SearchPipeModule,
    NgxPaginationModule
  ],
  exports:[CreateCompanyComponent]
})
export class HradminModule { }
