<div class="wrap_body">
    <h4 class="title_m1">Reports</h4>
    <div class="row">
        <form class="row" [formGroup]="formgroup" (submit)="submitData()">
            <div class="col-sm-12">
                <div class="row mb-3">
                    <div class="col-sm-2">
                        <label for="" class="m-0">Company</label>
                        <select name="" id="" class="form-control" formControlName="companyId">
                            <option value="">Select</option>
                            <option value="{{data.companyId}}" *ngFor="let data of companyList">{{data.companyName}}</option>
                        </select>
                    </div>
                    <div class="col-sm-2">
                        <label for="" class="m-0">Report</label>
                        <select name="" id="" class="form-control" formControlName="name">
                            <option value="">Select</option>
                            <option value="{{data.key}}" *ngFor="let data of reports" >{{data.name}}</option>
                        </select>
                    </div>
                    <div class="col-sm-2">
                        <label for="" class="m-0">Select Duration</label>
                        <select name="" id="" class="form-control" (change)="selectDate()" value="Last week" formControlName="period">
                            <option value="">Select</option>
                            <option value="Last week">Last week</option>
                            <option value="Last 3 months">Last 3 months</option>
                            <option value="Last 6 months">Last 6 months</option>
                        </select>
                    </div>
                    <div class="col-sm-2">
                        <label for="" class="m-0">Select Date from</label>
                        <input type="date" class="form-control" formControlName="fromDate">
                    </div>
                    <div class="col-sm-2">
                        <label for="" class="m-0">Select Date to</label>
                        <input type="date" class="form-control" formControlName="toDate">
                    </div>
                    <div class="col-sm-2 mb-2">
                        <button class="btn btn_m1 text-white p-1 px-3 mt-4 rounded-0" type="submit">Report</button>
                    </div>
                </div>
            </div>
          
        </form>

<!-- Table Model 2 Start Here -->
<div class="table_m5">
    <div class="row">
        <span class="col-sm-1">
            <select class="form-control" (change)="pageCount($event)">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
        </span>

        <div class="col-sm-1 text-right pr-0 offset-sm-7">
            <a (click)="export()" class="">
                <img src="../../../assets/images/excel.png" alt="" style="width: 40px;">
            </a>
        </div>
        <div class="col-sm-3">

            <div class="search_m1 mb-3">
                <input type="text" class="form-control" [(ngModel)]="term" placeholder="Search"
                    (keyup)="pageConfig.currentPage = 1">
                <img src="../../../assets/images/Search.png" alt="">
            </div>
        </div>
    </div>
<div class="table-responsive text-nowrap">
    <table
        class="table table-striped table_m1 table_sort" id="excel-table">
        <thead>
            <tr>
                <th *ngIf="serviceReportData?.length > 0">S. No</th>
                <th *ngFor="let header of headers" (click)="sort(serviceReportData, getValue(header.value), sorting)">
                    {{header.value}} 
                    <i class="fa fa-fw fa-sort"></i>
                </th>
            </tr>
        </thead>
        <tbody class="text-center">
            <tr *ngFor="let group of serviceReportData | filter:term | paginate: { itemsPerPage: recordCount, currentPage: pageConfig.currentPage } let i = index">
                <td>{{(pageConfig.currentPage - 1) * recordCount + ((serviceReportData | filter:term )?.length > 0 ? 1 : 0) + i}}</td>
                <td *ngFor="let header of headers">
                    {{getKey(group, header.key)}}
                </td>
            </tr>
        </tbody>
        <!-- <tfoot *ngIf="recordCount > 10">
            <tr>
                <th *ngFor="let header of headers" (click)="sort(serviceReportData, getValue(header.value), sorting)">
                    {{header.value}} 
                    <i class="fa fa-fw fa-sort"></i>
                </th>
            </tr>
        </tfoot> -->
    </table>
</div>
<div *ngIf="serviceReportData?.length > 0">
    <div class="row">
        <div class="col-sm-6 text-start">
            <p>Showing {{(pageConfig.currentPage - 1) * recordCount + ((serviceReportData | filter:term )?.length > 0 ? 1 : 0)}} - 
                {{(((pageConfig.currentPage - 1) * recordCount + recordCount) > (serviceReportData | filter:term )?.length) 
                    ? ((serviceReportData | filter:term )?.length) : (pageConfig.currentPage - 1) * recordCount + recordCount}} 
                of {{(serviceReportData | filter:term )?.length}} records
            </p>
        </div>     
        <div class="col-sm-6 text-end">
            <pagination-controls (pageChange)="pageConfig.currentPage = $event"></pagination-controls>
        </div>
    </div>
</div>
 
<!-- Table Model 2 End Here -->


        <!-- <div class="table_m5">
            <table datatable [dtOptions]="dtOptions"
                class="table table-striped table-bordered table-sm row-border hover" id="datatableexample">
                <thead>
                    <tr>
                        <th *ngFor="let header of headers">
                            {{header.value}}
                          
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let group of serviceReportData">
                        <td *ngFor="let header of headers">
                            {{getKey(group, header.key)}}
                        </td>
                        
                    </tr>
                </tbody>
            </table>
        </div> -->
    </div>
