<div class="wrap_body">
  <h4 class="title_m1">Create Template</h4>

  <form class="row" [formGroup]="formgroup" (submit)="addTemplate()">
    <div class="col-sm-6">
      <div class="row">

        <div class="col-sm-12">
          <div class="form-group row">
            <label for="" class="col-sm-3 text-right col-form-label">Company Name <span class="c_3">&ast;</span></label>
            <div class="col-sm-8">
              <select name="" id="" class="form-control" formControlName="companyId" (change)="companyEvent($event)"
              [ngClass]="{ 'is-invalid': submitted && f.companyId.errors }">
                <option value="">Select</option>
                <option value="{{data.companyId}}" *ngFor="let data of companyList">{{data.companyName}}</option>
              </select>
              <div *ngIf="submitted && f.companyId.errors" class="invalid-feedback">
                <div *ngIf="f.companyId.errors.required">Field is required</div>
                <!-- <div *ngIf="f.companyId.errors.pattern">Invallid UserName</div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group row">
            <label for="" class="col-sm-3 text-right col-form-label">Category <span class="c_3">&ast;</span></label>
            <div class="col-sm-8">
              <select name="" id="" class="form-control" formControlName="companyCategoryId"
                [ngClass]="{ 'is-invalid': submitted && f.companyCategoryId.errors }">
                <option value="">Select</option>
                <option value="{{data.id}}" *ngFor="let data of categorys">{{data.name}}</option>
              </select>
              <div *ngIf="submitted && f.companyCategoryId.errors" class="invalid-feedback">
                <div *ngIf="f.companyCategoryId.errors.required">Field is required</div>
                <!-- <div *ngIf="f.companyCategoryId.errors.pattern">Invallid UserName</div> -->
              </div>
            </div>
          </div>
        </div>

        <!-- change -->
        <div class="col-sm-12">
          <div class="form-group row">
            <label for="" class="col-sm-3 text-right col-form-label">Master Template <span class="c_3">&ast;</span></label>
            <div class="col-sm-8">
              <div class="custom_auto"  (clickOutside)="onClickedOutside($event)"  >
              <input type="text" class="form-control" formControlName="templateName" placeholder="Search" (focus)="showSuggestions()"    [ngClass]="{ 'is-invalid': submitted && f.templateName.errors }" [(ngModel)]="term"/>
              <ul *ngIf="showSuggestionList" class="list_m5">
                <li *ngFor="let suggestion of suggestionList | filter:term" (click)="selectSuggestion(suggestion)">{{ suggestion.templateName }}  |   {{suggestion.industry}}
                  <a (click)="template_link(template,suggestion)" class="text-decoration-none font-italic text-end" style="font-size: small;">Show Template</a>
                </li>
              </ul>
              <div *ngIf="submitted && f.templateName.errors" class="invalid-feedback">
                <div *ngIf="f.templateName.errors.required">Field is required</div>
                <div *ngIf="f.templateName.errors.pattern">Invallid Input</div>
              </div>
            </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group row">
            <label for="" class="col-sm-3 text-right col-form-label">Template Name <span class="c_3">&ast;</span></label>
            <div class="col-sm-8">
              <input type="text" class="form-control" id="" placeholder="Template Name" formControlName="templateName"
                [ngClass]="{ 'is-invalid': submitted && f.templateName.errors }">
              <div *ngIf="submitted && f.templateName.errors" class="invalid-feedback">
                <div *ngIf="f.templateName.errors.required">Field is required</div>
                <div *ngIf="f.templateName.errors.pattern">Invallid Input</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <p class="fw-bold col-sm-6 text-right">Please upload word file only.</p>
          <div class="form-group row">
            <label for="" class="col-sm-3 text-right col-form-label">Document <span class="c_3">&ast;</span></label>
            <div class="col-sm-8">
              <div class="file_m1">
                <input type="file" (change)="fileSelected($event)" name="file" formControlName="file">
              </div>
              <span *ngIf="uploadResponseFlag" class="colour">{{afuConfig.replaceTexts.afterUploadMsg_success}}</span>
            </div>
          </div>
        </div>
        <div class="col-sm-12 form-group row">
          <div *ngIf="services?.adharSign" class="col-sm-5 text-right">
            <label>
              Aadhaar Signature
              <input type="radio" class="ml-2" formControlName="isAadhaarSign" [(ngModel)]="aadhaarFlag" [value]=true>
            </label>
          </div>
          <div *ngIf="services?.imageSign" class="col-sm-5 text-right">
            <label>
              Image Signature
              <input type="radio" class="ml-2" formControlName="isAadhaarSign" [(ngModel)]="aadhaarFlag" [value]=false>
            </label>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group row">
            <label for="" class="col-sm-4 text-right col-form-label">Documents Required <span class="c_3">&ast;</span></label>
            <div class="col-sm-7">
              <input type="checkbox" formControlName="isUpload">
              <!-- <label class="switch">
                <span class="slider round"></span>
              </label> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="table_m3" *ngIf="uploadResponseFlag">
        <table>
          <thead>
            <tr>
              <th>Variables</th>
              <!-- <th>Value</th> -->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let user of variblesData;let i=index ">
              <td>{{user}}</td>
              <!-- <td>
                <select name="" id="" class="sel_m2" (change)="changeValues($event,user)">
                  <option value="">Select</option>
                  <option *ngFor="let data of templateNames" value="{{data.name}}">{{data.name}}</option>
                </select>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
<div class="text-right">
  <div class="row">
    <div class="col-sm-6">
      <div class="col-sm-12 text-right mt-3">
        <button class="btn btn_m4 mr-2" type="submit">save</button>
        <button class="btn btn_m3" routerLink="/hradmin/template">Cancel</button>
      </div>
    </div>
  </div>
    </div>
    <div class="col-6 text-right mt-3"></div>
  </form>
</div>

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{templateName}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="template_popup.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12">
        <pdf-viewer [src]="fileUrl" [render-text]="true" [original-size]="false"
          style="width: 100%; height: 600px"></pdf-viewer>
      </div>
    </div>
  </div>
</ng-template>