<div class="wrap_body">
  <h4 class="title_m1">Template</h4>
  <div class="row">
    <div class="col-sm-3">
      <div class="form-group">
        <div class="">
          <select name="" id="" (change)="selectCompany($event)" [(ngModel)]="companyId" class="form-control">
            <option value="">Select Company</option>
            <option value="{{data.companyId}}" *ngFor="let data of companyList">{{data.companyName}}</option>
          </select>
        </div>
      </div>
    </div>
   
    <div class="col-sm-3 offset-sm-4 text-right">
      <div class="search_m1 mb-3">
        <input type="text" class="form-control" [(ngModel)]="term" placeholder="Search">
        <img src="../../../assets/images/Search.png" alt="">
      </div>
    </div>
    <div class="col-sm-2 text-right" *ngIf="checkAccess('template')">
      <a routerLink="/hr/create-template" routerLinkActive="active" class="btn_m1 text-white" >
        <span>Create Template</span>
      </a>
      </div>
    <div class="col-sm-12">
      <div class="table-responsive">
        <table class="table table-striped table_m1">
          <thead>
            <tr>
              <th scope="col">S. No. </th>
              <th scope="col">Company Name</th>
              <th>Category Name</th>
              <th scope="col">Template</th>
              <th>Signature</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody class="text-center">
            <tr *ngFor="let data of temlateData | filter:term; let index=index;">
              <td>{{index+1}}</td>
              <td>{{data.companyName}}</td>
              <td>{{data.categoryName}}</td>
              <td><a href="#!" class="link-primary"></a> <button class="btn btn-link" type="button"
                  (click)="offer_letter_link(template3,data)">{{data.templateName}}</button>
                  <a (click)="downloadFile(data.templateId)" class="menu_icon" type="button">
                    <img src="../../../assets/images/download.png" alt="">
                  </a>  
              </td>

              <td>{{signatureDetails(data.aadhaarSign)}}</td>
              <td *ngIf="data.status=='PENDING'">
                <button class="btn btn_un1 btn_m6 text-uppercase mr-3" value="REJECTED" id="myBtn"
                  (click)="reject_model(template1,data,$event)">Reject</button>
                <button class="btn btn_un1 btn_m7 text-uppercase" value="APPROVED" id="myBtn" type="button"
                  (click)="openModal(template,data,$event)">Approve</button>
              </td>
              <td *ngIf="data.status=='REJECTED'" class="c_3"><i class="fas fa-exclamation-triangle "></i>
                {{data.status}}</td>
              <td *ngIf="data.status=='APPROVED'" class="c_2 text-uppercase"><i class="fas fa-check "></i>
                {{data.status}}</td>
              <td><a class="link-primary" (click)="redirect(data.status, data.templateId)" ><i class="fas fa-edit"></i></a>
                <label class="switch ml-3">
                  <input type="checkbox" [checked]="data?.recordState === 'ACTIVE' ? true : false" (change)="changeStatus($event, data.templateId)" >
                  <span class="slider round"></span>
                </label>
              </td>
            </tr>
            <tr *ngIf="temlateData.length === 0"><td colspan="7" class="text-center">No data found</td></tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<form [formGroup]="formGroup">
  <ng-template #template>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Approve Appointment Letter</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group row">
            <label for="" class="col-sm-2 text-left col-form-label">Comments</label>
            <div class="col-sm-10">
              <textarea name="" id="" cols="30" rows="2" class="form-control" formControlName="remarks"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="text-right">
        <button class="btn btn_un1 btn_m6 text-uppercase" (click)="modalRef.hide()">Cancel</button>
        <button class="btn btn_un1 btn_m7 text-uppercase ml-3" (click)="submit()">Submit</button>
      </div>
    </div>
  </ng-template>
</form>

<form [formGroup]="formGroup">
  <ng-template #template1>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Reject Appointment Letter</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="reject_Ref.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group row">
            <label for="" class="col-sm-2 text-left col-form-label">Comments</label>
            <div class="col-sm-10">
              <textarea name="" id="" cols="30" rows="2" class="form-control" formControlName="remarks"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="text-right">
        <button class="btn btn_un1 btn_m6 text-uppercase" (click)="reject_Ref.hide()">Cancel</button>
        <button class="btn btn_un1 btn_m7 text-uppercase ml-3" (click)="submit()">Submit</button>
      </div>
    </div>
  </ng-template>
</form>

<ng-template #template3>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{templateName}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="offer_letter.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12">
        <pdf-viewer [src]="pdfSrc" [render-text]="true" [original-size]="false"
          style="width: 100%; height: 600px"></pdf-viewer>
      </div>
    </div>
  </div>
</ng-template>