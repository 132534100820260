import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public hradminTag = false;
  public hrTag = false;
  public superuserTag = false;
  sessionData: any;
  menuList: any;
  userName: any;
  modalRef!: BsModalRef;
  constructor(private router: Router, private modalService: BsModalService) { 
    var data: any= sessionStorage.getItem('loginDetails');
    this.sessionData = JSON.parse(data);
    var menu: any = sessionStorage.getItem('menuList');
    this.menuList = JSON.parse(menu);
    this.userName = this.sessionData.loginId ? this.sessionData.loginId : 'User';
  }
  onHidden(): void {
    console.log('Dropdown is hidden');
  }
  onShown(): void {
    console.log('Dropdown is shown');
  }
  isOpenChange(): void {
    console.log('Dropdown state is changed');
  }
  ngOnInit(): void {
    // $('ul.sidebar-menu').tree();

    // if ("hradmin" == sessionStorage.getItem('hradmin')) {
    //   this.hradminTag = true;
    // } else if ("hr" == sessionStorage.getItem('hr')) {
    //   this.hrTag = true;
    // } else if ("superuser" == sessionStorage.getItem('superuser')) {
    //   this.superuserTag = true;
    // }

  }

  showProfile(){
    this.router.navigate(['/superuser/add-user/'+this.sessionData.userId])
  }

  logOut() {
    sessionStorage.clear();
    this.router.navigate(['/']);
    this.modalRef.hide();
  }

  home(){
    this.router.navigate(['/dashboard/dashboard'])
  }

  logOutPopUp(template: any){
    const initialState = {

    };
    this.modalRef = this.modalService.show(template,
      {
        class: 'modal-dialog-centered model_1', initialState,
      },
    );
  }

  openModal(template: any, data: any, event: any) {
    
    

  }

  checkAccess(menu: any){
    return this.menuList.some((item: {
      view: boolean; featureName: any; 
    }) => item.featureName.toLowerCase() === menu.toLowerCase() && item.view);
  }

  updatePwd(){
    this.router.navigate([`/updatePwd/${this.userName}`])
  }

}
