 

<div class="wrap_body">
    <h4 class="title_m1">Recipient List</h4>
    <div class="row">
        <div class="col-sm-3">
    <div class="search_m1 mb-3">
        <input type="text" class="form-control" placeholder="search">
        <img src="../../../assets/images/Search.png" alt="">
    </div>
</div>

<!-- <div class="col-sm-9 text-right">
     <button class="btn btn_un1 btn_m6 text-uppercase mr-3">reject</button>
     <button class="btn btn_un1 btn_m7 text-uppercase"  type="button"  (click)="openModal(template,data)">approve</button>
</div> -->

<div class="col-sm-12">
    <div class="table-responsive">
    <table class="table  table-striped table_m1">
        <thead>
          <tr>
            <th scope="col"> </th>
            <th>Name</th>
            <th scope="col">Email Id</th>
            <th scope="col">Approver</th>
            <th scope="col">Company</th>
            <th>Template</th>
            <th>Status</th>
            <th>Expiry date</th>
           </tr>
        </thead>
        <tbody>
        <tr *ngFor="let data of candidatesList">
          <td>
            <input type="checkbox">
          </td>
          <td>{{data.firstName}}</td>
          <td>{{data.emailId}}</td>
          <td>{{data.approver}}</td>
          <td>{{data.companyName}}</td>
          <td><a href="javascript:;"  class="link-primary" (click)="offer_letter_link(template3)">{{data.template.templateName}}</a></td>
          <td *ngIf="data.status=='Pending'">
          <button class="btn btn_un1 btn_m6 text-uppercase mr-3" (click)="reject_model(template2)">reject</button>
          <button class="btn btn_un1 btn_m7 text-uppercase" (click)="openModal(template,data)">approve</button>
        </td>
        <td *ngIf="data.status=='Approved'" class="c_2 text-uppercase"><i class="fas fa-check "></i> {{data.status}}</td>
        <td *ngIf="data.status=='Rejected'"class="c_3"><i class="fas fa-exclamation-triangle " ></i>  {{data.status}}</td>
      
          <td>{{data.template.expiryDate}}</td>
        </tr>
        </tbody>
      </table>
</div>
</div>
</div>
</div>
  
<ng-template #template>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Approve Candidate 1</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
     <!-- <p><i class="fas fa-check c_2"></i> Successfully added Candidate 1.</p>
     <p><i class="fas fa-exclamation-triangle c_3" ></i> Rejected.</p> -->
<div class="row">
     <div class="col-sm-12">
        <div class="form-group row">
         <label for="" class="col-sm-2 text-left col-form-label">Comments</label>
         <div class="col-sm-10">
           <textarea name="" id="" cols="30" rows="2" class="form-control"></textarea>
         </div>
       </div>
    </div>
</div>


     <div class="text-right">
       
     <button class="btn btn_un1 btn_m6 text-uppercase"(click)="modalRef.hide()">Cancel</button>
     <button class="btn btn_un1 btn_m7 text-uppercase ml-3" (click)="submit(templateDta)">Submit</button>
  </div>
    </div>
  </ng-template>



  <ng-template #template1>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Appointment Letter</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="offer_letter.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
    
<div class="row">
     <div class="col-sm-12">
      <iframe src="https://docs.google.com/viewer?url=http://www.pdf995.com/samples/pdf.pdf&embedded=true" frameborder="0" height="500px" width="100%"></iframe>
         <div class="letter_m1">
            <table>
                <tbody>
                    <tr>
                        <td>First Name</td>
                        <td>John</td>
                    </tr>
                    <tr>
                        <td>Last Name</td>
                        <td>Smith</td>
                    </tr>
                    <tr>
                        <td>Email Id</td>
                        <td>John.s@gmail.com</td>
                    </tr>
                    <tr>
                        <td>Contact number</td>
                        <td>99090902</td>
                    </tr>
                </tbody>
            </table>
            <p class="mt-4 mb-4">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. </p>
        <p>Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p> 
        </div>
    </div>
</div>

     <div class="text-right mt-4 mb-2">
        <button class="btn btn_un1 btn_m6 text-uppercase mr-3">reject</button>
     <button class="btn btn_un1 btn_m7 text-uppercase">approve</button>
  </div>
    </div>
  </ng-template>
  <ng-template #template3>
    <div class="modal-header">
      <!-- <h4 class="modal-title pull-left">{{templateName}}</h4> -->
      <button type="button" class="close pull-right" aria-label="Close" (click)="offer_letter.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
    
<div class="row">
     <div class="col-sm-12">
      <iframe src="https://docs.google.com/viewer?url=http://www.pdf995.com/samples/pdf.pdf&embedded=true" frameborder="0" height="500px" width="100%"></iframe>
         <div class="letter_m1">
            <table>
                <tbody>
                    <tr>
                        <td>First Name</td>
                        <td>John</td>
                    </tr>
                    <tr>
                        <td>Last Name</td>
                        <td>Smith</td>
                    </tr>
                    <tr>
                        <td>Email Id</td>
                        <td>John.s@gmail.com</td>
                    </tr>
                    <tr>
                        <td>Contact number</td>
                        <td>99090902</td>
                    </tr>
                </tbody>
            </table>
            <p class="mt-4 mb-4">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. </p>
        <p>Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p> 
        </div>
    </div>
</div>

     <div class="text-right mt-4 mb-2">
        <button class="btn btn_un1 btn_m6 text-uppercase mr-3">reject</button>
     <button class="btn btn_un1 btn_m7 text-uppercase">approve</button>
     
  </div>
    </div>
  </ng-template>
  <ng-template #template2>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Reject Appointment Letter</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="reject_Ref.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
     <!-- <p><i class="fas fa-check c_2"></i> Successfully added Candidate 1.</p>
     <p><i class="fas fa-exclamation-triangle c_3" ></i> Rejected.</p> -->
<div class="row">
     <div class="col-sm-12">
        <div class="form-group row">
         <label for="" class="col-sm-2 text-left col-form-label">Comments</label>
         <div class="col-sm-10">
           <textarea name="" id="" cols="30" rows="2" class="form-control"></textarea>
         </div>
       </div>
    </div>
</div>

     <div class="text-right">
        <button class="btn btn_un1 btn_m6 text-uppercase" (click)="reject_Ref.hide()">Cancel</button>
     <button class="btn btn_un1 btn_m7 text-uppercase ml-3" (click)="reject_Ref.hide()">Submit</button>
  </div>
    </div>
  </ng-template>