<div class="wrapper">
    <app-header></app-header>
    <div class="content-wrapper">
        <router-outlet></router-outlet>
        <div class="login_set">
            <div class="container-fluid">
                <!-- <div class="row">
                    <div class="col-sm-6 bg_m1">
                        <div class="login_left_set"></div>
                    </div>
                </div> -->
                <div class="col-12">
                    <div class="wrap_body">
                        <h4 class="title_m1">Update Password</h4>
                        <div class="row">
                            <form [formGroup]="formgroup" >
                                <div class="form col-sm-4 offset-sm-1">
                                 
                                    <div class="input_m1 mb-4">
                                        <input type="password" class="form-control" placeholder="Password"
                                            formControlName="password" required
                                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                            <div *ngIf="f.password.errors.required">Field is required</div>
                                            <div *ngIf="f.password.errors.pattern">Invallid Password</div>
                                        </div>
                                    </div>
                                    <div class="input_m1 mb-4">
                                        <input type="password" class="form-control" placeholder="New Password"
                                            formControlName="newPassword" required
                                            [ngClass]="{ 'is-invalid': submitted && f.newPassword.errors }">
                                        <div *ngIf="submitted && f.newPassword.errors" class="invalid-feedback">
                                            <div *ngIf="f.newPassword.errors.required">Field is required</div>
                                            <div *ngIf="f.newPassword.errors.pattern">Invallid New Password</div>
                                        </div>
                                    </div>
                                    <div class="input_m1 mb-4">
                                        <input type="password" class="form-control" placeholder="Confirm Password"
                                            formControlName="confirmPassword" required
                                            [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }">
                                        <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                                            <div *ngIf="f.confirmPassword.errors.required">Field is required</div>
                                            <div *ngIf="f.confirmPassword.errors.pattern">Invallid Confirm Password</div>
                                        </div>
                                    </div>
                                    <div *ngIf="errorMsgFlag" class="text-danger">
                                        {{errorMessage}}
                                    </div>
                                    <div>
                                        <button class="btn btn-block text-white mt-4 btn_m1" (click)="updatePwd()">Update Password</button>
                                    </div>
        
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>