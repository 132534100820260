import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

export class EncryptDecrypt {
   
 
 
  encrypt(input: any) {

    if(input === null || input === undefined){
      return input;
    }
    const key = CryptoJS.enc.Utf8.parse(environment.encryptionKey);
    const iv = CryptoJS.enc.Utf8.parse(environment.encryptionKey);
    const encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(input),
      key,
      {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
        iv: iv,
        keySize: 128 / 8 // 128 bits key size
      }
    );
      let encryptedData = encrypted.toString();
        encryptedData = encryptedData.replace(/\//g, "SlAy");
	    encryptedData = encryptedData.replace(/-/g, "HyFn");
	    encryptedData = encryptedData.replace(/=/g, "EQlS");
	    encryptedData = encryptedData.replace(/\+/g, "PlSs");
		encryptedData = encryptedData.replace(/&/g, "AmPr");
	   
      return encryptedData;
  }

  decrypt(input: any) {
    // input = input.replace('-', '/');
    const key = CryptoJS.enc.Utf8.parse(environment.encryptionKey);
    const iv = CryptoJS.enc.Utf8.parse(environment.encryptionKey);

    const encrypted = CryptoJS.enc.Base64.parse(input);

// Decrypt the data
    const decrypted = CryptoJS.AES.decrypt(
      input,
      key,
      {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
        iv: iv,
        keySize: 128 / 8
      }
    );
      // return decrypted.toString();
    return CryptoJS.AES.decrypt(input, environment.encryptionKey).toString(CryptoJS.enc.Utf8);
  }
}