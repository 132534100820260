<div class="wrap_body">
  <h4 class="title_m1">Addedddd Candidate Details</h4>
  <div class="row">
    <div class="col-sm-12 mt-3 mb-3">
      <h6>Personal Details</h6>
    </div>
  </div>
  <form class="row " [formGroup]="formgroup" (submit)="addCandiate()">
    <div class="col-sm-6">
      <div class="form-group row">
        <label for="" class="col-sm-3 text-right col-form-label">First name</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" id="" formControlName="firstName"
            [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }">
          <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
            <div *ngIf="f.firstName.errors.required">Field is required</div>
            <div *ngIf="f.firstName.errors.pattern">Invallid UserName</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="form-group row">
        <label for="" class="col-sm-3 text-right col-form-label">Last name</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" id="" formControlName="lastName"
            [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }">
          <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
            <div *ngIf="f.lastName.errors.required">Field is required</div>
            <div *ngIf="f.lastName.errors.pattern">Invallid UserName</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="form-group row">
        <label for="" class="col-sm-3 text-right col-form-label">Email Id</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" id="" formControlName="emailId"
            [ngClass]="{ 'is-invalid': submitted && f.emailId.errors }">
          <div *ngIf="submitted && f.emailId.errors" class="invalid-feedback">
            <div *ngIf="f.emailId.errors.required">Field is required</div>
            <div *ngIf="f.emailId.errors.pattern">Invallid UserName</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="form-group row">
        <label for="" class="col-sm-3 text-right col-form-label">Contact Number</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" id="" formControlName="contactNumber"
            [ngClass]="{ 'is-invalid': submitted && f.contactNumber.errors }">
          <div *ngIf="submitted && f.contactNumber.errors" class="invalid-feedback">
            <div *ngIf="f.contactNumber.errors.required">Field is required</div>
            <div *ngIf="f.contactNumber.errors.pattern">Invallid UserName</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 mt-3 mb-3">
      <h6>Address</h6>
    </div>
    <div class="col-sm-6">
      <div class="form-group row">
        <label for="" class="col-sm-3 text-right col-form-label">H.No.</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" id="" formControlName="hno"
            [ngClass]="{ 'is-invalid': submitted && f.hno.errors }">
          <div *ngIf="submitted && f.hno.errors" class="invalid-feedback">
            <div *ngIf="f.hno.errors.required">Field is required</div>
            <div *ngIf="f.hno.errors.pattern">Invallid UserName</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="form-group row">
        <label for="" class="col-sm-3 text-right col-form-label">Street/Building Name</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" id="" formControlName="streat"
            [ngClass]="{ 'is-invalid': submitted && f.streat.errors }">
          <div *ngIf="submitted && f.streat.errors" class="invalid-feedback">
            <div *ngIf="f.streat.errors.required">Field is required</div>
            <div *ngIf="f.streat.errors.pattern">Invallid UserName</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="form-group row">
        <label for="" class="col-sm-3 text-right col-form-label">LandaMark</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" id="" formControlName="landmark"
            [ngClass]="{ 'is-invalid': submitted && f.landmark.errors }">
          <div *ngIf="submitted && f.landmark.errors" class="invalid-feedback">
            <div *ngIf="f.landmark.errors.required">Field is required</div>
            <div *ngIf="f.landmark.errors.pattern">Invallid UserName</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="form-group row">
        <label for="" class="col-sm-3 text-right col-form-label">City</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" id="" formControlName="city"
            [ngClass]="{ 'is-invalid': submitted && f.city.errors }">
          <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
            <div *ngIf="f.city.errors.required">Field is required</div>
            <div *ngIf="f.city.errors.pattern">Invallid UserName</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="form-group row">
        <label for="" class="col-sm-3 text-right col-form-label">State</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" id="" formControlName="state"
            [ngClass]="{ 'is-invalid': submitted && f.state.errors }">
          <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
            <div *ngIf="f.state.errors.required">Field is required</div>
            <div *ngIf="f.state.errors.pattern">Invallid UserName</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="form-group row">
        <label for="" class="col-sm-3 text-right col-form-label">Country</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" id="" formControlName="country"
            [ngClass]="{ 'is-invalid': submitted && f.country.errors }">
          <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
            <div *ngIf="f.country.errors.required">Field is required</div>
            <div *ngIf="f.country.errors.pattern">Invallid UserName</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="form-group row">
        <label for="" class="col-sm-3 text-right col-form-label">PinCode</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" id="" formControlName="pincode"
            [ngClass]="{ 'is-invalid': submitted && f.pincode.errors }">
          <div *ngIf="submitted && f.pincode.errors" class="invalid-feedback">
            <div *ngIf="f.pincode.errors.required">Field is required</div>
            <div *ngIf="f.pincode.errors.pattern">Invallid UserName</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 mt-3 mb-3">
      <h6>Send to approver</h6>
    </div>
    <div class="col-sm-6">
      <div class="form-group row">
        <label for="" class="col-sm-3 text-right col-form-label">Approved by</label>
        <div class="col-sm-8">
          <select name="" id="" class="form-control" formControlName="approvedBy"
            [ngClass]="{ 'is-invalid': submitted && f.approvedBy.errors }">
            <option value="Manager">Manager</option>
            <option value="GM">GM</option>

          </select>
          <div *ngIf="submitted && f.approvedBy.errors" class="invalid-feedback">
            <div *ngIf="f.approvedBy.errors.required">Field is required</div>
            <div *ngIf="f.approvedBy.errors.pattern">Invallid UserName</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="form-group row">
        <label for="" class="col-sm-3 text-right col-form-label">Company</label>
        <div class="col-sm-8">
          <select name="" id="" class="form-control" formControlName="company"
            [ngClass]="{ 'is-invalid': submitted && f.company.errors }">
            <option value="">Company</option>
          </select>
          <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
            <div *ngIf="f.company.errors.required">Field is required</div>
            <div *ngIf="f.company.errors.pattern">Invallid UserName</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="form-group row">
        <label for="" class="col-sm-3 text-right col-form-label">Template</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" id="" formControlName="templateId"
            [ngClass]="{ 'is-invalid': submitted && f.templateId.errors }">
          <div *ngIf="submitted && f.templateId.errors" class="invalid-feedback">
            <div *ngIf="f.templateId.errors.required">Field is required</div>
            <div *ngIf="f.templateId.errors.pattern">Invallid UserName</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="form-group row">
        <label for="" class="col-sm-3 text-right col-form-label">Position</label>
        <div class="col-sm-8">
          <select name="" id="" class="form-control" formControlName="position"
            [ngClass]="{ 'is-invalid': submitted && f.position.errors }">
            <option value="">Position</option>
          </select>
          <div *ngIf="submitted && f.position.errors" class="invalid-feedback">
            <div *ngIf="f.position.errors.required">Field is required</div>
            <div *ngIf="f.position.errors.pattern">Invallid UserName</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="form-group row">
        <label for="" class="col-sm-3 text-right col-form-label">Trigger Date</label>
        <div class="col-sm-8">
          <input type="date" class="form-control" id="" formControlName="triggerDate"
            [ngClass]="{ 'is-invalid': submitted && f.triggerDate.errors }">
          <div *ngIf="submitted && f.triggerDate.errors" class="invalid-feedback">
            <div *ngIf="f.triggerDate.errors.required">Field is required</div>
            <div *ngIf="f.triggerDate.errors.pattern">Invallid UserName</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="form-group row">
        <label for="" class="col-sm-3 text-right col-form-label">Expiry Date</label>
        <div class="col-sm-8">
          <input type="date" class="form-control" id="" formControlName="expiryDate"
            [ngClass]="{ 'is-invalid': submitted && f.expiryDate.errors }">
          <div *ngIf="submitted && f.expiryDate.errors" class="invalid-feedback">
            <div *ngIf="f.expiryDate.errors.required">Field is required</div>
            <div *ngIf="f.expiryDate.errors.pattern">Invallid UserName</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-6 mt-3"><button class="btn btn_m3">Cancel</button></div>
    <div class="col-6 text-right mt-3"><button class="btn btn_m4" type="button" type="submit">Submit</button></div>
  </form>
</div>

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Added Candidate</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p><i class="fas fa-check c_2"></i> Successfully added Candidate 1.</p>
    <p><i class="fas fa-exclamation-triangle c_3"></i> Rejected.</p>
    <div class="text-right">
      <button class="btn btn_m5">Ok</button>
    </div>
  </div>
</ng-template>