import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  headers= new HttpHeaders()
  .set('content-type', 'application/json')
  .set('Access-Control-Allow-Origin', '*');
  constructor(private http:HttpClient) { }

  public login(data:any){
    return this.http.post<any>(environment.url+`/esign/login`,data);
  }

  public updatePwd(data:any){
    return this.http.post<any>(environment.url+`/esign/login/password`,data);
  }

  public forgotPwd(data:any){
    return this.http.post<any>(environment.url+`/esign/login/forgotPassword`,data);
  }
  
}
