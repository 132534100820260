import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifyService } from 'src/app/notify.service';
import { HradminService } from '../services/hradmin.service';
import { Validatorutil } from 'src/app/validatorutil';

@Component({
  selector: 'app-create-category',
  templateUrl: './create-category.component.html',
  styleUrls: ['./create-category.component.css']
})
export class CreateCategoryComponent implements OnInit {
  public formgroup: any;
  public submitted = false;
  public category: any = [];
  public storedata: any = [];
  public companyId: any;
  public companyDetails: any = {
    category: ""
  }
  companyList: any;
  sessionData: any;
  term: string = '';
  categoryId: any;

  constructor(private router: Router, private fb: FormBuilder,
    private hradminservice: HradminService, private notify: NotifyService,
    private validator: Validatorutil, private route: ActivatedRoute) {
    var data: any= sessionStorage.getItem('loginDetails');
    this.sessionData = JSON.parse(data);
    this.formgroup = fb.group({
      companyId: [this.sessionData.roleName != "Super Admin" ? this.sessionData.companyId : ''],
      id: [null],
      name: ['', [Validators.required, Validators.pattern(validator.NAME_REGEXP)]],
    })
  }

  ngOnInit(): void {
    this.categoryId = this.route.snapshot.paramMap.get('id');
    if (this.categoryId && this.categoryId != '') {
      this.loadCategory();
    }
    this.getCompanyList();
    this.viewCategorys();
  }

  loadCategory() {
    this.hradminservice.getCategory(this.categoryId).subscribe(
      res => {
        if (res.status === 'Success') {
          const response = res.respObject;
          this.formgroup.patchValue({
            companyId: response.companyId,
            id: response.id,
            name:response.name
          })
        }
      }
    )
  }

  addCategory() {
    if (this.formgroup.invalid) {
      this.submitted = true;
      return;
    } else {
      if (this.formgroup.value.id && this.formgroup.value.id != null) {
        this.updateCategory();
      } else {
        this.hradminservice.addCategory(this.formgroup.value).subscribe(
          res => {
            if (res.status == "Success") {
              this.notify.showSuccess('Successfully Saved.', 'Success');
              this.router.navigate(['/hradmin/categoryList']);
            }
          },
          (error) => {
            if (error.status === 500) {
              if (error?.error?.message){
                this.notify.showWarning(error?.error?.message, 'Warning');
              } else {
                this.notify.showError('Category details failed to save.', 'Fail');
              }
            }
          }
        )
      }
    }
  }

  updateCategory(){
    this.hradminservice.editCategory(this.formgroup.value).subscribe(
      res => {
        if (res.status == "Success") {
          this.notify.showSuccess('updated successfully.', 'Success');
          this.router.navigate(['/hradmin/categoryList'])
        }
      },
      (error) => {
        if (error.status === 500) {
          if (error?.error?.message){
            this.notify.showWarning(error?.error?.message, 'Warning');
          } else {
            this.notify.showError('Failed to update.', 'Failed');
          }
        }
      }
    )
  }

  cancel(){
    this.router.navigate(['/hradmin/categoryList'])
  }

  viewCategorys() {
    const status = 'active';
    if (this.formgroup.value.companyId) {
      this.hradminservice.getAllcategorys(this.formgroup.value.companyId, status).subscribe(
        res => {
          if (res.status == "Success") {
            this.category = res.respObject;
          }
        }
      )
    }
  }

  get f() {
    return this.formgroup.controls;
  }
  getCompanyList(){
    this.hradminservice.getAllcompanyDeatls().subscribe(
      res => {
        if (res.status == 'Success') {
          this.companyList = res.respObject;
          if (this.formgroup.value.companyId === '') {
            this.formgroup.get('companyId').setValue(this.companyList[0].companyId)
            this.viewCategorys();
          }
        }
      },
      (error) => {
        console.log('error: ' + error)
        if (error?.error?.status === 'Error') {
          this.notify.showError(error?.error?.message, 'Failed')
        }
      }
    )
  }
  selectCompany() {
    this.viewCategorys();
  }
  

}
