import { Component, OnInit } from '@angular/core';
import { HradminService } from '../services/hradmin.service';
import { NotifyService } from 'src/app/notify.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-workflowlist',
  templateUrl: './workflowlist.component.html',
  styleUrls: ['./workflowlist.component.css']
})
export class WorkflowlistComponent implements OnInit {
  public workflowData: any = [];
  sessionData: any;
  companyId: any;
  companyList: any;
  menuList: any;
  companyName: any;
  term: string = '';
  constructor(private hradminService: HradminService, private notify: NotifyService,
    private router: Router ) {
    var data: any= sessionStorage.getItem('loginDetails');
    this.sessionData = JSON.parse(data);
    this.companyId = this.sessionData.roleName != 'Super Admin' ? this.sessionData.companyId : '';
    var menu: any = sessionStorage.getItem('menuList');
    this.menuList = JSON.parse(menu);
  }

  ngOnInit(): void {
    this.getCompanyList();
    this.getWorkFlow()
  }

  getWorkFlow() {
    if (this.companyId) {
      this.hradminService.workflowListData(this.companyId).subscribe(
        res => {
          console.log(res);
          this.workflowData = res.respObject;
          this.workflowData = this.workflowData.map((item: any) => {
            return {
              ...item,
              companyName: this.sessionData.companyName
            };
          });
        }
      )
    }
  }

  getCompanyList(){
    this.hradminService.getAllcompanyDeatls().subscribe(
      res => {
        if (res.status == 'Success') {
          this.companyList = res.respObject;
          if (this.companyId === '') {
            this.companyId = this.companyList[0].companyId;
            this.getWorkFlow();
          }
          if (this.companyId && this.companyId != '') {
            this.companyName = this.companyList.filter((data: { companyId: number; }) => data.companyId === Number(this.companyId))[0]?.companyName;
          }
        }
      },
      (error) => {
        console.log('error: ' + error);
        if (error?.error?.status === 'Error') {
          this.notify.showError(error?.error?.message, 'Failed')
        }
      }
    )
  }

  selectCompany(event?: any){
    if (event && event.currentTarget.value) {
      this.companyId = event.currentTarget.value;
    }
    this.companyName = this.companyList.filter((data: { companyId: number; }) => data.companyId === Number(this.companyId))[0]?.companyName;
    this.getWorkFlow();
  }

  checkAccess(menu: any){
    return this.menuList.some((item: {
      edit: boolean; featureName: any; 
    }) => item.featureName.toLowerCase() === menu.toLowerCase() && item.edit);
  }

  navigateToWorkflow(){
    this.router.navigate(["/hradmin/workflow"])
  }

}
