<div class="login_set">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6 bg_m1">
                <div class="login_left_set"></div>
            </div>
            <div class="col-sm-6">
                <div class="login_right_set">
                    <!-- <p class="text-right">Doesn’t have an account? <a routerLink="/signup">Sign up</a></p> -->
                    <div class="col-sm-8 offset-sm-2">
                        <form [formGroup]="formgroup" (submit)="login()">
                            <div class="form ">
                                <h3 class="mt-5 mb-4">Sign in</h3>
                                <div class="input_m1 mb-4">
                                    <input type="text" class="form-control" placeholder="User Name"
                                        formControlName="loginId" required
                                        [ngClass]="{ 'is-invalid': submitted && f.loginId.errors }">
                                    <div *ngIf="submitted && f.loginId.errors" class="invalid-feedback">
                                        <div *ngIf="f.loginId.errors.required">Field is required</div>
                                        <div *ngIf="f.loginId.errors.pattern">Invallid UserName</div>
                                    </div>
                                </div>
                                <div class="input_m1 mb-4">
                                    <div>
                                        <input [type]="password" class="form-control" placeholder="Password"
                                            formControlName="password" required
                                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                                        <div class="icon">
                                            <a (click)="togglePassword($event)">
                                                <i class="fa fa-eye" aria-hidden="true" *ngIf="!show"></i>
                                                <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="show"></i>
                                            </a>
                                        </div>
                                        <!-- <span>
                                            <a (click)="togglePassword($event)">
                                                <i class="fa fa-eye" aria-hidden="true" *ngIf="!show"></i>
                                                <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="show"></i>
                                            </a>
                                        </span> -->
                                    </div>
                                        <!-- <input type="checkbox" (change)="togglePassword()"> Show Password -->
                                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                        <div *ngIf="f.password.errors.required">Field is required</div>
                                        <div *ngIf="f.password.errors.pattern">Invallid Password</div>
                                    </div>
                                </div>
                                <div class="mb-2">
                                    <a routerLink="/forgotPassword" class="link_m1">Forgot password?</a>
                                </div>
                                <div>
                                    <button class="btn btn-block text-white mt-4 btn_m1">SIGN IN</button>
                                </div>

                                <!-- <p class="text-center mt-4 mb-4 c_1">Or Login in with</p>
                                    <button class="btn btn-block btn_m2">Google</button> -->
                                </div>
                            </form>
                            <div class="form-check form-check-inline mt-4 check_m1">
                                <input class="form-check-input" type="checkbox" id="remember_me" [(ngModel)]="rememberMe">
                                <label class="form-check-label" for="remember_me" >Remember me</label>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>