import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, Observable, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { NotifyService } from "./notify.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  authRequest: any;
  constructor(private router: Router, private notify: NotifyService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = sessionStorage.getItem('token');
    const loginUrl = environment.url+ '/esign/login';
    const uploadSign = environment.url+ '/esign/transactionTrack';
    const forgotPwd = environment.url + '/esign/login/forgotPassword';
    const canVerify = environment.url + '/esign/login/verifyOtpLogin';
    const candidateUrl = environment.url+ '/esign/candidate/';
    const uploadDocs = environment.url + '/esign/uploadDetails/save';
    const getUploadDocs = environment.url + '/esign/uploadDetails/documents';
    const deleteUploadDocs = environment.url + '/esign/uploadDetails/delete';
    if (request.url === loginUrl 
      // || request.url.includes(candidateUrl) 
      || request.url === uploadSign
    || request.url.includes(forgotPwd) 
    // || request.url.includes(uploadDocs) 
    || request.url.includes(canVerify) 
    // || request.url.startsWith(getUploadDocs)
    // || request.url.startsWith(deleteUploadDocs)
    ) {
      return next.handle(request);
    }
    this.authRequest = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`)
    });
    return next.handle(this.authRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        // if (error.status === 0) {
        //   alert('Please check your internet connection!')
        // } else 
        if (error.status === 401) {
          sessionStorage.clear();
          this.router.navigate([`/login`]);
        } if (error?.error?.status == 'Error' || error?.error?.status == 412 || error.status === 412) {
          console.log('error: ->' + error?.error)
          this.notify.showError(error?.error?.message, 'Failed');
        } else {
          if (error?.status >= 400 && error?.status <= 422 && error?.status != 404) {
            this.notify.showError(error?.error ? error?.error?.message : 'Some error occured.', 'Failed');
          }
        }
        return throwError(error);
      })
    );
    ;
  }
}