import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-add-candidate-details',
  templateUrl: './add-candidate-details.component.html',
  styleUrls: ['./add-candidate-details.component.css']
})
export class AddCandidateDetailsComponent implements OnInit {
  public formgroup: any;
  public submitted = false;
  modalRef!: BsModalRef;
  public candiateDeatils: any = {
    firstName: "",
    lastName: "",
    emailId: "",
    contactNumber: "",
    address: {
      hno: "",
      landmark: "",
      state: "",
      country: "",
      streat: "",
      pincode: "",
      city: ""
    },
    template: {
      templateId: "",
      triggerDate: "",
      expiryDate: ""
    }

  }
  constructor(private modalService: BsModalService,
    private router: Router, private fb: FormBuilder, private http: HttpClient) {
    this.formgroup = fb.group({
      firstName: ['', [Validators.required, Validators.pattern('')]],
      lastName: ['', Validators.required],
      hno: ['', Validators.required],
      landmark: ['', Validators.required],
      streat: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      pincode: ['', Validators.required],
      emailId: ['', Validators.required],
      country: ['', Validators.required],
      contactNumber: ['', Validators.required],
      templateId: [''],
      triggerDate: [''],
      expiryDate: [''],
      approvedBy: [''],
      company: [''],
      position: [''],
    })
  }

  ngOnInit(): void {
  }

  openModal(template: TemplateRef<any>) {
    const initialState = {
    };
    // this.modalRef = this.modalService.show(template);
    this.modalRef = this.modalService.show(template,
      {
        class: 'modal-dialog-centered model_1', initialState,
      },
    );
  }

  splitAdress() {
    let stringToSplit = "abc def ghi";
    let x = stringToSplit.split(" ");
    console.log(x[0]);
  }

  addCandiate() {
    if (this.formgroup.invalid) {
      this.submitted = true;
      return;
    } else {
      this.openModal(this.formgroup);
      //  this.candiateDeatils.firstName=this.formgroup.value.firstName;
      //  this.candiateDeatils.lastName=this.formgroup.value.lastName;
      //  this.candiateDeatils.emailId=this.formgroup.value.emailId;
      //  this.candiateDeatils.contactNumber=this.formgroup.value.contactNumber;
      //  this.candiateDeatils.address.hno=this.formgroup.value.hno;
      //  this.candiateDeatils.address.streat=this.formgroup.value.streat;
      //  this.candiateDeatils.address.landmark=this.formgroup.value.landmark;
      //  this.candiateDeatils.address.city=this.formgroup.value.city;
      //  this.candiateDeatils.address.state=this.formgroup.value.country;
      //  this.candiateDeatils.address.pincode=this.formgroup.value.pincode;
      //  this.candiateDeatils.template.templateId=this.formgroup.value.templateId;
      //  this.http.post<any>('https://2d9776e0-8f75-40aa-9a87-6ac7196737a2.mock.pstmn.io/v1/candidate',this.candiateDeatils).subscribe(
      //   res=>{
      //     console.log(res);
      //     this.router.navigate(['/hradmin/appointment-list']);
      //   }
      //  );
    }
  }
  get f() {
    return this.formgroup.controls;
  }
}

