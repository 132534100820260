<div class="wrap_body">
  <div *ngIf="!otpVerified" class="display">
    <button #verifyButton (click)="popupModal(template1)" id="popup" >Verify OTP</button>
  </div>
  
  <form class="row" *ngIf="otpVerified">
    <div class="col-sm-12">
      <div class="card card_m1">
        <div class="card-header">
          <b>My Details</b>
        </div>
        <div class="p-3">
          <table>
            <tbody>
              <tr>
                <th class="col-sm-5">Recipient</th>
                <th>{{candidateDeatils?.candidateDto?.firstName}} {{candidateDeatils?.candidateDto?.lastName}}
                </th>
              </tr>
              <tr>
                <th class="col-sm-5">Email Id</th>
                <th>{{candidateDeatils?.candidateDto?.emailId}}</th>
              </tr>
              <tr>
                <th class="col-sm-5">Address</th>
                <th>{{getAddress(candidateDeatils?.candidateDto?.addressDto)}}
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="col-sm-12 mt-4">
      <div class="card card_m1">
        <div class="card-header">
          <b>Company Details</b>
        </div>
        <div class="p-3">
          <table>
            <tbody>
              <tr>
                <th class="col-sm-5">Company Name</th>
                <td> {{candidateDeatils?.companyName?.toUpperCase()}}</td>
              </tr>
              <tr>
                <th class="col-sm-5">Letter</th>
                <td> <button class="btn btn-link" style="padding-left: 0px;" type="button"
                    (click)="openModal(template, candidateDeatils?.templateDto?.templateName)">{{candidateDeatils?.templateDto?.templateName}}</button></td>
                    <a (click)="downloadFile(candidateDeatils?.templateCandidateId)" class="menu_icon" type="button" *ngIf="!acceptedFlag">
                      <img src="../../../assets/images/download.png" alt="">
                    </a>
              </tr>
              <tr *ngIf="!acceptedFlag">
                <th class="col-sm-5">Download File</th>
                <td>
                  <a (click)="downloadZipFile(candidateDeatils?.templateCandidateId)" class="download_icon mb-2" type="button">
                    <img src="../../../assets/images/download.png" alt="">
                  </a>
                </td>
              </tr>
              <tr>
                <th class="col-sm-5">Status</th>
                <td>{{candidateDeatils?.reviewStatus}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </form>
</div>

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{popupFileName}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="row">
      <div class="col-sm-12">
        <pdf-viewer [src]="pdfSrc" [render-text]="true" [original-size]="false"
          style="width: 100%; height: 600px"></pdf-viewer>
      </div>
    </div>
    <div class="my-3 col-sm-12 row">
      <div class="text-right col-sm-10" *ngIf="acceptedFlag && !continueFlag">
        <button class="btn btn_un1 btn_m6 text-uppercase" (click)="modalRef.hide()"
          (click)="approveLetter(template1,'REJECTED')">reject</button>
      </div>
      <div class="text-right col-sm-10" *ngIf="acceptedFlag && continueFlag">
        
      </div>
      <div class="col-sm-2" *ngIf="acceptedFlag && continueFlag">
        <button class="btn btn_un1 btn_m7 text-uppercase" (click)="modalRef.hide()">Close</button>
      </div>
  
      <div class="col-sm-2" *ngIf="acceptedFlag && !continueFlag">
        <button class="btn btn_un1 btn_m7 text-uppercase"  (click)="modalRef.hide()"
          (click)="afterApproval()">Continue</button>
      </div>
    </div>
  </div>
  <h4 *ngIf="!acceptedFlag" class="text-right">Already Accepted.</h4>
</ng-template>


<ng-template #template1>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modalRef1.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body mt-0">
    <div class="row">
      <div class="col-sm-12 mb-2">
        <h4 class="modal-title">Please enter OTP has send to your registered email.</h4>
      </div>
      <div class="col-sm-8">
        <input type="text" placeholder="Enter OTP" [(ngModel)]="otp" class="form-control">
      </div>
    </div>

    <div class="text-right mt-4 mb-2">
      <button class="btn btn_un1 btn_m7 text-uppercase" (click)="verifyOtpAndGetToken()">Verify</button>
    </div>
  </div>
</ng-template>