<div class="wrap_body">
  <h4 class="title_m1">Variable Mapping</h4>

  <form class="row " [formGroup]="formgroup" (submit)="saveUpdateVaribles()">
    <div class="col-sm-6">
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group row">
            <label for="" class="col-sm-3 text-right col-form-label">Company Name</label>
            <div class="col-sm-8">
              <select name="" id="" class="form-control" formControlName="companyId" (change)="companyEvent($event)">
                <option value="">Select</option>
                <option value="{{data.companyId}}" *ngFor="let data of companyList">{{data.companyName}}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="col-sm-12">
          <div class="form-group row">
            <label for="" class="col-sm-3 text-right col-form-label">Category</label>
            <div class="col-sm-8">
              <select name="" id="" class="form-control" formControlName="companyCategoryId"
                (change)="selectCategory($event)">
                <option value="">Select</option>
                <option value="{{data.id}}" *ngFor="let data of categorylist">{{data.name}}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="col-sm-12">
          <div class="form-group row">
            <label for="" class="col-sm-3 text-right col-form-label">Template Name</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" id="" formControlName="templateName">
              <!-- <select name="" id="" class="form-control" formControlName="templateId" (change)="selectTemplate($event)">
                <option value="">Select</option>
                <option value="{{data.templateId}}" *ngFor="let data of templatelist">{{data.templateName}}</option>
              </select> -->
            </div>
          </div>
        </div>

        <div class="col-sm-12">
          <div class="form-group row">
            <label for="" class="col-sm-3 text-right col-form-label">Template File</label>
            <div class="col-sm-8">
              <a href="javascript:;" class="link-primary" (click)="offer_letter_link(template3)">{{templateName}}</a>
            </div>
          </div>
        </div>
        <div class="col-sm-12 form-group row">
          <label class="col-sm-5 text-right">
            Aadhaar Signature
            <input type="radio" selected class="ml-2" formControlName="aadhaarSign" [value]=true>
          </label>
          <label class="col-sm-5 text-right">
            Image Signature
            <input type="radio" class="ml-2" formControlName="aadhaarSign" [value]=false>
          </label>
        </div>
        <div class="col-11 text-right mt-3">
          <button class="btn btn_m3 mr-3" (click)="cancel()">Cancel</button>
          <button class="btn btn_m4" type="submit">save</button></div>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="table_m3">
        <table>
          <thead>
            <tr>
              <th>Variable</th>
              <!-- <th>Value</th> -->

            </tr>
          </thead>
          <tbody>
            <tr *ngFor=" let data of variblesKeyData;let indexOfelement=index;">
              <td>{{data}}</td>
              <!-- <td>
                <select name="" id="{{indexOfelement}}" class="sel_m2" (change)="changeValues($event,data)">
                  <option [value]="value" [selected]="variblesValueData[indexOfelement]==value?true:false"
                    *ngFor="let value of variblesValueData">{{value}}</option>
                </select>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- <div class="col-6 mt-3"><button class="btn btn_m3">Cancel</button></div>
    <div class="col-6 text-right mt-3"><button class="btn btn_m4" type="submit">save</button></div> -->
  </form>
</div>
<ng-template #template3>
  <div class="modal-header">
    <!-- <h4 class="modal-title pull-left">{{templateName}}</h4> -->
    <button type="button" class="close pull-right" aria-label="Close" (click)="offer_letter.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="row">
      <div class="col-sm-12">
        <pdf-viewer [src]="fileUrl" [render-text]="true" [original-size]="false"
          style="width: 100%; height: 600px"></pdf-viewer>
      </div>
    </div>

  </div>
</ng-template>