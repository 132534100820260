import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { data } from 'jquery';
import { EncryptDecrypt } from 'src/app/encrypt-decrypt';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CandidateService {

  constructor(private http:HttpClient, private encDec: EncryptDecrypt) { }

  encrypt(value: any): any {
    return this.encDec.encrypt(value);
  }

  public candidatedeatils(id:any){
    return this.http.get<any>(environment.url+`/esign/candidate/candidateAppointment?candidateTemplateId=${this.encrypt(id)}`);
  }
  public candidateleterApprove(tId:any,status:any,data:any){
    return this.http.put<any>(environment.url+`/esign/candidate/candidateLetterAccept/${this.encrypt(tId)}/${this.encrypt(status)}`,data);
  }
  public candidatEducationDetails(candidateId:any,data:any){
    return this.http.post<any>(environment.url+`/esign/candidate/add/${candidateId}/candidateQualification`,data);
  }
  public candidateProfessionDeatils(candidateId:any,data:any){
    return this.http.post<any>(environment.url+`/esign/candidate/add/${candidateId}/candidateExperience`,data);
  }
  public candidateEsignService(candidateTemplateId:any,candidateTextSign:any,data:any){
    return this.http.post<any>(environment.url+`/esign/candidate/signUpload/${this.encrypt(candidateTemplateId)}/${this.encrypt(candidateTextSign)}`,data);
  }
  public generateOtpService(data: any){
    return this.http.post<any>(environment.url+`/esign/transactionTrack`,data);
  }

  public verifyOtpService(data: any){
    return this.http.post<any>(environment.url+`/esign/transactionTrack/verify`,data);
  } 

  public verifyOtpGetToken(data: any){
    return this.http.post<any>(environment.url+`/esign/login/verifyOtpLogin`,data);
  }

  public getEsignXml(candidateId: any){
    return this.http.get<any>(environment.url+`/esign/candidate/candidateEsign?candidateTemplateId=${this.encrypt(candidateId)}`);
  }

  public saveDetails(data: any){
    return this.http.post<any>(environment.url+`/esign/uploadDetails/save`, data);
  }

  public uploadDetails(data: any){
    return this.http.put<any>(environment.url+`/esign/uploadDetails`, data);
  }

  getUploadDocs(id: any) {
    return this.http.get<any>(environment.url+`/esign/uploadDetails/documents/${this.encrypt(id)}`);
  } 
  
  deleteUploadDocs(id: any) {
    return this.http.delete<any>(environment.url+`/esign/uploadDetails/delete/${this.encrypt(id)}`);
  }
}
