import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HradminService } from '../services/hradmin.service';

@Component({
  selector: 'app-create-template',
  templateUrl: './create-template.component.html',
  styleUrls: ['./create-template.component.css']
})
export class CreateTemplateComponent implements OnInit {
  public formgroup:any;
  public submitted=false;
  public templateArray:any=[];
  public category:any=[];
  public storedata:any=[];
  public variblesData=[];
  public uploadResponseFlag=false;
  public templateNames=[];
  public baseUrl=environment.url;
  public templateDetails:any={
    templateId:"",
    fileContent:"",
    approverId:null,
    statusId:null,
    CompanyCategoryId:"",
    companyName:"",
    variableKey:[
       {
          "key":"$dob$",
          "value":""
       },
       {
        "key":"$contact$",
        "value":""
       },
       {
        "key":"$name$",
        "value":""
       },
    ]
 }
  constructor(private router:Router,
    private fb:FormBuilder,private http:HttpClient,private hradminService:HradminService) 
  { 
    this.formgroup =fb.group({
      companyName:[''],
      category:['',Validators.required],
      templateName:['',Validators.required],
      fileContent:['']
    })
  }
  afuConfig = {
    uploadAPI: {
      url:this.baseUrl+"/esign/template/loadplaceholders",
      
    },
    autoUpload: true,
    hideResetBtn: true,
    replaceTexts: {
      selectFileBtn: 'Choose file',
      resetBtn: 'Reset',
      uploadBtn: 'Choose file',
      dragNDropBox: 'Drag N Drop',
      attachPinBtn: 'Attach Files...',
      afterUploadMsg_success: 'Successfully Uploaded !',
      afterUploadMsg_error: 'Upload Failed !',
      sizeLimit: 'Size Limit'
    }
};
  ngOnInit(): void {
    
    // this.http.get<any>('https://2d9776e0-8f75-40aa-9a87-6ac7196737a2.mock.pstmn.io/v1/master/variableValue').subscribe(
    //   res =>{
    //    console.log(res);
    //    this.templateNames=res.values;
    //   }
    // )
  }
  
  fileSelected(event:any){
   this.formgroup.fileContent=event.target.files[0];
   const uploadImageData = new FormData();
    uploadImageData.append(
      "imageFile",
      this.formgroup.fileContent,
      this.formgroup.fileContent.name
    );
   sessionStorage.setItem("file", uploadImageData.toString());
  }
  docUpload(event:any){
    if(event.status=="200"){
       this.uploadResponseFlag=true;
    this.variblesData=event.body.variables;
    }
    // sessionStorage.setItem("file", event.target.files[0]);
  }
  changeValues(event:any){
    console.log(event.currentTarget.value);
    if(this.templateDetails.variableKey[0].value==null ||this.templateDetails.variableKey[0].value==""){
      this.templateDetails.variableKey[0].value=event.currentTarget.value;
    }else if(this.templateDetails.variableKey[1].value==null || this.templateDetails.variableKey[1].value==""){
      this.templateDetails.variableKey[1].value=event.currentTarget.value;

    }else if( this.templateDetails.variableKey[2].value==null || this.templateDetails.variableKey[2].value==""){
      this.templateDetails.variableKey[2].value=event.currentTarget.value;
    }
    
  }
  contactNo(event:any){
    console.log(event.currentTarget.value);
  }
  candidateName(event:any){
   
    console.log(event.currentTarget.value);
  }
  addTemplate(){
    if (this.formgroup.invalid) {
      this.submitted= true;
      return;
    } else {
      this.templateDetails.templateId=this.formgroup.value.templateName;
      this.templateDetails.fileContent=this.formgroup.fileContent;
      this.templateDetails.CompanyCategoryId=this.formgroup.value.category;
      this.templateDetails.companyName=this.formgroup.value.companyName;
      this.templateArray.push(this.templateDetails);
      sessionStorage.setItem("templateDetails",JSON.stringify(this.templateArray));
      this.http.post('https://2d9776e0-8f75-40aa-9a87-6ac7196737a2.mock.pstmn.io/v1/template',this.templateDetails).subscribe(
        res=>{
          console.log(res);
        }
      )
      this.viewTempaltes();
      this.formgroup.reset();
    }
  }
  viewTempaltes(){
    this.storedata=sessionStorage.getItem("templateDetails");
    let arry = JSON.parse(this.storedata);
    this.templateArray=arry;
  }
get f(){
    return this.formgroup.controls;
  }
}
