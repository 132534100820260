<div class="wrap_body">
   <h4 class="title_m1">Workflow</h4>
   <div class="row">
      <form class="container" [formGroup]="workflowGroup">
         <div class="col-sm-6">
            <div class="row">
               <div class="col-sm-12">
                  <div class="form-group row">
                     <label for="" class="col-sm-3 text-right col-form-label">Company Name <span class="c_3">&ast;</span></label>
                     <div class="col-sm-8">
                        <select name="" id="" class="form-control" formControlName="companyId" (change)="selectCompany()" 
                        required [ngClass]="{ 'is-invalid': submitted && f.companyId.errors }">
                           <option value="">Select</option>
                           <option value="{{data.companyId}}" *ngFor="let data of companyList">{{data.companyName}}</option>
                        </select>
                        <div *ngIf="submitted && f.companyId.errors" class="invalid-feedback">
                           <div *ngIf="f.companyId.errors.required">Field is required</div>
                           <div *ngIf="f.companyId.errors.pattern">Invallid UserName</div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-sm-12">
                  <div class="form-group row">
                     <label for="" class="col-sm-3 text-right col-form-label">Category <span class="c_3">&ast;</span></label>
                     <div class="col-sm-8">
                        <select name="" id="" class="form-control" (click)="selectCategory($event)"
                           formControlName="categoryCompanyId" required 
                           [ngClass]="{ 'is-invalid': submitted && f.categoryCompanyId.errors }">
                           <option value="">Select</option>
                           <option value="{{data.id}}" *ngFor="let data of categoryCompanyList">{{data.name}}</option>
                        </select>
                        <div *ngIf="submitted && f.categoryCompanyId.errors" class="invalid-feedback">
                           <div *ngIf="f.categoryCompanyId.errors.required">Field is required</div>
                           <div *ngIf="f.categoryCompanyId.errors.pattern">Invallid UserName</div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-sm-12">
                  <div class="form-group row">
                     <label for="" class="col-sm-3 text-right col-form-label">Template Name <span class="c_3">&ast;</span></label>
                     <div class="col-sm-8">
                        <select name="" id="" class="form-control" (click)="clickTemplet()" 
                           formControlName="templateId" required (change)="selecTemplet($event)" 
                           [ngClass]="{ 'is-invalid': submitted && f.templateId.errors }">
                           <option value="">Select</option>
                           <option value="{{data.templateId}}" *ngFor="let data of templets">{{data.templateName}}</option>
                        </select>
                        <div *ngIf="submitted && f.templateId.errors" class="invalid-feedback">
                           <div *ngIf="f.templateId.errors.required">Field is required</div>
                           <div *ngIf="f.templateId.errors.pattern">Invallid UserName</div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-sm-12">
                  
               </div>
            </div>
         </div>
      </form>
      <form [formGroup]="groupData">
         
         <div class="col-sm-6 my-4">
            <h5 class="mt-3">Reviewer <span class="c_3">&ast;</span></h5>
            <ng-multiselect-dropdown [placeholder]="'Select Reviewer'" [settings]="dropdownSettings" [data]="userList"
                (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
               formControlName="reviewerId" required [ngClass]="{ 'is-invalid': submitted && form.reviewerId.errors }">
            </ng-multiselect-dropdown>
            <div *ngIf="submitted && form.reviewerId.errors" class="invalid-feedback">
               <div *ngIf="form.reviewerId.errors.required">Field is required</div>
               <div *ngIf="form.reviewerId.errors.pattern">Invallid UserName</div>
            </div>
         </div>
         <div class="col-sm-6 my-4">
            <h5 class="mt-3">Approver <span class="c_3">&ast;</span></h5>
            <ng-multiselect-dropdown [placeholder]="'Select Approver'" [settings]="dropdownSettings" [data]="userList"
                (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
               formControlName="approverId" required [ngClass]="{ 'is-invalid': submitted && form.approverId.errors }">
            </ng-multiselect-dropdown>
            <div *ngIf="submitted && form.approverId.errors" class="invalid-feedback">
               <div *ngIf="form.approverId.errors.required">Field is required</div>
               <div *ngIf="form.approverId.errors.pattern">Invallid UserName</div>
            </div>
         </div>
         <div class="col-sm-6 my-4">
            <h5 class="mt-3">Issuer <span class="c_3">&ast;</span></h5>
            <ng-multiselect-dropdown [placeholder]="'Select Issuer'" [settings]="dropdownSettings" [data]="userList"
                (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)" [(ngModel)]="groupData.value.issuerId"
               formControlName="issuerId" required [ngClass]="{ 'is-invalid': submitted && form.issuerId.errors }">
            </ng-multiselect-dropdown>
            <div *ngIf="submitted && form.issuerId.errors" class="invalid-feedback">
               <div *ngIf="form.issuerId.errors.required">Field is required</div>
               <div *ngIf="form.issuerId.errors.pattern">Invallid UserName</div>
            </div>
         </div>
      </form>
   </div>
   <div class="col-sm-12 text-right mt-3 mb-4">
      <button class="btn btn_m3 mr-2" type="button" routerLink="/hradmin/workflowList" routerLinkActive="active">Cancel</button>
      <button class="btn btn_m4" type="button" *ngIf="!updateFlag" (click)="workflowData()">Save</button>
      <button class="btn btn_m4" type="button" *ngIf="updateFlag" (click)="updateWorkflowData()">Update</button>
   </div>
</div>