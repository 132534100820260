import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReportsComponent } from './reports.component';
import { UserreportsComponent } from './userreports/userreports.component';

const routes: Routes = [
  {path:'',component:ReportsComponent,
  children: [
    {path:'userreports',component:UserreportsComponent}
  ]
},
 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
