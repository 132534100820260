import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NotifyService } from 'src/app/notify.service';
import { Validatorutil } from 'src/app/validatorutil';
import { environment } from 'src/environments/environment';
import { HradminService } from '../services/hradmin.service';

@Component({
  selector: 'app-variable-mapping',
  templateUrl: './variable-mapping.component.html',
  styleUrls: ['./variable-mapping.component.css']
})
export class VariableMappingComponent implements OnInit {
  public companyId: any;
  sessionData: any;
  public formgroup: any;
  public templatelist: any = [];
  public categorylist: any = [];
  public variablesData: any = [];
  public variblesKeyData: any = [];
  public variblesValueData: any = [];
  public companyList: any = [];
  public companyName: any;
  public variblekey: any = [];
  public templateFile: any;
  modalRef!: BsModalRef;
  offer_letter!: BsModalRef;
  reject_Ref!: BsModalRef;
  public templateName: any;
  public varSelectedValue: any;
  public fileUrl: any;
  public baseUrl: any;
  public templateId: any;
  constructor(private hradminService: HradminService,
    private fb: FormBuilder, private validtor: Validatorutil, private notify: NotifyService,
    private modalService: BsModalService, private route: ActivatedRoute, private router: Router) {
    var data: any = sessionStorage.getItem('loginDetails');
    this.sessionData = JSON.parse(data);
    this.companyId = this.sessionData.roleName != 'Super Admin' ? this.sessionData.companyId : '';
    var data: any = sessionStorage.getItem('loginDetails');
    this.sessionData = JSON.parse(data);
    this.baseUrl = environment.url;
    this.formgroup = fb.group({
      templateId: null,
      companyCategory: [''],
      companyCategoryId: ['', Validators.required],
      fileContentPDF: [''],
      templateName: ['', [Validators.required, Validators.pattern(validtor.NAME_REGEXP)]],
      approverId: [this.sessionData.userId],
      statusId: [''], // need to change
      variableKey: this.fb.array([]),
      companyId: [this.sessionData.roleName != 'Super Admin' ? this.sessionData.companyId : ''],
      aadhaarSign: [''],

    })
  }
  public templateDetails: any = {
    templateId: "",
    approverId: null,
    statusId: null,
    companyCategoryId: "",
    templateName: "",
    variableKey: []
  }
  ngOnInit(): void {
    this.getCompanyList();
    this.templateId = this.route.snapshot.paramMap.get('id');
    this.getVariblsMapping(this.templateId);
    // this.getTemplateList();
    // this.getCategorys();
  }
  getCompanyList() {
    this.hradminService.getAllcompanyDeatls().subscribe(
      res => {
        if (res.status == 'Success') {
          this.companyList = res.respObject;
        }
      },
      (error) => {
        console.log('error: ' + error);
        if (error?.error?.status === 'Error') {
          this.notify.showError(error?.error?.message, 'Failed')
        }
      }
    )
  }
  getCategorys() {
    const status = 'active';
    if (this.formgroup.value.companyId && this.formgroup.value.companyId != "") {
      this.hradminService.getAllcategorys(this.formgroup.value.companyId, status).subscribe(
        res => {
          this.categorylist = res.respObject;
        },
        (error) => {
          console.log(error);
          if (error?.error?.status === 'Error') {
            this.notify.showError(error?.error?.message, 'Failed')
          }
        }
      )
    }
  }
  getTemplateList() {
    const status = 'active';
    if (this.formgroup.value.companyId && this.formgroup.value.companyId != "") {
      this.hradminService.getAllTemplatesByCId(this.formgroup.value.companyId, status).subscribe(
        res => {
          this.templatelist = res.respObject;
        },
        (error) => {
          if (error?.error?.status === 'Error') {
            this.notify.showError(error?.error?.message, 'Failed')
          }
        }
      )
    }
  }
  selectTemplate(event: any) {
    
    console.log(event.currentTarget.value);
    this.variablesData = [];
    this.variblesKeyData = [];
    this.variblesValueData = [];
    this.getVariblsMapping(event.currentTarget.value);

  }
  getVariblsMapping(id: any) {
    
    this.hradminService.getTemplateDetailsByTemplateId(id).subscribe(
      res => {
        this.variablesData = res.respObject.variableKey;
        const data = res.respObject;
        this.formgroup.patchValue({
          aadhaarSign: JSON.parse(data.aadhaarSign),
          approverId: data.approverId,
          templateId: data.templateId,
          statusId: data.statusId,
          fileContentPDF: data.fileContentPDF,
          companyCategoryId: data.companyCategoryId,
          companyId: data.companyId,
          templateName: data.templateName,
          // variableKey: this.variablesData
        });
        this.getTemplateList();
        this.getCategorys();
        this.templateName = data.templateName;
        this.templateFile = data.fileContentPDF;
        this.fileUrl = this.baseUrl + '/esign' + data.fileUrl;
        this.variablesData.forEach((element: { key: any; value: any }) => {
          // this.varSelectedValue=element.value;
          this.variblesKeyData.push(element.key);
          this.variblesValueData.push(element.value);
          // this.varSelectedValue=this.variablesData.filter((value: { value: any; }) => value.value ===element.value)[0].value;
        });
        
      },
      (error) => {
        if (error?.error?.status === 'Error') {
          this.notify.showError(error?.error?.message, 'Failed')
        }
      }
    )
  }
  varibleValueReturn(element: any) {
    this.varSelectedValue = this.variablesData.filter((value: { value: any; }) => value.value === element.value)[0].templateVariableId;
  }
  selectCategory(event: any) {
    this.formgroup.value.companyCategoryId = event.currentTarget.value;
  }

  companyEvent(event?: any) {
    console.log(event.target.value);
    this.companyId = event.target.value;
    this.formgroup.value.companyId = this.companyId;
    this.hradminService.getcompanyDeatislById(event.target.value).subscribe(
      res => {
        console.log(res);
        this.companyName = res.respObject.companyName;
      },
      (error) => {
        console.log('error: ' + error);
        if (error?.error?.status === 'Error') {
          this.notify.showError(error?.error?.message, 'Failed')
        }
      }
    )
    this.getTemplateList();
    this.getCategorys();
  }
  testSearch(term: string, item: any) {
    console.log(item);
    console.log(term);
    return item.name.startsWith(term);
  }
  changeValues(event: any, user: any) {
    let varibleKeydata = {
      id: null,
      key: "",
      value: "",
      templateVariableId: ''
    }
    varibleKeydata.value = event.currentTarget.value;
    varibleKeydata.key = user;
    varibleKeydata.id = this.variablesData.filter((value: { value: any; }) => value.value === event.currentTarget.value)[0].id;
    varibleKeydata.templateVariableId = this.variablesData.filter((value: { value: any; }) => value.value === event.currentTarget.value)[0].templateVariableId;
    this.formgroup.value.variableKey.push(varibleKeydata);
  }
  saveUpdateVaribles() {
    this.hradminService.updateTemplateVaribales(this.formgroup.value).subscribe(
      res => {
        if (res.status = 'Success') {
          this.notify.showSuccess('Template Updated successfully', 'Success');
          this.router.navigate(['/hradmin/template']);
        }
      },
      (error) => {
        if (error?.error?.status === 'Error') {
          this.notify.showError(error?.error?.message, 'Failed')
        } else {
          this.notify.showError('Template Update failed', 'Failed');
        }
        console.log(error);
      }
    )

  }

  cancel() {
    this.router.navigate(['/hradmin/template']);
  }

  offer_letter_link(template1: TemplateRef<any>) {
    const initialState = {

    };

    this.offer_letter = this.modalService.show(template1,
      {
        class: 'modal-dialog-centered model_1 modal-lg', initialState,

      },

    );
  }
}
