<div class="wrap_body">
  <h4 class="title_m1">Bulk Appointments</h4>
  <div class="row">
    <div class="col-sm-3">
      <div class="search_m1 mb-3">
        <input type="text" class="form-control" placeholder="search">
        <img src="../../../assets/images/Search.png" alt="">
      </div>
    </div>
    <div class="list_m1 row my-2">
      <div class="col-sm-3">
        <select name="" id="" (change)="companyEvent($event)" class="form-control" [(ngModel)]="companyId">
          <option value="">Select Company</option>
          <option value="{{data.companyId}}" *ngFor="let data of companyList">{{data.companyName}}</option>
        </select>
      </div>
    </div>
    <div class="list_m1 row my-2">
      <div class="col-sm-3">
        <select name="" id="" (change)="selectCategory($event)" class="form-control">
          <option value="">Select Category</option>
          <option value="{{data.id}}" *ngFor="let data of categorys">{{data.name}}</option>
        </select>
      </div>
    </div>
    <div class="list_m1 row my-2">
      <div class="col-sm-3">
        <select name="" id="" (change)="selectTemplate($event)" class="form-control">
          <option value="">Select Template</option>
          <option value="{{data.templateId}}" *ngFor="let data of masterTemplteList">{{data.templateName}}</option>
        </select>
      </div>
    </div>
    <div class="col-sm-12">
      <ul class="list_m1">
        <li><a href="javascript:;" (click)="downloadExcel()">Click here</a> to Download template. </li>
        <li>Add your data in the template file. </li>
        <li>Upload the template.</li>
        <li>All excel fields should text format.</li>
        <li>Data format should be dd/mm/yyyy</li>
        <li>Limit of records should not exceed 20</li>
        <li>If you don't have template please select the category and template to download format.</li>
      </ul>
      <div class="file_m1 file_drag_m1 my-3">
        <!-- <angular-file-uploader [config]="afuConfig" (fileSelected)="fileSelected($event)">
        </angular-file-uploader> -->
        <input type="file" (change)="fileSelected($event)" [(ngModel)]="fileName"> 
        <div *ngIf="msgFlagSuccess" class="text-success text-bold mt-1">{{successMsg}}</div>
        <div *ngIf="msgFlagError" class="text-danger text-bold mt-1">{{errorMsg}}</div>
      </div>
    </div>
    
    <form [formGroup]="formgroup" (submit)="submit()">
      <div class="col-sm-12">
        <div class="table-responsive text-nowrap" *ngIf="headers?.length > 0">
          <table class="table table-striped table_m1 table_sort">
            <thead>
              <tr>
                <th>Action</th>
                <ng-container *ngFor="let header of headers">
                  <th *ngIf="(header.value != 'templateId') && (header.value != 'isValidTemplate')">{{header.value}}</th>
                </ng-container>
              </tr>
            </thead>
            <ng-container formArrayName="bulAppointMentsList">
              <tbody>
                <tr *ngFor="let formFeature of formgroup.controls.bulAppointMentsList.controls; let i = index;"
                  [formGroupName]="i">
                  <td><button class="btn" (click)="delete(i)"><i class="fas fa-trash"></i></button></td>
                  <ng-container *ngFor="let header of headers">
                    <td *ngIf="(header.key != 'templateId') && (header.key != 'isValidTemplate')">
                      <ng-container *ngIf="(header.key === 'id') || (header.key === 'templateName') || (header.key === 'errorMessage')">
                        <input type="text" class="form-control" [formControlName]="getVal(header)" readonly>
                      </ng-container>
                      <ng-container *ngIf="((header.key != 'id') || (header.key != 'templateName')) && (header.key != 'id') && (header.key != 'templateName') && (header.key != 'errorMessage')">
                        <input type="text" class="form-control" [formControlName]="getVal(header)">
                      </ng-container>
                    </td>
                  </ng-container>
                  <!-- <td *ngFor="let header of headers">
                    <div *ngIf="(header.value != 'templateId') && (header.value != 'isValidTemplate')">
                      <input type="text" class="form-control" [formControlName]="getVal(header)">
                    </div>
                  </td>  -->
                </tr>
              </tbody>
            </ng-container>
          </table>
        </div>
      </div>
      <!-- <div class="col-6 mt-3"><button class="btn btn_m3">Cancel</button></div> -->
      <div class="col-sm-12 text-right mt-12"><button class="btn btn_m4" type="submit">Submit</button>
      </div>
    </form>
  </div>
</div>