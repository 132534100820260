import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { from } from 'rxjs';
import { ExcelService } from 'src/app/shared/excel/excel.service';
import { environment } from 'src/environments/environment';
import { HrService } from '../services/hr.service';
import { NotifyService } from 'src/app/notify.service';
import { SuperuserService } from 'src/app/superuser/services/superuser.service';

@Component({
  selector: 'app-bulk-appointments',
  templateUrl: './bulk-appointments.component.html',
  styleUrls: ['./bulk-appointments.component.css']
})
export class BulkAppointmentsComponent implements OnInit {
  public formgroup: any;
  public variblesData: any = [];
  public editedVaraibles: any = [];
  public uploadResponseFlag = false;
  public appointmentList: any = [];
  public users = [{ "id": "", "firstName": "", "lastName": "", "aadhaarName": "", "hno": "", "city": "", "street": "", "pincode": "", "state": "", "country": "", "email": "", "company": "", "triggerDate": "", "expiryDate": "", "contactNumber": "", "templateName": "" }]
  public baseUrl = environment.url;
  public sessionData: any;
  public companyId: any;
  public uploadImageData = new FormData();
  public candiateRequests: any = [];
  templetId: any;
  request!: CandidateRequest;
  address!: Address;
  templet!: Templet;
  candidate!: Candidate
  public featureValue: any;
  masterTemplateId: any;
  masterTemplteList: any;
  variables: any = [];
  companyList: any;
  categorys: any;
  headers: any;
  msgFlagSuccess: boolean = false;
  msgFlagError: boolean = false;
  successMsg: string = 'Successfully Uploaded!';
  errorMsg: string = 'Upload Failed!';
  fileName: any;
  constructor(private excelService: ExcelService, private hrService: HrService,
    private fb: FormBuilder, private http: HttpClient, private router: Router, private superUserService: SuperuserService,
    private cdr: ChangeDetectorRef, private formBuilder: FormBuilder, private notify: NotifyService) {
    this.formgroup = fb.group({
      bulAppointMentsList: this.formBuilder.array([])
    })
    var data: any = sessionStorage.getItem('loginDetails');
    this.sessionData = JSON.parse(data);
    this.companyId = this.sessionData.roleName != 'Super Admin' ? this.sessionData.companyId : '';
  }
  afuConfig = {
    uploadAPI: {
      url: "https://2d9776e0-8f75-40aa-9a87-6ac7196737a2.mock.pstmn.io//v1/bulkupload/filetojson"
    },
    autoUpload: true,
    hideResetBtn: true,
    theme: "dragNDrop",
    hideProgressBar: true,
    replaceTexts: {
      selectFileBtn: 'Choose file',
      resetBtn: 'Reset',
      uploadBtn: 'Choose file',
      dragNDropBox: 'Drag and Drop',
      attachPinBtn: 'Attach Files...',
      afterUploadMsg_success: 'Successfully Uploaded!',
      afterUploadMsg_error: 'Upload Failed !',
      sizeLimit: 'Size Limit'
    }
  };
  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }
  ngOnInit(): void {
    this.getCompanyList();
    this.getCategorys();
  }

  getCompanyList() {
    this.superUserService.getAllcompanyDeatls().subscribe(
      res => {
        if (res.status == 'Success') {
          this.companyList = res.respObject;
        }
      },
      (error) => {
        console.log('error: ' + error);
        if (error?.error?.status === 'Error') {
          this.notify.showError(error?.error?.message, 'Failed')
        }
      }
    )
  }
  companyEvent(event?: any) {
    this.companyId = event.currentTarget.value ? event.currentTarget.value : '';
    this.getCategorys();
  }
  getCategorys() {
    const status = 'active';
    if (this.companyId != '') {
      this.hrService.getCategoryByCompanyId(this.companyId, status).subscribe(
        res => {
          console.log(res);
          this.categorys = res.respObject;
        },
        (error) => {
          console.log('error: ' + error);
          if (error?.error?.status === 'Error') {
            this.notify.showError(error?.error?.message, 'Failed')
          }
        }
      );
    }
  }

  selectCategory(event?: any){
    if (event.currentTarget.value) {
      this.getTemplates(event.currentTarget.value)
    }
  }

  getTemplates(categoryId: any) {
    this.hrService.getTempletList(categoryId).subscribe(
      res => {
        this.masterTemplteList = res.respObject;
      }
    )
  }

  selectTemplate(event?: any) {
    if (event && event.currentTarget.value) {
      this.masterTemplateId = event.currentTarget.value;
    }
  }
  
  downloadExcel(): void {
    if(!this.masterTemplateId){
      this.notify.showInfo('Please select Template.', 'Info');
      return;
    }
    this.hrService.getExcel(this.masterTemplateId).subscribe(
      (data: Blob) => {
        this.handleExcelDownload(data);
      },
      (error: any) => {
        console.error('Error downloading Excel file:', error);
      }
    );
  }

  private handleExcelDownload(data: Blob): void {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Bulk upload.xlsx'; // You can set the filename as desired
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  get items(): FormArray {
    return this.formgroup.get('bulAppointMentsList') as FormArray;
  }
  get f() {
    return this.formgroup.controls;
  }
  fileSelected(event: any) {
    
    if (!this.companyId || this.companyId === '') {
      this.notify.showInfo('Please select company.', 'Info');
      return;
    }
    this.uploadImageData = new FormData();
    this.formgroup.fileContent = event.target.files[0];
    this.uploadImageData.append(
      "file",
      this.formgroup.fileContent,
      this.formgroup.fileContent.name
    );

    sessionStorage.setItem("file", this.uploadImageData.toString());
    this.docUpload(this.uploadImageData);
  }
  docUpload(data: any) {
    
    this.http.post<any>(this.baseUrl + `/esign/candidate/excelDataList/${this.companyId}`, data).subscribe(
      res => {
        console.log(res);
        if (res != null) {
          this.msgFlagSuccess = true;
          this.msgFlagError = false;
          this.uploadResponseFlag = true;
          this.afuConfig.replaceTexts.afterUploadMsg_success;
          this.variblesData = res.respObject?.reportList;
          this.headers = res.respObject?.reportHeader
          this.getVariables(this.headers);
        }
        for(var data of this.variblesData){
          this.featureValue = this.formBuilder.group({})
          if (data.isValidTemplate) {
            for(var formKey of this.headers){
              this.featureValue.addControl(formKey.key, new FormControl(this.getKey(data, formKey.key)));
            }
            this.items.push(this.featureValue);
          }
        }
        this.cdr.detectChanges();
      },
      (error) => {
        this.msgFlagError = true;
        this.msgFlagSuccess = false;
        this.fileName = '';
        this.items.clear();
        this.headers = [];
        if (error.error.status === 'Error') {
          this.notify.showError(error.error.message, 'Error');
        } else {
          this.notify.showError('Document upload failed.', 'Error');
        }
      }
    )
  }

  getVariables(data: any){
    for(let variable of data){
      if (variable.key?.startsWith("$")) {
        this.variables.push(variable.key);
      }
    }
  }

  getVal(value: any){
    return value?.key;
  }

  getKey(data?:any, value?:any){
    return data[value];
  }

  delete(id: any){
    this.items.removeAt(id)
  }

  export() {
    this.excelService.exportAsExcelFile(this.users, 'template');
  }
  submit() {
    
    this.candiateRequests = [];
    this.editedVaraibles = this.items.value;
    for (var data of this.editedVaraibles) {
      this.address = {
        id: null,
        hno: data.hno,
        street: data.street,
        city: data.city,
        country: data.country,
        pincode: data.pincode,
        cityId: data.cityId,
        stateOfCountry: data.state,
        stateId: data.stateId,
        countryId: data.countryId,
        landmark: data.landmark,
        localArea: data.localArea,
        location: data.location
      }
      this.candidate = {
        addressDto: this.address,
        emailId: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        contactNumber: data.contactNumber,
        aadhaarName: data.aadhaarName,
        id: null
      }
      this.templet = {
        templateId: Number(data.templateId),
        templateName: data.templateName,
        companyId: Number(this.companyId)
      }
      this.request = {
        candidateDto: this.candidate,
        templateDto: this.templet,
        companyId: Number(this.companyId),
        triggerDate: data.triggerDate,
        expiryDate: data.expiryDate,
        variableKeyDtoList: this.getVariablesData(data)
      }
      this.candiateRequests.push(this.request);
    }
    if (Object.keys(this.candiateRequests).length === 0){
      this.notify.showError('Please upload candidate details.', 'Error');
      return;
    }
    this.hrService.bulkappointmentList(this.candiateRequests).subscribe(
      res => {
        
        if (res.status == "Success") {
          this.notify.showSuccess(res?.message, res.status);
          this.router.navigate(['/hr/appointment-list']);
        }
      }, (error) => {
        console.log(error.message);
        if (error?.error?.status == 'Error') {
          console.log('error: ->' + error?.error)
          this.notify.showError(error?.error?.message, 'Failed');
        } else {
          this.notify.showError('Failed to save data.', 'res.status')
        }
      }
    )
  }

  getVariablesData(data: any){
    let variablesData: any = [];
    for(let varData of this.variables){
      variablesData.push({
        candidateTemplateId: null,
        candidateTemplateVariableId: null,
        id: null,
        key: varData,
        value: data[varData],
        templateVariableId: null
      })
    }
    return variablesData;
  }
}

interface CandidateRequest {
  "candidateDto": Candidate,
  "templateDto": Templet,
  "expiryDate": any,
  "triggerDate": any,
  "companyId": number,
  "variableKeyDtoList": any
}

interface Templet {
  "companyId": number
  "templateId": number,
  "templateName": "string",
}

interface Address {
  "id": any,
  "pincode": number,
  "city": "string",
  "cityId": number,
  "country": "string",
  "countryId": number,
  "hno": "string",
  "landmark": "string",
  "stateId": number,
  "stateOfCountry": "string",
  "street": "string"
  // "latitude": number,
  // "longitude": number,
  "location": "",
  "localArea": "",
}

interface Candidate {
  "contactNumber": "string",
  "emailId": "string",
  "firstName": "string",
  "id": any,
  "lastName": "string",
  "aadhaarName": "string",
  "addressDto": Address
}
