import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EncryptDecrypt } from 'src/app/encrypt-decrypt';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HradminService {
  public token = sessionStorage.getItem('token');
  headers = new HttpHeaders()
    .set('content-type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('Authorization', "Bearer " + this.token);
  constructor(private http: HttpClient, private encDec: EncryptDecrypt) { }

  encrypt(value: any): any {
    return this.encDec.encrypt(value);
  }

  public workflowData(data: any) {
    return this.http.post<any>(environment.url + `/esign/workflow`, data);
  }
  public workflowDataUpdate(data: any) {
    return this.http.put<any>(environment.url + `/esign/workflow/update`, data);
  }
  public workflowListData(companyId: any) {
    return this.http.get<any>(environment.url + `/esign/workflow/${this.encrypt(companyId)}`);
  }
  addCompany(data: any) {
    return this.http.post<any>(environment.url + `/esign/company`, data);
  }
  updateCompany(data: any) {
    return this.http.put<any>(environment.url + `/esign/company`, data);
  }
  addCategory(data: any) {
    return this.http.post<any>(environment.url + `/esign/companycataegory`, data);
  }

  getCategory(id: any) {
    return this.http.get<any>(environment.url + `/esign/companycataegory/${this.encrypt(id)}`);
  }

  editCategory(data: any) {
    return this.http.put<any>(environment.url + `/esign/companycataegory`, data);
  }

  deleteCategory(id: any) {
    return this.http.delete<any>(environment.url + `/esign/companycataegory/${this.encrypt(id)}`);
  }

  categoryList(companyId: any, status: any) {
    return this.http.get<any>(environment.url + `/esign/companycataegory/company/${this.encrypt(companyId)}/${this.encrypt(status)}`);
  }

  templetList(companyId: any, categoryId: any) {
    return this.http.get<any>(environment.url + `/esign/template/search?companyId=${this.encrypt(companyId)}&categoryId=${this.encrypt(categoryId)}`);
  }

  getUserDetails(companyId: any, status: any) {
    return this.http.get<any>(environment.url + `/esign/usermanagement/userRole/${this.encrypt(companyId)}/${this.encrypt(status)}`);
  }

  templateVariables() {
    return this.http.get<any>(environment.url + `/esign/master/mapVariables`)
  }

  getAllcompanyDeatls() {
    return this.http.get<any>(environment.url + `/esign/company`);
  }

  updateCategoryStatus(id: any, status: any) {
    return this.http.delete<any>(environment.url + `/esign/companycataegory/${this.encrypt(id)}/status/${this.encrypt(status)}`);
  }

  deleteCompany(id: any, status: any) {
    return this.http.delete<any>(environment.url + `/esign/company/${id}/status/${status}`);
  }

  getCandidateVariables(templateId: any, candidateTemplateId: any) {
    return this.http.get<any>(environment.url + `/esign/template/variables/${this.encrypt(templateId)}/${this.encrypt(candidateTemplateId)}`);
  }

  getCandidateDeatls(candidateId: any) {
    return this.http.get<any>(environment.url + `/esign/candidate/candidateAppointment?candidateTemplateId=${this.encrypt(candidateId)}`);
  }

  getWorkflowData(templateId:any) {
    return this.http.get<any>(environment.url + `/esign/workflow/template/${this.encrypt(templateId)}`);
  }

  getAllcategorys(companyId: any, status: any) {
    return this.http.get<any>(environment.url + `/esign/companycataegory/company/${this.encrypt(companyId)}/${this.encrypt(status)}`);
  }
  getAllTemplates() {
    return this.http.get<any>(environment.url + `/esign/template`);
  }
  updateTemplateVaribales(data:any) {
    return this.http.put<any>(environment.url + `/esign/template`,data);
  }
  getAllTemplatesByCId(cId: any, status: any) {
    return this.http.get<any>(environment.url + `/esign/template/companyId/${this.encrypt(cId)}/${this.encrypt(status)}`);
  }
  updateTemplateStatus(data: any) {
    return this.http.put<any>(environment.url + `/esign/template/updateStatus`, data);
  }
  templateStatus(id: any, status: any) {
    return this.http.delete<any>(environment.url + `/esign/template/${this.encrypt(id)}/recordStatus/${this.encrypt(status)}`);
  }
  getTemplateDetailsByTemplateId(id:any){
    return this.http.get<any>(environment.url + `/esign/template/templateDetails/${this.encrypt(id)}`);
  }
  getcompanyDeatislById(cId:any){
    return this.http.get<any>(environment.url+`/esign/company/${this.encrypt(cId)}`);
  }

}
