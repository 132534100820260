import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportserviceService {

  constructor(private http:HttpClient) { }

  getServiceReport(){
    return this.http.get<any>(environment.url+`/esign/report/company`);
  }

  getReports(){
    return this.http.get<any>(environment.url+`/esign/report/list`);
  }

  getReportsData(request: any){
    return this.http.post<any>(environment.url+`/esign/report/company`, request);
  }

  getAllcompany() {
    return this.http.get<any>(environment.url + `/esign/company`);
  }
}
