import { Component, OnInit } from '@angular/core';
import { ReportserviceService } from '../services/reportservice.service';
import { ExcelService } from 'src/app/shared/excel/excel.service';
import { FormBuilder } from '@angular/forms';
import { NotifyService } from 'src/app/notify.service';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-userreports',
  templateUrl: './userreports.component.html',
  styleUrls: ['./userreports.component.css']
})
export class UserreportsComponent implements OnInit {
  dtOptions: any = {};
  public serviceReportData: any;
  dtTrigger: any;
  formgroup: any;
  public formBuilder = new FormBuilder;
  headers: any;
  reports: any;
  p: number = 1;
  term: any = '';
  sorting: boolean = true;  
  recordCount: number = 10;
  currentPage = 1;
  companyList: any;
  sessionData: any;
  constructor(private reportservice: ReportserviceService, private _excelService: ExcelService,
    private notify: NotifyService, private datePipe: DatePipe) {
    var data: any = sessionStorage.getItem('loginDetails');
    this.sessionData = JSON.parse(data);
    this.formgroup = this.formBuilder.group({
      name: [''],
      period: ['Last week'],
      fromDate: [new Date(new Date(new Date().toISOString().substring(0, 10)).getTime() + -7 * 24 * 60 * 60 * 1000).toISOString().substring(0, 10)],
      toDate: [new Date().toISOString().substring(0, 10)],
      companyId: [this.sessionData.roleName != 'Super Admin' ? this.sessionData.companyId : '']
    })

  }
  getValue(value: any){
    return value;
  }
  pageConfig = {
    id: 'pagination',
    itemsPerPage:this.recordCount,
    currentPage: 1,
  };
  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      processing: true,
      dom: 'Blfrtip',
      buttons: ['colvis', 'excel', 'print'],
    };
    this.getReports()
    // this.getServiceReportDetails();
    // this.dtTrigger.next();
    this.selectDate();
    this.getCompanyList();
  }

  export(){
    // this._excelService.exportAsExcelFile(this.serviceReportData, 'Reports');
    let currentDateTime =this.datePipe.transform((new Date), 'MM:dd:yyyy-h:mm');
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
 
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Report');
    let reportName = this.reports.filter((data: { key: any; }) => data.key === Number(this.formgroup.get('name').value))[0]?.name.replace(/ /g, '_') + '-' + currentDateTime + '.xlsx'

    /* save to file */  
    XLSX.writeFile(wb, reportName);
  }

  getCompanyList(){
    this.reportservice.getAllcompany().subscribe(
      res => {
        if (res.status == 'Success') {
          this.companyList = res.respObject;
          this.formgroup.get('companyId').value === '' ? this.formgroup.get('companyId').setValue(this.companyList[0].companyId) : '';
          this.submitData()
        }
      },
      (error) => {
        console.log('error: ' + error);
        if (error?.error?.status === 'Error') {
          this.notify.showError(error?.error?.message, 'Failed')
        }
      }
    )
  }

  getServiceReportDetails() {
    this.reportservice.getServiceReport().subscribe(
      res => {
        const response = res.respObject;
        this.serviceReportData = response.reportList;
        this.headers = response.reportHeader;
        // this.dtTrigger.next();
      })
  }

  getKey(data?:any, value?:any){
    return data[value];
  }

  pageCount(count: any){
    this.recordCount = Number(count.currentTarget.value)
    this.pageConfig.currentPage = 1;
    this.pageConfig.itemsPerPage = this.recordCount;
  }

  selectDate() {
    const duration = this.formgroup.value.period;
    let days = 1;
    if (duration.toLowerCase() === 'Last week'.toLowerCase()) {
      days = 7;
    }else if (duration === 'Last 3 months') {
      days = 90;
    }else if (duration === 'Last 6 months') {
      days = 180;
    } else {
      days = 365;
    }
    if (this.formgroup.value.period) {
      this.formgroup.patchValue({
        fromDate: new Date(new Date(this.formgroup.value.toDate).getTime() + (-days) * 24 * 60 * 60 * 1000).toISOString().substring(0, 10)
      })
    }
  }

  getReports() {
    this.reportservice.getReports().subscribe(
      res => {
        if (res.status === "Success") {
          this.reports = res.respObject
          this.formgroup.get('name').value === '' ? this.formgroup.get('name').setValue(this.reports[0].key) : '';
        }
      },
      (error) => {
        console.log(error?.error ? error?.error?.message : error);
        if (error?.error?.status === 'Error') {
          this.notify.showError(error?.error?.message, 'Failed')
        }
      }
    )
  }

  getReportMsg(message: any){
    return this.formgroup.value.name === '' ? 
    (message.indexOf('Company') !== -1 || message.indexOf('Duration') !== -1 ? ' Report' : ' Report') : '';
  }

  getDurationMsg(message: any){
    return this.formgroup.value.period === '' ? 
    (message.indexOf('Company') !== -1 || message.indexOf('Report') !== -1) ? ' Duration' : ' Duration' : '';
  }

  submitData(){
    console.log(this.formgroup.value)
    let message = 'Please select ';
    
    if (this.formgroup.value.companyId === '' || this.formgroup.value.name === '' || this.formgroup.value.period === '') {
      message = message + (this.formgroup.value.companyId === '' ? (message.indexOf('Duration') !== -1 || message.indexOf('Report') !== -1 ? ' & Company' : 'Company') : '')
      + this.getReportMsg(message)
      + this.getDurationMsg(message);
      this.notify.showInfo(message, 'Info')
      return;
    }
    this.reportservice.getReportsData(this.formgroup.value).subscribe(
      res => {
        if (res.status === "Success") {
          this.serviceReportData = [];
          this.headers = [];
          const response = res.respObject;
          this.serviceReportData = response.reportList;
          this.headers = response.reportHeader;
          // this.dtTrigger.next();
        }
      },
      (error) => {
        console.log(error?.error ? error?.error?.message : error);
        if (error?.error?.status === 'Error') {
          this.notify.showError(error?.error?.message, 'Failed')
        }
      }
    )
  }

  sort(array: any, colName: string, sort: boolean) {
    if (sort) {
      array.sort((a: any, b: any) => a[colName] < b[colName] ? 1 : a[colName] > b[colName] ? -1 : 0)
      this.sorting = !this.sorting
    } else {
      array.sort((a: any, b: any) => a[colName] > b[colName] ? 1 : a[colName] < b[colName] ? -1 : 0)
      this.sorting = !this.sorting
    }
  }
  
  sortNumber(array: any, colName: string, sort: boolean) {
    if (sort) {
      array.sort((a: any, b: any) => a[colName] - b[colName]);
      this.sorting = !this.sorting;
    } else {
      array.sort((a: any, b: any) => b[colName] - a[colName]);
      this.sorting = !this.sorting;
    }
  }
}
