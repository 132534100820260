<div class="login_set">
    <div class="container-fluid">
        <div class="col-12">
            <div class="login_right_set">
                <div class="row">
                    <form [formGroup]="formgroup" >
                        <div class="form col-sm-4 offset-sm-2">
                            <h3 class="mt-5 mb-4">Forgot Password</h3>
                            <div class="input_m1 mb-4">
                                <input type="text" class="form-control" placeholder="Username"
                                    formControlName="loginId" required
                                    [ngClass]="{ 'is-invalid': submitted && f.loginId.errors }">
                                <div *ngIf="submitted && f.loginId.errors" class="invalid-feedback">
                                    <div *ngIf="f.loginId.errors.required">Field is required</div>
                                    <div *ngIf="f.loginId.errors.pattern">Invallid UserName</div>
                                </div>
                            </div>
                            
                            <div *ngIf="errorMsgFlag" class="text-danger">
                                {{errorMessage}}
                            </div>
                            <div>
                                <button class="btn btn-block text-white mt-3 btn_m1" (click)="forgotPwd()">Send Password</button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>