import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hradmin',
  templateUrl: './hradmin.component.html',
  styleUrls: ['./hradmin.component.css']
})
export class HradminComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
