import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CandidateRoutingModule } from './candidate-routing.module';
import { DetailsComponent } from './details/details.component';
import { CandidateLayoutComponent } from './candidate-layout/candidate-layout.component';
import { UploadEducationalDocumentsComponent } from './upload-educational-documents/upload-educational-documents.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AngularFileUploaderModule } from "angular-file-uploader";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
  declarations: [
    DetailsComponent,
    CandidateLayoutComponent,
    UploadEducationalDocumentsComponent,
   
  ],
  imports: [
    CommonModule,
    CandidateRoutingModule,
    TabsModule.forRoot(),
    AngularFileUploaderModule,
    ReactiveFormsModule,
    FormsModule,
    PdfViewerModule
    
  ],
  
})
export class CandidateModule { }
