<header class="main-header">
  <!-- Logo -->
  <a (click)="home()" class="logo">
    <!-- mini logo for sidebar mini 50x50 pixels -->
    <span class="logo-mini"><b>Esign</b></span>
    <!-- logo for regular state and mobile devices -->
    <span class="logo-lg"> <img src="../../../assets/images/ESIGN_Logo.png" alt=""></span>
  </a>
  <!-- Header Navbar: style can be found in header.less -->
  <nav class="navbar navbar-static-top">
    <!-- Sidebar toggle button-->
    <a href="#" class="menu_icon" data-toggle="push-menu" role="button">
      <img src="../../../assets/images/menu.png" alt="">
    </a>

    <div class="navbar-custom-menu">
      <ul class="nav navbar-nav">
        <span dropdown (onShown)="onShown()" (onHidden)="onHidden()" (isOpenChange)="isOpenChange()"
          class="drp_m4 logout">
          <a href id="basic-link" dropdownToggle (click)="false" aria-controls="basic-link-dropdown"><img
              src="../../../assets/images/user-pic.png" alt=""> {{userName}}</a>
          <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="basic-link">
            <li><a (click)="showProfile()">Profile</a></li>
            <li><a (click)="updatePwd()">Update password</a></li>
            <li><a (click)="logOutPopUp(template)">Logout</a></li>
          </ul>
        </span>
      </ul>
    </div>
  </nav>
</header>
<!-- Left side column. contains the logo and sidebar -->
<aside class="main-sidebar">
  <!-- sidebar: style can be found in sidebar.less -->
  <section class="sidebar">
    <!-- sidebar menu: : style can be found in sidebar.less -->
    <ul class="sidebar-menu" data-widget="tree">
      <!-- <li *ngIf="hradminTag">
        <a routerLink="/hradmin/appointment-list" routerLinkActive="active">
          <img src="../../../assets/images/vector.png" alt="" style="width: 14px;margin-left: 3px;">
          <span>AppointMentList</span>
        </a>
      </li> -->
      <!-- <li *ngIf="checkAccess('Create Company')">
        <a routerLink="/hradmin/create-company" routerLinkActive="active">
          <img src="../../../assets/images/vector.png" alt=""> <span>Create Company</span>
        </a>
      </li> -->
      <li class="text-white mt-3">
        <a routerLink="/dashboard/dashboard" routerLinkActive="active">
          <img src="../../../assets/images/menu_icon3.png" alt=""> <span>Home</span>
        </a>
      </li>
      <li *ngIf="checkAccess('package')">
        <a routerLink="/subscription/package" routerLinkActive="active">
          <img src="../../../assets/images/menu_icon8.png" alt=""> <span>Package</span>
        </a>
      </li>
      <li *ngIf="checkAccess('subscription')">
        <a routerLink="/subscription/subscribersList" routerLinkActive="active">
          <img src="../../../assets/images/menu_icon5.png" alt=""> <span>Subscribe</span>
        </a>
      </li>
      <li *ngIf="checkAccess('company')">
        <a routerLink="/hradmin/companylist" routerLinkActive="active">
          <img src="../../../assets/images/menu_icon2.png" alt=""> <span>Company</span>
        </a>
      </li>
      <li *ngIf="checkAccess('role')">
        <a routerLink="/superuser/assignrole" routerLinkActive="active">
          <img src="../../../assets/images/menu_icon4.png" alt=""> <span>Role</span>
        </a>
      </li>
      <li *ngIf="checkAccess('user')">
        <a routerLink="/superuser/userlist" routerLinkActive="active">
          <img src="../../../assets/images/menu_icon5.png" alt=""> <span>Users</span>
        </a>
      </li>
      <li *ngIf="checkAccess('category')">
        <a routerLink="/hradmin/categoryList" routerLinkActive="active">
          <img src="../../../assets/images/menu_icon7.png" alt=""> <span>Category</span>
        </a>
      </li>

      <li *ngIf="checkAccess('template')">
        <a routerLink="/hradmin/template" routerLinkActive="active">
          <img src="../../../assets/images/menu_icon8.png" alt="" style="width: 14px;margin-left: 3px;">
          <span>Template</span>
        </a>
      </li>
      <!-- <li *ngIf="checkAccess('Workflow')">
        <a routerLink="/hradmin/workflow" routerLinkActive="active">
          <img src="../../../assets/images/pdf_icon.png" alt="" style="width: 14px;margin-left: 3px;"> <span>Work
            Flow</span>
        </a>
      </li> -->
      <li *ngIf="checkAccess('workflow')">
        <a routerLink="/hradmin/workflowList" routerLinkActive="active">
          <img src="../../../assets/images/pdf_icon.png" alt="" style="width: 14px;margin-left: 3px;"> <span>Workflow</span>
        </a>
      </li>
      <li *ngIf="checkAccess('recipient')">
        <a routerLink="/hr/appointment-list" routerLinkActive="active">
          <img src="../../../assets/images/pdf_icon.png" alt="" style="width: 14px;margin-left: 3px;"> <span>Recipient</span>
        </a>
      </li>
      <!-- <li *ngIf="checkAccess('Add Candidate')">
        <a routerLink="/hr/add-Candidate" routerLinkActive="active">
          <img src="../../../assets/images/pdf_icon.png" alt="" style="width: 14px;margin-left: 3px;"> <span>Add
            Candidate</span>
        </a>
      </li> -->
      <!-- <li *ngIf="checkAccess('Create Template')">
        <a routerLink="/hr/create-template" routerLinkActive="active">
          <img src="../../../assets/images/vector.png" alt=""> <span>Create Template</span>
        </a>
      </li> -->
      <!-- <li *ngIf="checkAccess('Bulk Appointment')">
        <a routerLink="/hr/bulk-appointments" routerLinkActive="active">
          <img src="../../../assets/images/sms.png" alt=""> <span>Bulk Appointment</span>
        </a>
      </li> -->
      <!-- <li *ngIf="checkAccess('Add User')">
        <a routerLink="/superuser/add-user" routerLinkActive="active">
          <img src="../../../assets/images/vector.png" alt=""> <span>Add User</span>
        </a>
      </li> -->
      

      <li *ngIf="checkAccess('Assign Role To User')">
        <a routerLink="/superuser/assignroleTouser" routerLinkActive="active">
          <img src="../../../assets/images/menu_icon4.png" alt=""> <span>Assign Role To User</span>
        </a>
      </li>
      <li *ngIf="checkAccess('Assigned Role List')">
        <a routerLink="/superuser/assigned-role-list" routerLinkActive="active">
          <img src="../../../assets/images/list 1.png" alt=""> <span>Assigned Role List</span>
        </a>
      </li>
      <!-- <li *ngIf="checkAccess('Create Package')">
        <a routerLink="/subscription/package" routerLinkActive="active">
          <img src="../../../assets/images/vector.png" alt=""> <span>Packages</span>
        </a>
      </li> -->
      
      <!-- <li *ngIf="checkAccess('Subscription')">
        <a routerLink="/subscription/subscribeCompany" routerLinkActive="active">
          <img src="../../../assets/images/vector.png" alt=""> <span>Subscribe Company</span>
        </a>
      </li> -->
      <li *ngIf="checkAccess('Subscription Usage')">
        <a routerLink="/subscription/subscribersListDashboard" routerLinkActive="active">
          <img src="../../../assets/images/menu_icon8.png" alt=""> <span>Subscription Usage</span>
        </a>
      </li>
      <li *ngIf="checkAccess('Analytics')">
        <a routerLink="/subscription/analytics" routerLinkActive="active">
          <img src="../../../assets/images/menu_icon1.png" alt=""> <span>Analytics</span>
        </a>
      </li>
      <li *ngIf="checkAccess('Report')">
        <a routerLink="/reports/userreports" routerLinkActive="active">
          <img src="../../../assets/images/menu_icon1.png" alt=""> <span>Report</span>
        </a>
      </li>
      <!-- <li class="active treeview">
            <a routerLink="add-Candidate">
              <img src="../../../assets/images/vector.png" alt=""> <span>Add Candidate</span>
              <span class="pull-right-container">
                <i class="fas fa-chevron-down  pull-right" ></i>
              
              </span>
            </a>
            <ul class="treeview-menu">
              <li class="active"><a href="index.html"><i class="fa fa-circle-o"></i> Candidate v1</a></li>
              <li><a href="index2.html"><i class="fa fa-circle-o"></i> Candidate v2</a></li>
            </ul>
          </li>
         
          <li>
            <a routerLink="appointment-list">
              <img src="../../../assets/images/list-1.png" alt=""> <span>Candidate List</span>
           </a>
          </li>

          <li>
            <a href="pages/widgets.html">
              <img src="../../../assets/images/pdf_icon.png" alt="" style="width: 14px;margin-left: 3px;"> <span>Variable Mapping</span>
           </a>
          </li>
          
          <li>
            <a routerLink="bulk-appointments">
              <img src="../../../assets/images/sms.png" alt=""> <span>Bulk Appointment</span>
           </a>
          </li> -->

    </ul>
  </section>
  <!-- /.sidebar -->
</aside>

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-right text-center">Are you sure you want to logout?</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="text-center">
      <button class="btn btn_un1 btn_m6 text-uppercase" (click)="modalRef.hide()">Cancel</button>
      <button class="btn btn_un1 btn_m7 text-uppercase ml-3" (click)="logOut()">Logout</button>
    </div>
  </div>
</ng-template>