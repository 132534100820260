import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

import { catchError, debounceTime, distinctUntilChanged, Observable, of, switchMap } from 'rxjs';
import { NotifyService } from 'src/app/notify.service';
import { Validatorutil } from 'src/app/validatorutil';
import { HradminService } from '../services/hradmin.service';


@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.css']
})
export class WorkflowComponent implements OnInit {
  public groupData: any;
  public updateFlag:boolean = false;
  public workflowGroup: any;
  public categoryCompanyList: any = [];
  sessionData: any;
  public submitted = false;
  public templets: any = [];
  request!: WorkflowRequest;
  userList:any;
  companyList: any;
  workflowDetailsData:any;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings = {};
  public templateId :any;
  companyId: any;
  menuList: any;
  constructor(private fb: FormBuilder, private router: Router, private notify: NotifyService,
    private hradminService: HradminService, private validator: Validatorutil,private route: ActivatedRoute) {
    var data: any = sessionStorage.getItem('loginDetails');
    this.sessionData = JSON.parse(data);
    var menu: any = sessionStorage.getItem('menuList');
    this.menuList = JSON.parse(menu);
    this.companyId = this.sessionData.companyId;
    this.groupData = fb.group({
      approverId: ['', [Validators.required]],
      issuerId: ['', [Validators.required]],
      reviewerId: ['', [Validators.required]]
    }),
      
    this.workflowGroup = fb.group({
      companyName: [this.sessionData.roleName != 'Super Admin' ? this.sessionData.companyName : ''],
      // category: [''],
      // templateName: [''],
      // level: [''],
      templateId: [''],
      categoryCompanyId: [''],
      issuerIds: [],
      reviewerIds: [],
      approverIds: [],
      companyId: [this.sessionData.roleName != 'Super Admin' ? this.sessionData.companyId : '']
    })
  }

  ngOnInit(): void {
    this.templateId = this.route.snapshot.paramMap.get('id');
    // this.categoryList(this.workflowGroup.value.companyId);
    // this.templtetList();
    this.userDetails();
    this.getCompanyList();
    this.loadSetting();
    this.categoryList(this.workflowGroup.value.companyId);
    if(this.templateId && this.templateId != ""){
      this.updateFlag = true;
      this.loadWorkflowData(this.templateId);
    }
  }

  loadSetting() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'userId',
      textField: 'emailId',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 4,
      allowSearchFilter: true,
      limitSelection: 4,
      enableCheckAll: true
    };
  }

  loadWorkflowData(templateId:any){
    this.hradminService.getWorkflowData(templateId).subscribe(
      res => {
        if (res.status == 'Success') {
          this.workflowDetailsData = res.respObject[0];
          this.workflowGroup.patchValue({
            companyId: this.workflowDetailsData.companyId,
            categoryCompanyId: this.workflowDetailsData.categoryCompanyId,
            templateId: this.workflowDetailsData.templateId
          });
          if (this.categoryCompanyList && this.categoryCompanyList.length == 0) {
            this.categoryList(this.workflowDetailsData.companyId);
          }
          this.templtetList();
          this.userDetails();
          if (this.workflowGroup.value.companyId != "") {
            this.hradminService.getUserDetails(this.workflowGroup.value.companyId, 'active').subscribe(res => {
              if (res.status === "Success") {
                this.userList= res.respObject;
                this.groupData.patchValue({
                  issuerId: this.getUserDetails(this.workflowDetailsData.issuerIds),
                  reviewerId: this.getUserDetails(this.workflowDetailsData.reviewerIds),
                  approverId: this.getUserDetails(this.workflowDetailsData.approverIds)
                });
              }
            })
          }
        }
      },
      (error) => {
        console.log('error: ' + error);
        if (error?.error?.status === 'Error') {
          this.notify.showError(error?.error?.message, 'Failed')
        }
      }
    )
  }
  getCompanyList(){
    this.hradminService.getAllcompanyDeatls().subscribe(
      res => {
        if (res.status == 'Success') {
          this.companyList = res.respObject;
        }
      },
      (error) => {
        console.log('error: ' + error);
        if (error?.error?.status === 'Error') {
          this.notify.showError(error?.error?.message, 'Failed')
        }
      }
    )
  }

  userDetails() {
    const status = 'active';
    this.userList = [];
    this.selectedItems = [];
    if (this.workflowGroup.value.companyId != "") {
      this.hradminService.getUserDetails(this.workflowGroup.value.companyId, status).subscribe(res => {
        if (res.status === "Success") {
          this.userList= res.respObject;
        }
      })
    }
  }

    templtetList(){
    if (this.workflowGroup.value.companyId && this.workflowGroup.value.categoryCompanyId) {
      this.hradminService.templetList(this.workflowGroup.value.companyId, this.workflowGroup.value.categoryCompanyId).subscribe(res => {
        this.templets = res.respObject?.filter((data: { recordState: string; status: string; }) => data.recordState === "ACTIVE" && data.status === "APPROVED");
      })
    }
  }

  inputFormatValue(value: Templet){
    if (value.templateName) 
      return value.templateName;
    return value;
  }

  resultFormatValue(value: any){
    value.templateName;
  }
  formatter = (result: any) => result.templateName;
  clickedItem:string = '';
  selectedItem(item: { item: any; }){
    this.clickedItem=item.item;
  }

  selectCompany() {
    this.categoryList(this.workflowGroup.value.companyId);
    this.templtetList();
    this.userDetails();
  }
 
  categoryList(companyId: any) {
    const status = 'active';
    if (companyId && companyId != "") { 
      this.hradminService.categoryList(companyId, status).subscribe(res => {
        this.categoryCompanyList = res.respObject;
        console.log(this.categoryCompanyList)
      })
    }
  }
 
  selectCategory(categoryEvent: any) {
    if (this.workflowGroup.value.companyId == '') {
      this.categoryCompanyList = [];
      this.notify.showInfo('Please select company!!', 'Info')
    }
    this.templtetList();
  }

  clickTemplet(){
    if (this.workflowGroup.value.categoryCompanyId == '') {
      this.templets = [];
      this.notify.showInfo('Please select Category!!', 'Info')
      return;
    } 
  }

  selecTemplet(event: any){
    if (this.workflowGroup.value.templateId != '') {
      this.hradminService.getWorkflowData(this.workflowGroup.value.templateId).subscribe(
        res => {
          if (res.status == 'Success') {
            this.workflowDetailsData = res.respObject[0];
            this.groupData.patchValue({
              issuerId: this.getUserDetails(this.workflowDetailsData.issuerIds),
              reviewerId: this.getUserDetails(this.workflowDetailsData.reviewerIds),
              approverId: this.getUserDetails(this.workflowDetailsData.approverIds)
            });
          }
        },
        (error) => {
          console.log('error: ' + error);
          if (error?.error?.status === 'Error') {
            this.notify.showError(error?.error?.message, 'Failed')
          }
        }
      )
    }
  }

  getUserDetails(users: any){
    let data: any = [];
    users.forEach((user: any) => {console.log(user)
      data.push({
        userId: this.userList.filter((data: { emailId: any; }) => data.emailId === user)[0]?.userId,
        emailId: this.userList.filter((data: { emailId: any; }) => data.emailId === user)[0]?.emailId
      });console.log(this.userList.filter((data: { emailId: any; }) => data.emailId === user)[0]?.emailId)
    });
    return data;
  }

  getDetails(){
    if (this.groupData.value.issuerId != '') {
      !this.workflowGroup.value.issuerIds ? this.workflowGroup.value.issuerIds = [] : this.workflowGroup.value.issuerIds;
      for(let data of this.groupData.value.issuerId){
        let emailId = data.emailId?data.emailId:data;
        !this.workflowGroup.value.issuerIds.includes(emailId) ? 
        this.workflowGroup.value.issuerIds.push(emailId) : this.workflowGroup.value.issuerIds;
      }
    }
    if (this.groupData.value.approverId != '') {
      !this.workflowGroup.value.approverIds ? this.workflowGroup.value.approverIds = [] : this.workflowGroup.value.approverIds;
      for(let data of this.groupData.value.approverId){
        let emailId = data.emailId?data.emailId:data;
        !this.workflowGroup.value.approverIds.includes(emailId) ? 
        this.workflowGroup.value.approverIds.push(emailId) : this.workflowGroup.value.approverIds;
      }
    }
    if (this.groupData.value.reviewerId != '') {
      !this.workflowGroup.value.reviewerIds ? this.workflowGroup.value.reviewerIds = [] : this.workflowGroup.value.reviewerIds;
      for(let data of this.groupData.value.reviewerId){
        let emailId = data.emailId?data.emailId:data;
        !this.workflowGroup.value.reviewerIds.includes(emailId) ? 
        this.workflowGroup.value.reviewerIds.push(emailId) : this.workflowGroup.value.reviewerIds;
      }
    }
  }

  validate() {
    this.getDetails();
    this.request = ({
      approverIds: this.workflowGroup.value.approverIds,
      issuerIds: this.workflowGroup.value.issuerIds,
      reviewerIds: this.workflowGroup.value.reviewerIds,
      categoryCompanyId: this.workflowGroup.value.categoryCompanyId,
      templateId: this.workflowGroup.value.templateId
    })
  }

  validateUpdateData() {
    this.getDetails();
    this.request = ({
      approverIds: this.workflowGroup.value.approverIds,
      issuerIds: this.workflowGroup.value.issuerIds,
      reviewerIds: this.workflowGroup.value.reviewerIds,
      categoryCompanyId: this.workflowGroup.value.categoryCompanyId,
      templateId: this.workflowGroup.value.templateId
    })
  }

updateWorkflowData(){
  this.validateUpdateData();
    if (this.workflowGroup.invalid || this.groupData.invalid) {
      this.submitted = true;
      return;
    } else {
      console.log(this.request);
        this.hradminService.workflowDataUpdate(this.request).subscribe(
          res => {
            if (res.status == "Success") {
              this.notify.showSuccess('Updated successfully.', 'Success');
              this.router.navigate(['/hradmin/workflowList']);
            }
          },
          (error) =>{
            if (error?.error?.status === 'Error') {
              this.notify.showError(error?.error?.message, 'Failed')
            } else if (error?.status >= 400 && error?.status <= 422) {
              this.notify.showError(error?.error ? error?.error?.message : 'Some error occured.', 'Failed');
            } else {
              console.log('Internal Server Error')
              this.notify.showError('Some error occured.', 'Failed');
            }
          }
        )
    }
}
  workflowData() {
    this.validate();
    if (this.workflowGroup.invalid || this.groupData.invalid) {
      this.submitted = true;
      return;
    } else {
      this.hradminService.workflowData(this.request).subscribe(
        res => {
          if (res.status == "Success") {
            this.notify.showSuccess('Saved successfully.', 'Success');
            this.router.navigate(['/hradmin/workflowList']);
          }
        },
        (error) =>{
          if (error?.status >= 400 && error?.status <= 422) {
            this.notify.showError(error?.error ? error?.error?.message : 'Some error occured.', 'Failed');
          } else {
            console.log('Internal Server Error')
            this.notify.showError('Some error occured.', 'Failed');
          }
        }
      )
    }
  }
  get f() {
    return this.workflowGroup.controls;
  }
  get form() {
    return this.groupData.controls;
  }

  onItemSelect(item: any) {
    console.log(item);
    console.log(this.groupData.value.issuerId)
  }
  onSelectAll(items: any) {
    // console.log(items);
  }

  checkAccess(menu: any){
    return this.menuList.some((item: {
      edit: boolean; featureName: any; 
    }) => item.featureName.toLowerCase() === menu.toLowerCase() && item.edit);
  }

}

interface Templet {
  companyId: number;
  templateId: number;
  templateName: string;
}

interface WorkflowRequest{
  "approverIds": [
    "string"
  ],
  "categoryCompanyId": 0,
  "issuerIds": [
    "string"
  ],
  "reviewerIds": [
    "string"
  ],
  "templateId": 0
}
