<div class="wrap_body">
  <h4 class="title_m1">Workflow list</h4>
  <div class="row">
    <div class="col-sm-3">
      <select name="" id="" (change)="selectCompany($event)" [(ngModel)]="companyId" class="form-control">
        <option value="">Select Company</option>
        <option value="{{data.companyId}}" *ngFor="let data of companyList" >{{data.companyName}}</option>
      </select>
    </div>
    <div class="col-sm-3 offset-sm-4">
      <div class="search_m1">
        <input type="text" class="form-control" [(ngModel)]="term" placeholder="Search">
        <img src="../../../assets/images/Search.png" alt="">
      </div>
    </div>
    <div class="col-sm-2 text-right" *ngIf="checkAccess('Workflow')">
      <a (click)="navigateToWorkflow()" class="btn_m1 text-white" >
        <span>Workflow</span>
      </a>
    </div>
    
    <div class="col-sm-12 mt-3">
      <div class="table-responsive">
        <table class="table  table-striped table_m1">
          <thead>
            <tr>
              <th>Company Name</th>
              <th scope="col">Template Name</th>
              <th>Reviewer</th>
              <th scope="col">Approver</th>
              <th scope="col">Issuer</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let user of workflowData | filter:term;">
              <td>{{companyName}}</td>
              <td>{{user.templateName}}</td>
              <td>{{user.reviewerIds[0]}} {{user.reviewerIds[1]}} {{user.reviewerIds[2]}} {{user.reviewerIds[3]}}</td>
              <td>{{user.approverIds[0]}} {{user.approverIds[1]}} {{user.approverIds[2]}} {{user.approverIds[3]}}</td>
              <td>{{user.issuerIds[0]}} {{user.issuerIds[1]}} {{user.issuerIds[2]}} {{user.issuerIds[3]}}</td>
              <td class="text-center"><a class="link-primary" routerLink="/hradmin/workflow/{{user.templateId}}" ><i class="fas fa-edit"></i></a></td>
            </tr>
            <tr *ngIf="workflowData.length === 0"><td colspan="6" class="text-center">No data found</td></tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>