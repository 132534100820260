import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { error } from 'console';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { NotifyService } from 'src/app/notify.service';
import { HrService } from 'src/app/pages/services/hr.service';
import { CandidateService } from '../services/candidate.service';

@Component({
  selector: 'app-upload-educational-documents',
  templateUrl: './upload-educational-documents.component.html',
  styleUrls: ['./upload-educational-documents.component.css']
})
export class UploadEducationalDocumentsComponent implements OnInit {

  // request!: Array<EducationalDocument>;
  request: any = [];
  public sscCertificate: any;
  public hscCertificate: any;
  public ugCertificate: any;
  public pgCertificate: any;
  public images: any = [];
  public sscCertifileName: any;
  public hscCertifileName: any;
  public ugCertifileName: any;
  public pgCertifileName: any;
  public templateCandidateId: any;
  public comapnylist: any = [];
  public company!: ProfessionalDocument;
  public disableFlag = false;
  public errorMessage: any;
  public errorFlag = false;
  otpModelFlag = false;
  otpVarificationMsg = 'OTP Verified';
  otpVarMsgFlag = false;
  uploadCategotyDetails: any;
  documents: any;
  docType: any;
  files: any;
  upload: any;
  status: boolean = false;
  modalRef!: BsModalRef;
  saveDocumentDetails: any = [];
  updateDocumentDetails: any = [];
  saveFlag: boolean = false;
  updateFlag: boolean = false;

  constructor(private fb: FormBuilder, private candidateService: CandidateService,
    private modalService: BsModalService, private notify: NotifyService, private router: Router,
    private hrService: HrService) {
    this.templateCandidateId = sessionStorage.getItem('templateCandidateId');
    this.aadhaarSign = sessionStorage.getItem('aadhaarSign')
    this.upload = sessionStorage.getItem('upload')
    this.documents = new FormGroup({
      templateLetterCandidateId: new FormControl(this.templateCandidateId),
      uploadCategotyDetails: new FormArray([
        new FormGroup({
          uploadCategory: new FormControl(this.docType),
          description: new FormControl(''),
          uploadDocumentId: new FormControl(''),
          fileNameList:  new FormArray([
            this.fb.group({
              file: new FormControl('')
            })
          ])
        })
      ])
    })
    this.files = new FormData();

  }
  @ViewChild('staticTabs')
  staticTabs!: TabsetComponent;
  public sscTag = false;
  public hscTag = false;
  public ugTag = false;
  public pgTag = false;
  public company1Tag = false;
  public company2Tag = false;
  public selectedValue: any;
  public documentName: any;
  public formgroup: any;
  public profesionalForm: any;
  public variblesData: any = [];
  public uploadResponseFlag = false;
  public submitted = false;
  public professionalFile: any = [];
  public professionalFileName:any;
  public eisgnFile:any;
  public eisgnFileName:any;
  public professionalImageArray: any = [];
  otp:any;
  aadhaarSign:any
  selectTab(tabId: number) {
    if (this.upload === 'false') {
      this.staticTabs.tabs[1].active = true;
    } else {
      this.staticTabs.tabs[tabId].active = true;
    }
  }

  afuConfig = {
    uploadAPI: {
      url: "https://2d9776e0-8f75-40aa-9a87-6ac7196737a2.mock.pstmn.io//v1/bulkupload/filetojson"
    },
    autoUpload: true,
    hideResetBtn: true,
    //theme: "dragNDrop",
    hideProgressBar: false,
    formatsAllowed: ".jpg,.png",
    replaceTexts: {
      selectFileBtn: 'Choose file',
      resetBtn: 'Reset',
      uploadBtn: 'Choose file',
      dragNDropBox: 'Drag and Drop',
      attachPinBtn: 'Attach Files...',
      afterUploadMsg_success: 'Successfully Uploaded!',
      afterUploadMsg_error: 'Upload Failed !',
      sizeLimit: 'Size Limit'
    }
  };
  uploaderOptions = {
    allowedExtensions: ['image/jpeg', 'image/png']
    // Other configuration options
  };
  
  ngOnInit(): void {
    if (!this.templateCandidateId) {
      sessionStorage.clear();
      this.router.navigate(['/login'])
    }
    this.candidateService.candidatedeatils(this.templateCandidateId).subscribe(
      res => {
        const candidateDeatils = res.respObject[0];
        sessionStorage.setItem('status', candidateDeatils?.reviewStatus);
        if (candidateDeatils?.reviewStatus === 'REJECTED') {
          this.router.navigate(['/candidate/details/candidateAppointment/'+ this.templateCandidateId]);
        }
      }
    )
    
    this.loadData();
    if (this.upload === 'false') {
      setTimeout(() => {
        this.staticTabs.tabs[1].active = true;
      }, 1000);
    }
  }

  get items(): FormArray {
    return this.documents.get('uploadCategotyDetails') as FormArray;
  }

  itemsObject() {
    const data = this.documents.get('uploadCategotyDetails').value = [];
  }

  loadData(){
    this.candidateService.getUploadDocs(this.templateCandidateId).subscribe(
      res => {
        if (res.status === 'Success') {
          const canRes = res.respObject
          if (canRes[0]?.uploadCategotyDetails?.length >= 1) {
            if (canRes[0]?.uploadCategotyDetails[0]?.uploadDocumentId === '') {
              this.removeForm(0);
            }
          }
          this.items.clear();
          for(let data of canRes){
            data?.uploadCategotyDetails?.forEach((feature: {description: any; uploadCategory: any; fileNameList: any; uploadDocumentId: any}, index:any ) => {
              const featureValue = this.fb.group({
                  description: new FormControl(feature.description),
                  uploadCategory: new FormControl(feature.uploadCategory),
                  uploadDocumentId: new FormControl(feature.uploadDocumentId),
                  fileNameList: this.fb.array([this.fb.group(feature.fileNameList[0])])
              })
              this.items.push(featureValue)
            });
          }
          this.saveFlag = false;
          this.updateFlag = false;
        }
      },
      (error) => {
        if (error?.error?.status === 'Error') {
          this.notify.showError(error?.error?.message, 'Failed')
        }
      }
    )
  }

  addForm(){
    if (!this.docType || this.docType === '') {
      this.notify.showInfo('Please select document type', 'Info');
      return;
    }
    this.documents.controls['uploadCategotyDetails'].push(
      this.fb.group({
        uploadCategory: [this.docType],
        description: [''],
        uploadDocumentId: [''],
        fileNameList:  this.fb.array([
          this.fb.group({
            file: ['']
          })
        ])
      })
    )
  }
  removeForm(index: any){
    const control = this.documents.controls['uploadCategotyDetails'].controls[index];
    if (control.value.uploadDocumentId != '') {
      this.deleteDocs(control.value.uploadDocumentId, index);
    } else {
      this.documents.controls['uploadCategotyDetails'].removeAt(index);
    }
  }

  deleteDocs(id: any, index: any) {
    this.candidateService.deleteUploadDocs(id).subscribe(
      res => {
        if (res.status === 'Success') {
          this.documents.controls['uploadCategotyDetails'].removeAt(index);
        }
      },
      (error) => {
        if (error?.error?.status === 'Error') {
          this.notify.showError(error?.error?.message, 'Failed')
        }
      }
    )
  }
  documentDetails(id: any){
    console.log(this.documents.value)
    this.saveDocumentDetails = [];
    this.updateDocumentDetails = [];
    for (let data of this.documents.value.uploadCategotyDetails) {
      if (data.uploadDocumentId && data.uploadDocumentId != '') {
        this.updateDocumentDetails.push({
          description: data.description,
          uploadDocumentsId: data.uploadDocumentId
        })
      } else {
        if (data.description && data.description != '' && data.fileNameList[0]?.file && data.fileNameList[0].file != '') {
          this.saveDocumentDetails.push(data);
        } else if (data.description != '' && data.fileNameList[0].file === '') {
          this.notify.showError('Please upload document.', 'Error');
          return;
        } else if(data.description === '' && data.fileNameList[0].file != ''){
          this.notify.showError('Please add description.', 'Error');
          return;
        }
      }
    }
    const saveData = {
      templateLetterCandidateId: this.templateCandidateId,
      uploadCategotyDetails: this.saveDocumentDetails
    }
    if (!(this.updateDocumentDetails.length > 0 || this.saveDocumentDetails.length > 0)) {
      this.notify.showInfo('Please upload the details.', 'Info');
      return;
    }
    saveData?.uploadCategotyDetails.filter((data: { description: string; fileNameList: { file: string; }[]; }, index: any) => 
        data.description === '' || data.fileNameList[0].file === '' ? saveData.uploadCategotyDetails.splice(index, 1) : data)
    let data = saveData?.uploadCategotyDetails.filter((data: { description: string; fileNameList: { file: string; }[]; }) => data.description != '' || data.fileNameList[0].file != '')
    if (data && data.length > 0) {
      if (this.files.get('uploadDetails') === null) {
        this.files.append('uploadDetails',JSON.stringify(saveData));
      }
      if (saveData.uploadCategotyDetails.length > 0) {
        this.saveDocuments(id);
      }
    } else {
      this.saveFlag = true;
    }
    this.updateDocuments(id, this.updateDocumentDetails);
    setTimeout(() => {
      if (this.saveFlag || this.updateFlag) {
        this.staticTabs.tabs[id].active = true;
        this.documents.value.uploadCategotyDetails = [];
        this.documents.controls['uploadCategotyDetails'].reset();
        this.loadData();
        this.files = new FormData();
      }
    }, 1000);
  }

  saveDocuments(id: any){
    this.candidateService.saveDetails(this.files).subscribe(
      res => {
        if (res.status == "Success") {
          this.notify.showSuccess('Saved successfully.', res.status);
          this.saveFlag = true;
        }
      },
      (error) => {
        this.saveFlag = false;
        if (error?.error?.status == 'Error') {
          console.log('error: ->' + error?.error)
          this.notify.showError(error?.error?.message, 'Failed');
        } else {
          this.notify.showError('Failed to save data.', 'Failed');
        }
      }
    )
  }

  updateDocuments(id: any, data: any){
    this.candidateService.uploadDetails(data).subscribe(
      res => {
        if (res.status == "Success") {
          this.notify.showSuccess('Saved successfully.', res.status);
          this.updateFlag = true;
        }
      },
      (error) => {
        this.updateFlag = false;
        if (error?.error?.status == 'Error') {
          console.log('error: ->' + error?.error)
          this.notify.showError(error?.error?.message, 'Failed');
        } else {
          this.notify.showError('Failed to save data.', 'Failed');
        }
      }
    )
  }

  fileSelected(event: any, name: any) {
    console.log('name: ' + name)

    const uploadImageData = new FormData();
    uploadImageData.append(
      "files",
      event.target.files[0],
      event.target.files[0].name
    );
    if(name=='ESIGN'){
      const fileType: string = event.target.files[0]?.type;
      if (!(fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'image/png')) {
        this.notify.showInfo('Invalid file format! Please provide JPG/JPEG/PNG file.', 'Info');
        this.uploadResponseFlag = false;
        return;
      }
      this.eisgnFileName=event.target.files[0].name
      this.eisgnFile=event.target.files[0];
    }

    sessionStorage.setItem("file", uploadImageData.toString());
  }
  fileUpload(event: any, index: any) {
    const fileType: string = event.target.files[0]?.type;
    if (!(fileType === 'application/pdf' || fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'image/png')) {
      this.notify.showInfo('Invalid file format! Please provide PDF/JPG/JPEG/PNG file.', 'Info');
      this.uploadResponseFlag = false;
      return;
    }
    this.files.append('files', event.target.files[0], event.target.files[0].name);

    this.documents.controls['uploadCategotyDetails'].controls[index].controls['fileNameList'].controls[0].controls['file'].setValue(event.target.files[0].name)

  }

  documentsType(event: any){
    this.docType = event?.target?.value ? event?.target?.value : '';
    // !this.documents.controls['uploadCategotyDetails'].controls[0].get('uploadCategory').value ? 
    // this.documents.controls['uploadCategotyDetails'].controls[0].get('uploadCategory').setValue(this.docType) : '';  
  }

  addDetails(){
    this.uploadCategotyDetails.push(this.documents);
    console.log(this.uploadCategotyDetails.value)
  }

  docUpload(event: any) {
    const fileType: string = event.target?.files[0]?.type;
    if (event.status == "200") {
      this.uploadResponseFlag = true;
      this.variblesData = event.body.fileData;
    }
    // sessionStorage.setItem("file", event.target.files[0]);
  }
  qualification(event: any) {
    console.log(event.currentTarget.value);
    this.selectedValue = event.currentTarget.value;

  }
  
  backToOffer() {
    if (this.modalRef) {
      this.modalRef.hide()
    }
    this.router.navigate(['/candidate/details/candidateAppointment/'+this.templateCandidateId]);
  }

  backPopup(template: any){
    let data = this.items.value.filter((data: { description: string; fileNameList: { file: string; }[]; }) => data.description != '' || data.fileNameList[0].file != '')
    if (data && data.length === 0) {
      this.backToOffer()
      return;
    }
    const initialState = {

    };
    this.modalRef = this.modalService.show(template,
      {
        class: 'modal-dialog-centered model_1', initialState,
      },
    ); 
    
  }

  continueToEsign(){
    // if (this.upload === 'false' && this.saveFlag && this.updateFlag) {
    //   this.staticTabs.tabs[1].active = true;
    // }
    if (this.upload === 'false') {
      this.staticTabs.tabs[1].active = true;
    }
  }

  backToUpload() {
    if (this.upload === 'false') {
      this.router.navigate(['/candidate/details/candidateAppointment/'+this.templateCandidateId]);
    } else {
      this.staticTabs.tabs[0].active = true;
    }
  }
  
  documentSelect(event: any) {
    console.log(event.currentTarget.value);
    this.documentName = event.currentTarget.value;
  }

  selectCompany(companyName: any) {
    console.log(companyName)
    if (this.comapnylist.some((company: { companyName: any; }) => company.companyName === companyName)) {
      const companyDetails = this.comapnylist.filter((company: { companyName: any; }) => company.companyName === companyName)[0]
      this.profesionalForm.patchValue({
        companyName: this.selectedValue,
        fromYear: companyDetails?.fromYear,
        toYear: companyDetails.toYear,
        designation: companyDetails.designation,
        filename: companyDetails.filename,
      });
    }
  }

  addImages(){
    let formData = new FormData();
    for(let company of this.comapnylist){
      const data = this.professionalFile.filter((img: { name: any; }) => img.name === company.filename);
      formData.append('files', data[0], data[0].name);
    }
    return formData;
  }

  addCompany() {
    if (this.comapnylist && this.comapnylist.length === 0) {
      this.comapnylist.push(this.profesionalForm.value);
    }
    if (this.profesionalForm.value.companyName && !this.comapnylist.some((com: { companyName: any; }) => com.companyName === this.profesionalForm.value.companyName)) {
      if (this.profesionalForm.value.companyName != '') {
        this.comapnylist.push(this.profesionalForm.value);
      }
    }
    this.profesionalForm.reset();
  }

  esignUpload(){
    if (this.aadhaarSign === 'true') {
      this.esignXml();
      // this.router.navigate([`hradmin/esign/${this.templateCandidateId}`]);
    }else {
      if(this.eisgnFile){
        let formData = new FormData();
        formData.append('file', this.eisgnFile,this.eisgnFile.name);
        let formdata2= new FormData();
        let candiateName:any=sessionStorage.getItem('candidateName');
        formdata2.append('candidateTextSign',candiateName?.toString());
        this.candidateService.candidateEsignService(this.templateCandidateId, candiateName, formData).subscribe(
          res=>{
            if (res.status == "Success") {
              this.notify.showSuccess('Uploaded successfully.', res.status);
              let aadhaarSign = sessionStorage.getItem('aadhaarSign');
              if (aadhaarSign != 'true') {
                this.generateOtp();
              } else {
                this.esignXml();
              }
            }
          },
          (error) => {
            console.log('error: ', error);
            if (error?.error?.status === 'Error') {
              this.notify.showError(error?.error?.message, 'Failed');
            } else {
              this.notify.showError('Esign upload failed!', 'Failed');
            }
            
          }
        )
      } else {
        this.notify.showInfo('Please select image', 'Info');
      }
    }
  }

  esignXml() {
    this.candidateService.getEsignXml(this.templateCandidateId).subscribe(
      res => {
        if (res.status == "Success") {
          this.submitForm(res.respObject);
          // this.hrService.sendXml(this.xml); ccc
          // this.router.navigate(['hradmin/esign']);
        }
      },
      (error) => {
        console.log('error: ', error)
        if (error?.error?.status === 'Error') {
          this.notify.showError(error?.error?.message, 'Failed')
        } else {
          this.notify.showError('Esign failed!', 'Failed');
        }
      }
    )
  }

  generateOtp() {
    let request: any = {
      objectId: this.templateCandidateId,
      txnObject: "candidate"
    }
    this.candidateService.generateOtpService(request).subscribe(
      res => {
        if (res.status == "Success") {
          if (res.respObject) {
            sessionStorage.setItem('trnxKey', res.respObject);
            this.notify.showSuccess('OTP has send to your registered email.', res.status);
            this.otpModelFlag = true;
          }
        }
      },
        (error) => {
          console.log('error: ', error)
          if (error?.error?.status === 'Error') {
            this.notify.showError(error?.error?.message, 'Failed')
          } else {
            this.notify.showError('Failed to send OTP to your registered email.', 'Failed');
          }
        }
    )
  }

  verifyOtp(){
    console.log(this.otp)
    let request: any = {
      otp: this.otp,
      transcationUniqueId: sessionStorage.getItem('trnxKey'),
      txnObject: "candidate"
    }
    this.candidateService.verifyOtpService(request).subscribe(
      res => {
        if (res.status == "Success") {
          if (res.respObject) {
            this.notify.showSuccess(this.otpVarificationMsg, res.status);
            this.otpVarMsgFlag = true;
            console.log('Otp varified!')
            sessionStorage.clear();
            this.router.navigate(['/esign'])
          }
        }
      },
      (error) => {
        console.log('error: ', error)
        if (error?.error?.status === 'Error') {
          this.notify.showError(error?.error?.message, 'Failed');
        } else {
          this.notify.showError('Otp varification failed!', 'Failed');
        }
      }
    )
  }

  otpVarificationCancel(){
    this.otpModelFlag = false;
  }

  submitForm(xml: any) {
    const formData = new FormData();
    formData.append('msg', xml);
    const url = 'https://pregw.esign.egov-nsdl.com/nsdl-esp/authenticate/esign-doc/';
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = url;
  
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = 'msg';
    input.value = xml
    form.appendChild(input);
  
    const newWindow = window.open('', '_blank');
    if (newWindow) {
      newWindow.document.body.appendChild(form);
      form.submit();
    }
  }

}

interface EducationalDocument {
  instituteName: any;
  YearOfPass: any;
  percent: any;
  filename: any;
  templateCandidateId: any;
  qualification: any;
}
interface ProfessionalDocument {
  companyName: any;
  fromYear: any;
  toYear: any;
  designation: any;
  filename: any;
}
