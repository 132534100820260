import { Component, OnInit } from '@angular/core';
import { TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HradminService } from '../services/hradmin.service';
import { NotifyService } from 'src/app/notify.service';
@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.css']
})
export class CompanyListComponent implements OnInit {
  public companyDetail: any = [];
  public companyDetails: any;
  modalRef!: BsModalRef;
  offer_letter!: BsModalRef;
  public address: any;
  menuList: any;
  term: string = '';
  recordCount: number = 10;
  pageConfig = {
    id: 'pagination',
    itemsPerPage:this.recordCount,
    currentPage: 1,
  };
  constructor(private modalService: BsModalService, private hradminService: HradminService,
    private notify: NotifyService) {
    var menu: any = sessionStorage.getItem('menuList');
    this.menuList = JSON.parse(menu);
   }

  ngOnInit(): void {
    this.getAllcompanyDetails();
  }
  getAllcompanyDetails() {
    this.hradminService.getAllcompanyDeatls().subscribe(
      res => {
        if (res.status == "Success") {
          this.companyDetail = res.respObject;
        }
      }
    )
  }
  openModal(template: TemplateRef<any>) {
    const initialState = {

    };
    this.modalRef = this.modalService.show(template,
      {
        class: 'modal-dialog-centered model_1', initialState,
      },
    );
  };

  offer_letter_link(template1: TemplateRef<any>) {
    const initialState = {

    };
    this.offer_letter = this.modalService.show(template1,
      {
        class: 'modal-dialog-centered model_1 modal-lg', initialState,
      },
    );
  }

  getAddress(address: any) {
    var addrs = !!address ? 
    (!!address.hno ? address.hno : '') + '' +
    (!!address.location ? ' ' + address.location : '') + '' +
    (!!address.city ? ' ' + address.city : '') + '' +
    (!!address.stateOfCountry ? ' ' + address.stateOfCountry : '') + '' +
    (!!address.country ? ' ' + address.country : '') + '' + 
    (!!address.pincode ? ' ' + address.pincode : '') 
    : ''; 
    return addrs;
  }

  checkAccess(menu: any){
    return this.menuList.some((item: {
      edit: boolean; featureName: any; 
    }) => item.featureName.toLowerCase() === menu.toLowerCase() && item.edit);
    
  }
  changeStatus(event: any, companyId: any) {
    this.hradminService.deleteCompany(companyId, event?.currentTarget?.checked ? 'ACTIVE' : 'INACTIVE').subscribe(
      res => {
        this.getAllcompanyDetails();
      },
      (error) => {
        this.getAllcompanyDetails();
        if (error?.error?.status === 'Error') {
          this.notify.showError(error?.error?.message, 'Failed')
        } else {
          this.notify.showError('Failed to Active/Inactive company.', 'Error')
        }
      }
    )
  }
}
