import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotifyService } from 'src/app/notify.service';
import { LoginService } from '../services/login.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public formgroup:any;
  public submitted=false;
  errorMessage="Invallid Credentials Please Try again";
  rememberMe: boolean = true;
  showPassword: boolean = false;
  show = false;
  password = 'password'
  constructor(private router:Router,private fb:FormBuilder, private loginService: LoginService,
    private notify: NotifyService, private cookie: CookieService) {
      // sessionStorage.clear();
    this.formgroup =fb.group({
      loginId:['',[Validators.required,Validators.pattern('')]],
      password:['',[Validators.required,Validators.pattern('')]],
      emailId:['']
    })
   }

  ngOnInit(): void {
    const rememberedCredentials = this.getRememberedCredentials();
    // if (rememberedCredentials.loginId) {
    //   this.formgroup.get('loginId').setValue(rememberedCredentials.loginId);
    // }
    // if (rememberedCredentials.password) {
    //   this.formgroup.get('password').setValue(rememberedCredentials.password);
    // }
    const username = this.cookie.get('username');
    const password = this.cookie.get('password');
    if (this.cookie.check('username')) {
      if (this.formgroup.value.loginId === '') {
        this.formgroup.get('loginId').setValue(username);
      }
    }
    if (this.cookie.check('password')) {
      if (this.formgroup.value.loginId === '') {
        this.formgroup.get('loginId').setValue(password);
      }
    }
    if(!(this.cookie.check('username') && this.cookie.check('password'))){
      this.rememberMe = false;
    }
  }

  login(){
    if (this.formgroup.invalid) {
      this.submitted= true;
      return;
    } else {
      sessionStorage.clear();
      this.loginService.login(this.formgroup.value).subscribe(
        res => {
          if (res.status == "Success") {
            this.notify.showSuccess('Login successful.', res.status);
            const loginDetails = res.respObject;
            const sessionData: any = {
              userId: loginDetails.userId,
              loginId: loginDetails.loginId,
              companyId: loginDetails.companyId,
              companyName: loginDetails?.companyName,
              emailId: loginDetails.emailId,
              roleId: loginDetails?.roleDto?.roleId,
              roleName: loginDetails?.roleDto?.name
            }
            let authToken=loginDetails?.token;
            sessionStorage.setItem('token',authToken);
            sessionStorage.setItem('cId',sessionData.companyId);
            sessionStorage.setItem('loginDetails', JSON.stringify(sessionData));
            var menuList = [];
            if (this.rememberMe) {
              this.storeCredentialsInLocalStorage(this.formgroup.value.loginId, this.formgroup.value.password);
            }
            const services = loginDetails?.services;
            sessionStorage.setItem('services', JSON.stringify(services));
            menuList = loginDetails.featureList;
            sessionStorage.setItem('menuList', JSON.stringify(menuList))
            if (loginDetails.isDefaultPwd) {
              this.router.navigate([`/updatePwd/${this.formgroup.value.loginId}`]);
            } 
            this.router.navigate([`/dashboard/dashboard`])
            .then(() => {
              // window.location.reload();
            })
          } else if(res.status === 'Error') {
            this.notify.showError(res?.message, 'Error');
          }
        },
        (error) => {
          if (error?.error?.status === 'Error') {
            this.notify.showError(error?.error?.message, 'Failed')
          } else if (error.status == 500) {
            console.log('Invalid credential! Login failed')
            this.notify.showError('Login failed.', 'Failed');
          } else {
            this.notify.showError('Login failed.', 'Failed');
          }
        }
      )
    
  }
  
  }
  get f(){
    return this.formgroup.controls;
  }

  storeCredentialsInLocalStorage(loginId: string, password: string) {
    localStorage.setItem('rememberedEmail', loginId);
    localStorage.setItem('rememberedPassword', password);
    if (!this.cookie.check('username')) {
      this.cookie.delete('username');
      this.cookie.set('username', loginId);
    }
    if (!this.cookie.check('password')) {
      this.cookie.delete('password');
      this.cookie.set('password', password);
    }
  }

  // Method to retrieve login credentials from local storage
  getRememberedCredentials() {
    const loginId = localStorage.getItem('rememberedEmail');
    const password = localStorage.getItem('rememberedPassword');
    return { loginId, password };
  }
  togglePassword(event?: any){
    // this.showPassword = !this.showPassword;
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }
}
