import { HttpClient } from '@angular/common/http';
import { utf8Encode } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { TemplateRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { from, timestamp } from 'rxjs';
import { NotifyService } from 'src/app/notify.service';
import { environment } from 'src/environments/environment';
import { HradminService } from '../services/hradmin.service';
import { EncryptDecrypt } from 'src/app/encrypt-decrypt';
import { PdfServiceService } from 'src/app/pdf-service.service';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.css']
})
export class TemplateComponent implements OnInit {
  public templateList: any = [];
  public temlateData: any = [];
  public approvedTag = false;
  public pendingTag = true;
  public rejectedTag = false;
  public butoonsTag = true;
  public message = "";
  public formGroup: any;
  public buttonName: any;
  public templateDta: any = {
    status: ""
  };
  public templateId = "";
  sessionData: any;
  companyId: any;
  modalRef!: BsModalRef;
  reject_Ref!: BsModalRef;
  offer_letter!: BsModalRef;
  public templateName = "";
  public appointMentStatusObejct: any = {
    remarks: "",
    status: "",
    statusId: '',
    templateId: ''
  }
  companyList: any;
  menuList: any;
  fileUrl: any;
  baseUrl: any;
  isDisabled: boolean = false;
  term: string = '';
  companyName: any;
  pdfSrc: any;
  token: any;
  constructor(private modalService: BsModalService,
    private http: HttpClient, private hradminService: HradminService, private formbuilder: FormBuilder,
    private router: Router, private notify: NotifyService, private pdfService: PdfServiceService,private encDec: EncryptDecrypt) {
    var data: any = sessionStorage.getItem('loginDetails');
    this.sessionData = JSON.parse(data);
    this.baseUrl = environment.url;
    this.formGroup = formbuilder.group({
      remarks: ''
    })
    this.companyId = this.sessionData.roleName != 'Super Admin' ? this.sessionData.companyId : '';
    var menu: any = sessionStorage.getItem('menuList');
    this.menuList = JSON.parse(menu);
  }

  ngOnInit(): void {
    this.token = sessionStorage.getItem('token');
    this.getCompanyList();
    this.getTemplateListByCompanyId();
  }

  encrypt(value: any): any {
    return this.encDec.encrypt(value);
  }

  getTemplateListByCompanyId() {
    const status = null;
    if (this.companyId && this.companyId != '') {
      this.hradminService.getAllTemplatesByCId(this.companyId, status).subscribe(
        res => {
          this.temlateData = res.respObject;
        },
        (error) => {
         console.log(error.message);
         if (error?.error?.status === 'Error') {
          this.notify.showError(error?.error?.message, 'Failed')
         }
        }
      )
    }
  }

  getCompanyList(){
    this.hradminService.getAllcompanyDeatls().subscribe(
      res => {
        if (res.status == 'Success') {
          this.companyList = res.respObject;
          if (this.companyId === '') {
            this.companyId = this.companyList[0].companyId;
            this.getTemplateListByCompanyId();
          }
        }
      },
      (error) => {
        console.log('error: ' + error)
        if (error?.error?.status === 'Error') {
          this.notify.showError(error?.error?.message, 'Failed')
        }
      }
    )
  }
  openModal(template: any, data: any, event: any) {
    
    this.buttonName = event.target.value;
    this.templateDta = data;
    this.templateId = data.templateId;
    const initialState = {

    };
    this.modalRef = this.modalService.show(template,
      {
        class: 'modal-dialog-centered model_1', initialState,
      },
    );

  }
  submit() {
    
    if (this.buttonName == "APPROVED") {
      this.modalRef.hide();
    } else {
      this.reject_Ref.hide();
    }
    this.appointMentStatusObejct.templateId = this.templateId;
    this.appointMentStatusObejct.status = this.buttonName;
    this.appointMentStatusObejct.remarks = this.formGroup.value.remarks;
    this.hradminService.updateTemplateStatus(this.appointMentStatusObejct).subscribe(
      res => {
        console.log(res);
        if (res.status == "Success") {
          this.notify.showSuccess('Data saved successfully.', 'Success')
          this.getTemplateListByCompanyId();
          this.formGroup.reset();
        }
      },
      (error) => {
       console.log(error.message);
       if (error?.error?.status === 'Error') {
        this.notify.showError(error?.error?.message, 'Failed')
       }
      }
    )

  }

  loadPdf(fileUrl: any){
    this.pdfService.getPdfWithToken(fileUrl).subscribe((pdfBlob) => {
      this.pdfSrc = window.URL.createObjectURL(pdfBlob);
    });
  }
  selectCompany(event?: any){
    if (event && event.currentTarget.value) {
      this.companyId = event.currentTarget.value;
    }
    this.companyName = this.companyList.filter((data: { companyId: number; }) => data.companyId === Number(this.companyId))[0]?.companyName;
    this.getTemplateListByCompanyId();
  }

  redirect(status: any, templateId: any){
    status != 'APPROVED' ? this.router.navigate([`/hradmin/variable-mapping`, templateId]) : 
    this.notify.showInfo('Template already approved! can not edit.', 'Info');
  }

  signatureDetails(data: any){
    return data === true ? 'Aadhaar Signature' : 'Image Signature';
  }

  reject_model(template1: TemplateRef<any>, data: any, event: any) {
    
    this.buttonName = event.target.value;
    this.templateDta = data;
    this.templateId = data.templateId;
    const initialState = {

    };
    // this.modalRef = this.modalService.show(template);
    this.reject_Ref = this.modalService.show(template1,
      {
        class: 'modal-dialog-centered model_1', initialState,
      },
    );
  }
  downloadFile(id: any) {
    const url = this.baseUrl + '/esign/template/templatefilePdf/download/' + this.encrypt(id);
    const timestamp = new Date().getTime();
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/octet-stream', // Adjust the content type as needed
        'Authorization': `Bearer ${this.token}`
      }
    })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `template-${timestamp}.pdf`; // Specify the desired file name
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }
  offer_letter_link(template3: any, data: any) {
    
    this.fileUrl = this.baseUrl+ '/esign' + data?.fileUrl
    console.log(this.fileUrl)
    this.templateName = data.templateName;
    const initialState = {

    };
    this.loadPdf(this.fileUrl);
    this.offer_letter = this.modalService.show(template3,
      {
        class: 'modal-dialog-centered model_1 modal-lg', initialState,
      },
    );
  }
  cancel() {
    this.offer_letter.hide();
  }
  checkAccess(menu: any){
    return this.menuList.some((item: {
      edit: boolean; featureName: any; 
    }) => item.featureName.toLowerCase() === menu.toLowerCase() && item.edit);
  }
  changeStatus(event: any, templateId: any) {
    this.hradminService.templateStatus(templateId, event.currentTarget.checked ? 'ACTIVE' : 'INACTIVE').subscribe(
      res => {
        this.getTemplateListByCompanyId();
      },
      (error) => {
        if (error?.error?.status === 'Error') {
          this.notify.showError(error?.error?.message, 'Failed')
        } else {
          this.notify.showError('Failed Active/InActive template', 'Error');
        }
        this.getTemplateListByCompanyId();
      }
    )
  }
}

