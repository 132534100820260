import { Component, OnInit } from '@angular/core';
import { HradminService } from '../services/hradmin.service';
import { NotifyService } from 'src/app/notify.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.css']
})
export class CategoryListComponent implements OnInit {
  sessionData: any;
  category: any;
  term: string = '';
  menuList: any;
  companyList: any;
  companyId: any;

  constructor(private router: Router, private hradminservice: HradminService,
    private notify: NotifyService) { 
    var data: any= sessionStorage.getItem('loginDetails');
    this.sessionData = JSON.parse(data);
    var menu: any = sessionStorage.getItem('menuList');
    this.menuList = JSON.parse(menu);
    this.companyId = this.sessionData.roleName != "Super Admin" ? this.sessionData.companyId : '';
  }

  ngOnInit(): void {
    this.getCompanyList();
    this.viewCategorys();
  }

  viewCategorys() {
    const status = null;
    if (this.companyId) {
      this.hradminservice.getAllcategorys(this.companyId, status).subscribe(
        res => {
          if (res.status == "Success") {
            this.category = res.respObject;
          }
        }
      )
    }
  }

  editCategory(id: any) {
    const data = this.category.filter((data: { id: any; }) => data.id === id)[0];
  }

  deleteCategory(id: any) {
    this.hradminservice.deleteCategory(id).subscribe(
      res => {
        if (res.status == 'Success') {
          this.notify.showSuccess('Category deleted successfully.', 'Success');
          this.viewCategorys();
        }
      },
      (error) => {
        console.log('error: ' + error)
        if (error?.error?.status === 'Error') {
          this.notify.showError(error?.error?.message, 'Failed')
        } else {
          this.notify.showError('Category failed deleted.', 'Error');
        }
      }
    )
  }

  checkAccess(menu: any){
    return this.menuList.some((item: {
      edit: boolean; featureName: any; 
    }) => item.featureName.toLowerCase() === menu.toLowerCase() && item.edit);
    
  }

  changeStatus(event: any, categoryId: any) {
    this.hradminservice.updateCategoryStatus(categoryId, event.currentTarget.checked ? 'ACTIVE' : 'INACTIVE').subscribe(
      res => {
        this.viewCategorys();
      },
      (error) => {
        if (error?.error?.status === 'Error') {
          this.notify.showError(error?.error?.message, 'Failed')
        } else {
          this.notify.showError('Failed to Active/InActive Category.', 'Error');
        }
        this.viewCategorys();
      }
    )
  }

  getCompanyList(){
    this.hradminservice.getAllcompanyDeatls().subscribe(
      res => {
        if (res.status == 'Success') {
          this.companyList = res.respObject;
          if (this.companyId === '') {
            this.companyId = this.companyList[0].companyId;
            this.viewCategorys();
          }
        }
      },
      (error) => {
        console.log('error: ' + error)
        if (error?.error?.status === 'Error') {
          this.notify.showError(error?.error?.message, 'Failed')
        }
      }
    )
  }

  selectCompany(event?: any){
    if (event && event.currentTarget.value) {
      this.companyId = event.currentTarget.value;
    }
    this.viewCategorys();
  }

}
