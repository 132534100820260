<div class="wrap_body">
    <h4 class="title_m1">Bulk Upload</h4>
    <div class="row">
        <div class="col-sm-3">
    <div class="search_m1 mb-3">
        <input type="text" class="form-control" placeholder="search">
        <img src="../../../assets/images/Search.png" alt="">
    </div>
</div>

<div class="col-sm-12">
    <ul class="list_m1">
        <li><a href="#!">Click here</a> to Download template. </li>
        <li>Add your data in the template file. </li>
        <li>Upload the template.</li>
    </ul>

    <div class="file_m1 file_drag_m1">
        <angular-file-uploader
     [config]="afuConfig">
    </angular-file-uploader>
       </div>

</div>

<div class="col-sm-12">
    <div class="table-responsive">
    <table class="table  table-striped table_m1">
        <thead>
          <tr>
           
            <th>Name</th>
            <th scope="col">Email Id</th>
            <th scope="col">Address</th>
            <th scope="col">Approver</th>
            <th>Company</th>
            <th>Template</th>
          
           </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <input type="text" class="form-control">
          </td>
          <td>
            <input type="text" class="form-control">
          </td>
          <td>
            <input type="text" class="form-control">
          </td>
          <td>
            <input type="text" class="form-control">
          </td>
          <td>
            <input type="text" class="form-control">
          </td>
          <td>
            <input type="text" class="form-control">
          </td>
        </tr>

        <tr>
            
            <td>Candidate 1</td>
            <td>Candidate 1</td>
            <td>Candidate 1</td>
            <td>Candidate 1</td>
            <td>Candidate 1</td>
            <td>Candidate 1</td>
            
          </tr>

         

          
          
        </tbody>
      </table>
</div>
</div>
<div class="col-6 mt-3"><button class="btn btn_m3">Cancel</button></div>
<div class="col-6 text-right mt-3"><button class="btn btn_m4" type="button" (click)="openModal(template)" >Submit</button></div>


</div>
</div>

<ng-template #template>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Bulk upload</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
     <!-- <p><i class="fas fa-check c_2"></i> Successfully added Candidate 1.</p>
     <p><i class="fas fa-exclamation-triangle c_3" ></i> Rejected.</p> -->
<div class="row">
     <div class="col-sm-12">
     <p><i class="fas fa-check c_2"></i> Submitted Successfully.</p>
    </div>
</div>

     <div class="text-right">
     <button class="btn btn_m5">Ok</button>
  </div>
    </div>
  </ng-template>