import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotifyService } from 'src/app/notify.service';
import { SuperuserService } from 'src/app/superuser/services/superuser.service';
import { Validatorutil } from 'src/app/validatorutil';
import { environment } from 'src/environments/environment';
import { HrService } from '../services/hr.service';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EncryptDecrypt } from 'src/app/encrypt-decrypt';


@Component({
  selector: 'app-create-template',
  templateUrl: './create-template.component.html',
  styleUrls: ['./create-template.component.css']
})
export class CreateTemplateComponent implements OnInit {

  public formgroup: any;
  public submitted = false;
  public templateArray: any = [];
  public category: any = [];
  public storedata: any = [];
  public variblesData: any = [];
  public uploadResponseFlag = false;
  public templateNames: any = [];
  public categorys: any = [];
  public baseUrl = environment.url;
  public uploadImageData = new FormData();
  public variblekey: any = [];
  public companyId: any;
  public templateDetails: any = {
    templateId: "",
    approverId: null,
    statusId: null,
    companyCategoryId: "",
    templateName: "",
    variableKey: []
  }
  sessionData: any;
  companyList: any;
  aadhaarFlag = true;
  term: string = '';
  suggestionList: any[] = [];
  showSuggestionList = false;
  fileUrl!: string;
  templateName: any;
  template_popup!: BsModalRef;
  services: any;

  constructor(private router: Router, private modalService: BsModalService, private encDec: EncryptDecrypt,
    private fb: FormBuilder, private http: HttpClient, private superUserService: SuperuserService,
    private validtor: Validatorutil, private hrService: HrService, private notify: NotifyService) {
    var data: any = sessionStorage.getItem('loginDetails');
    var sessionService: any = sessionStorage.getItem('services');
    this.sessionData = JSON.parse(data);
    this.services = JSON.parse(sessionService);
    this.baseUrl = environment.url;
    this.formgroup = fb.group({
      templateId: null,
      companyName: [''],
      companyCategoryId: ['', Validators.required],
      templateName: ['', [Validators.required, Validators.pattern(validtor.NAME_REGEXP)]],
      file: [''],
      approverId: [this.sessionData.userId],
      statusId: ['22'], // need to change
      variableKey: this.fb.array([]),
      companyId: [this.sessionData.roleName != 'Super Admin' ? this.sessionData.companyId : '', Validators.required],
      isAadhaarSign: [''],
      masterTemplateId: [''],
      isUpload: [false]
    })
    this.formgroup.controls.templateName.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((term: string) => this.searchAPI(term))
      )
      .subscribe((results: any) => {
        this.suggestionList = results.respObject;
        // this.showSuggestionList = true;
    });
    this.aadhaarFlag = this.services?.adharSign ? true : false;
  }

  encrypt(value: any): any {
    return this.encDec.encrypt(value);
  }
  searchAPI(term: string) {
    if (this.formgroup.controls.templateName.value != '') {
      return this.hrService.getMasterTempletList();
    } else {
      return [];
    }
  }

  selectSuggestion(suggestion: any) {
    this.formgroup.get('templateName').setValue(suggestion.templateName);
    this.formgroup.get('masterTemplateId').setValue(suggestion.masterTemplateId);
    this.showSuggestionList = false;
    let payload: Payload = {
      masterTemplateId: suggestion.masterTemplateId
    }
    let formData = new FormData();
      // formData.append('file', this.formgroup.file, this.formgroup.file.name);
      formData.append('template', JSON.stringify(payload));
    this.docUpload(formData);
  }
  onClickedOutside(e: Event) {
    this.showSuggestionList = false;
  }
 
  showSuggestions() {
    this.showSuggestionList = true;
  }

  template_link(template: any, data: any) {
    
    this.fileUrl = this.baseUrl+ '/esign/masterTemplate/download/masterTemplateId/' + this.encrypt(data?.masterTemplateId)
    console.log(this.fileUrl)
    this.templateName = data.templateName;
    const initialState = {

    };

    this.template_popup = this.modalService.show(template,
      {
        class: 'modal-dialog-centered model_1 modal-lg', initialState,
      },
    );
  }
  afuConfig = {
    multiple: false,
    formatsAllowed: ".jpg,.png",
    maxSize: "1",
    uploadAPI: {
      url: "https://example-file-upload-api",
      method: "POST",
      params: {
        'page': '1'
      },
      responseType: 'text',
      withCredentials: false,
    },
    theme: "dragNDrop",
    hideProgressBar: true,
    hideResetBtn: true,
    hideSelectBtn: true,
    fileNameIndex: true,
    autoUpload: false,
    replaceTexts: {
      selectFileBtn: 'Select Files',
      resetBtn: 'Reset',
      uploadBtn: 'Upload',
      dragNDropBox: 'Drag N Drop',
      attachPinBtn: 'Attach Files...',
      afterUploadMsg_success: 'Successfully Uploaded !',
      afterUploadMsg_error: 'Upload Failed !',
      sizeLimit: 'Size Limit'
    }
  };

  ngOnInit(): void {
    this.templetList();
    this.templateMappingVaribales();
    this.getCategorys();
    this.getCompanyList();
  }

  templateMappingVaribales() {
    this.hrService.templateVariables().subscribe(
      res => {
        this.templateNames = res.respObject.variableValues;
      }
    )
  }

  fileSelected(event: any) {
    
    const fileType: string = event.target.files[0].type;
    if (!(fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
      this.notify.showInfo('Invalid file format! Please provide word document.', 'Info');
      this.formgroup.get('file').reset();
      this.uploadResponseFlag = false;
      return;
    }
    this.formgroup.file = event.target.files[0];
    this.uploadImageData.append(
      "file",
      this.formgroup.file,
      this.formgroup.file.name
    );
    this.docUpload(this.uploadImageData);
  }
  docUpload(data: any) {
    
    this.http.post<any>(this.baseUrl + "/esign/template/loadplaceholders", data).subscribe(
      res => {
        if (res != null) {
          this.uploadResponseFlag = true;
          this.afuConfig.replaceTexts.afterUploadMsg_success;
          this.variblesData = res.respObject.variables;
          this.setVariableValues();
        }
      },
      (error) => {
        console.log(error.message);
        if (error?.error?.status === 'Error') {
          this.notify.showError(error?.error?.message, 'Failed')
        }
      }
    )
  }

  setVariableValues() {
    for (let data of this.variblesData) {
      this.changeValues(undefined, data)
    };
  }

  changeValues(event: any, user: any) {
    
    let varibleKeydata = {
      id: null,
      key: "",
      value: ""
    }
    varibleKeydata.value = event ? event?.currentTarget?.value : '';
    varibleKeydata.key = user;
    // varibleKeydata.id = this.templateNames.filter((value: { name: any; }) => value.name === event.currentTarget.value)[0].id;
    this.variblekey.push(varibleKeydata);
    this.formgroup.value.variableKey = this.variblekey;
    this.templateDetails.variableKey = this.variblekey;
  }

  addTemplate() {
    
    this.showSuggestionList = false;
    const masterTemplateId = this.suggestionList.filter((data: any) => data.templateName === this.formgroup.controls.templateName.value)[0]?.masterTemplateId ? 
      this.suggestionList.filter((data: any) => data.templateName === this.formgroup.controls.templateName.value)[0]?.masterTemplateId : null;
    this.formgroup.get('masterTemplateId').setValue(masterTemplateId);
    console.log(this.formgroup.value)
    this.formgroup.value.isAadhaarSign = typeof this.formgroup.value.isAadhaarSign === 'string' ? this.formgroup.value.isAadhaarSign === "true" ? true : false : this.formgroup.value.isAadhaarSign;
    if (this.formgroup.invalid) {
      this.submitted = true;
      return;
    } else {
      
      // if (this.templateDetails.variableKey.length < this.variblesData.length) {
      //   this.notify.showInfo('Please select variable values.', 'Info');
      //   return;
      // }
      if (!masterTemplateId && (!this.formgroup.file || this.formgroup.file === '')) {
        this.notify.showInfo('Please upload template.', 'Info');
        return;
      }
      if (this.formgroup.value.variableKey.length >= 0) {
        this.formgroup.value.variableKey = this.variblekey;
      }
      this.templateDetails.templateName = this.formgroup.value.templateName;
      this.templateDetails.companyCategoryId = this.formgroup.value.companyCategoryId;
      this.templateDetails.companyId = this.formgroup.value.companyId;
      this.templateArray.push(this.templateDetails);
      this.formgroup.value.isAadhaarSign = typeof this.formgroup.value.isAadhaarSign === 'string' ? this.formgroup.value.isAadhaarSign === "true" ? true : false : this.formgroup.value.isAadhaarSign;
      let formData = new FormData();
      if (this.formgroup.value.masterTemplateId && this.formgroup.value.masterTemplateId != '') {
        formData.append('file', 'null');
      } else {
        formData.append('file', this.formgroup?.file, this.formgroup?.file?.name);
      }
      formData.append('template', JSON.stringify(this.formgroup.value));
      this.hrService.saveTemplate(formData).subscribe(
        (res: any) => {
          if (res.status === 'Success') {
            this.notify.showSuccess('Template created successfully', 'Success');
            this.router.navigate([`/hradmin/template`])
          }
          this.uploadResponseFlag = false;
          this.uploadResponseFlag = false;
        },
        (error) => {
          if (error?.error && error?.error?.message) {
            this.notify.showError(error?.error?.message, 'Failed');
          }
          console.log(error.message);
        }
      )
    }
  }
  viewTempaltes() {
    this.storedata = sessionStorage.getItem("templateDetails");
    let arry = JSON.parse(this.storedata);
    this.templateArray = arry;
  }
  get f() {
    return this.formgroup.controls;
  }

  templetList() {
    const status = null;
    if (this.formgroup.value.companyId) {
      this.hrService.templetList(this.formgroup.value.companyId, status).subscribe(
        res => {
          if (res.status == 'Success') {
            this.templateArray = res.respObject
          }
        },
        (error) => {
          console.log(error.message);
          if (error?.error?.status === 'Error') {
            this.notify.showError(error?.error?.message, 'Failed')
          }
        }
      );
    }
  }

  getCompanyList() {
    this.superUserService.getAllcompanyDeatls().subscribe(
      res => {
        if (res.status == 'Success') {
          this.companyList = res.respObject;
        }
      },
      (error) => {
        console.log('error: ' + error);
        if (error?.error?.status === 'Error') {
          this.notify.showError(error?.error?.message, 'Failed')
        }
      }
    )
  }
  companyEvent(event?: any) {
    this.templetList();
    this.getCategorys();
  }
  getCategorys() {
    const status = 'active';
    if (this.formgroup.value.companyId != '') {
      this.hrService.getCategoryByCompanyId(this.formgroup.value.companyId, status).subscribe(
        res => {
          console.log(res);
          this.categorys = res.respObject;
        },
        (error) => {
          console.log('error: ' + error);
          if (error?.error?.status === 'Error') {
            this.notify.showError(error?.error?.message, 'Failed')
          }
        }
      );
    }
  }
}

interface Payload {
  masterTemplateId: any
}
